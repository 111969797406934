import { FC } from 'react';
import { Page } from '../../components';
import { Grid } from '@mui/material';

export const TermsAndConditions: FC = () => {
  return (
    <Page title={'Terms and Conditions'}>
      <Grid container spacing={3}>
        <Grid item mt={1}>
          Vetcher offers you the option to engage in SMS text conversations about your job
          application. By participating, you also understand that message frequency may vary
          depending on the status of your job application, and that message and data rates may
          apply. Please consult your carrier for further information on applicable rates and fees.
          Carriers are not liable for delayed or undelivered messages. Reply STOP to cancel and HELP
          for help.
        </Grid>
        <Grid item>
          By opting-in to receiving SMS text messages about your job application, you acknowledge
          and agree that your consent data, mobile number, and personal information will be
          collected and stored solely for the purpose of providing you with updates and information
          related to your job application. No mobile information will be shared with third
          parties/affiliates for marketing/promotional purposes. All the above categories exclude
          text messaging originator opt-in data and consent; this information will not be shared
          with any third parties.
        </Grid>
      </Grid>
    </Page>
  );
};
