import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
import { ISchedulePayload } from '../models/schedule';

export interface IShiftRequestApproval {
  shiftRequestApproval: IShiftRequests;
}

export interface IShiftRequests {
  requests: IShiftApprovals[];
}
export interface IShiftApprovals {
  shiftId: number;
  dvmId: number;
  locationId: number;
  date: string | Date;
}
interface IRSSMarketRepeaterContext {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  payloads: ISchedulePayload[];
  setPayloads: Dispatch<SetStateAction<ISchedulePayload[]>>;
  allPayloads: IShiftRequestApproval;
  setAllPayloads: Dispatch<SetStateAction<IShiftRequestApproval>>;
}

interface IRSSMarketRepeaterContextHandlerProps {
  children: React.ReactNode;
}

export const RSSMarketRepeaterContext = createContext<IRSSMarketRepeaterContext>({
  isLoading: false,
  setIsLoading: () => {},
  payloads: [],
  setPayloads: () => {},
  allPayloads: { shiftRequestApproval: { requests: [] } },
  setAllPayloads: () => {},
});

export const RSSMarketRepeaterHandler: FC<IRSSMarketRepeaterContextHandlerProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payloads, setPayloads] = useState<any>([]);
  const [allPayloads, setAllPayloads] = useState<IShiftRequestApproval>({
    shiftRequestApproval: { requests: [] },
  });

  return (
    <RSSMarketRepeaterContext.Provider
      value={{
        isLoading,
        setIsLoading,
        payloads,
        setPayloads,
        allPayloads,
        setAllPayloads,
      }}
    >
      {children}
    </RSSMarketRepeaterContext.Provider>
  );
};
