import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Button, Box, useMediaQuery } from '@mui/material';
import { IColumn } from '../../models/util';
import { Table } from '../../components/table/Table';
import { getDvmPendingShiftRequests } from '../../fetch';
import { IDVM, IPendingShiftRequest } from '../../models';
import { useSnackbar } from 'notistack';
import { formatDateWithDay, formatMoney } from '../../helpers';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Clear } from '@mui/icons-material';
import { CancelRequestModal } from './cancel-request-modal';
import { Coin } from '../../components/icons/coin';

export interface IDvmPendingRequests {
  dvm: IDVM | null;
}
export const DvmPendingRequests: FC<IDvmPendingRequests> = ({ dvm }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<IPendingShiftRequest[]>([]);
  const [shiftRequestId, setShiftRequestId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const classes = useStyles({ isMobile });

  const fetchPendingRequests = async () => {
    setIsLoading(true);
    try {
      const res = await getDvmPendingShiftRequests(dvm?.dvmId ?? 0);
      setPendingRequests(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading pending shift requests, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = (shiftRequestId: number) => {
    setShiftRequestId(shiftRequestId);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setShiftRequestId(undefined);
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchPendingRequests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Date',
        accessor: 'shiftDate',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingShiftRequest } };
        }) => {
          return <span>{formatDateWithDay(original.shiftDate)}</span>;
        },
      },
      {
        Header: 'Location',
        accessor: 'locationName',
      },
      {
        Header: 'Rate',
        accessor: 'amount',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingShiftRequest } };
        }) => {
          return <span>{formatMoney(original.amount, 0)}</span>;
        },
      },
      {
        Header: 'Incentive',
        accessor: 'incentive',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingShiftRequest } };
        }) => {
          return (
            <span>
              {original?.incentive ? <Coin maxHeight={20} /> : null}{' '}
              {original?.incentive ? formatMoney(original.incentive, 0) : null}
            </span>
          );
        },
      },
      {
        Header: 'Total Payment',
        accessor: 'totalPayment',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingShiftRequest } };
        }) => {
          return (
            <span className={classes.noWrap}>
              {original?.incentive && isMobile ? <Coin maxHeight={20} /> : null}{' '}
              {original?.totalPayment ? formatMoney(original.totalPayment, 0) : null}
            </span>
          );
        },
      },
      {
        Header: '',
        accessor: 'actions',
        id: 'actions',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingShiftRequest } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.cancelButton}
                color="primary"
                onClick={() => handleCancel(original.shiftRequestId)}
              >
                <Clear />
                {isMobile ? null : ' Cancel'}
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  return (
    <>
      <Grid container spacing={6}>
        <Grid className={classes.centered} item xs={12}>
          <div style={isMobile ? { width: '100%' } : undefined}>
            {!isLoading && pendingRequests.length < 1 ? (
              <Typography align="center">No pending shift requests found.</Typography>
            ) : (
              <Table
                containerClasses={classes.tableOverflow}
                headerClasses={classes.tableCell}
                cellClasses={classes.tableCell}
                columns={columns
                  .filter(col =>
                    isMobile
                      ? col.accessor === 'shiftDate' ||
                        col.accessor === 'locationName' ||
                        col.accessor === 'totalPayment' ||
                        col.accessor === 'actions'
                      : true
                  )
                  .map(col =>
                    col.Header === 'Total Payment' ? { ...col, Header: 'Payment' } : col
                  )}
                data={pendingRequests}
                hidePagination
                isLoading={isLoading}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <CancelRequestModal
        open={isModalOpen}
        onClose={handleClose}
        shiftRequestId={shiftRequestId}
        fetchPendingRequests={() => fetchPendingRequests()}
      />
    </>
  );
};
const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) => ({
  shiftTableWrapper: {
    width: '100%',
  },
  centered: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  cancelButton: {
    width: '100%',
    maxWidth: '125px',
    minWidth: '1px',
    padding: ({ isMobile }) => (isMobile ? 0 : '6px 16px'),
    '& svg': {
      width: '.75em',
      height: '.75em',
      marginRight: ({ isMobile }) => (isMobile ? 0 : '6px'),
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableOverflow: {
    overflowY: 'auto',
  },
  tableCell: {
    padding: ({ isMobile }) => (isMobile ? '6px' : '6px 16px'),
  },
}));
