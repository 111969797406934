import { Close, DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Popover, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format, isValid, toDate as formatDate } from 'date-fns';
import { FC, useRef, useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import { IDateRange } from '../../models/date';

import '../../styles/react-date-range-theme.css'; // theme css file

export interface DateRangePickerProps {
  value: IDateRange | null;
  onChange: (value: IDateRange) => void;
  id: string;
  className?: string;
  placeholder?: string;
  inputSize?: 'medium' | 'small';
  fullWidth?: boolean;
  label?: string;
  isDisabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  showPopover?: boolean;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  value,
  onChange,
  id,
  placeholder,
  inputSize,
  className,
  fullWidth,
  label,
  isDisabled,
  minDate,
  maxDate,
  showPopover = true,
  ...props
}) => {
  const classes = useStyles({ inputSize });
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value?.inputValue ?? '');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(value?.startDate ?? null);
  const [toDate, setToDate] = useState<Date | null>(value?.endDate ?? null);
  const dateRangeRef = useRef(null);

  // if the parent value changes update state
  useEffect(() => {
    setInputValue(value?.inputValue ?? '');
    setFromDate(value?.startDate ?? null);
    setToDate(value?.endDate ?? null);
  }, [value]);

  const onAdornmentClick = (e: any) => {
    setDisplayCalendar(true);
    setAnchorEl(dateRangeRef.current);
  };
  const onClearClick = (e: any) => {
    e.stopPropagation();
    setDisplayCalendar(false);
    setAnchorEl(null);
    setInputValue('');
    setFromDate(null);
    setToDate(null);
    onChange({ startDate: null, endDate: null, key: 'selection', inputValue: '' }); // callback
  };
  const onInputChange = (e: any) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    setInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);
  };
  const onPopperClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
    onChange({ startDate: fromDate, endDate: toDate, key: 'selection', inputValue }); // callback
  };
  const onSelectDateRanges = ({ selection }: any) => {
    let { startDate, endDate } = selection;
    startDate = isValid(startDate) ? formatDate(startDate) : undefined;
    endDate = isValid(endDate) ? formatDate(endDate) : undefined;

    let inputValue = '';
    if (startDate) inputValue += format(startDate, 'M/d/yyyy');
    if (endDate) inputValue += ' - ' + format(endDate, 'M/d/yyyy');

    setFromDate(startDate);
    setToDate(endDate);
    setInputValue(inputValue);
  };
  const processInputValue = (value: any) => {
    let [fromDate, toDate] = value.split('-').map((elm: any) => elm.trim());

    fromDate = format(fromDate, 'M/dd/yyyy');
    fromDate = fromDate.isValid() ? formatDate(fromDate) : undefined;

    toDate = format(toDate, 'M/dd/yyyy');
    toDate = toDate.isValid() ? formatDate(toDate) : undefined;

    return { fromDate, toDate };
  };

  const clearButton =
    fromDate || toDate ? (
      <IconButton className={classes.clearButton} onClick={onClearClick} size="small">
        <Close />
      </IconButton>
    ) : (
      <></>
    );
  return (
    <div className={clsx(className, fullWidth && classes.fullWidth)} ref={dateRangeRef}>
      <TextField
        fullWidth
        placeholder={placeholder}
        size={inputSize}
        id={id}
        disabled={isDisabled}
        label={label}
        value={inputValue}
        onChange={onInputChange}
        onClick={onAdornmentClick}
        className={classes.pickerInput}
        InputProps={{
          endAdornment: (
            <InputAdornment className={classes.endAdornment} position="end">
              {clearButton}
              <IconButton edge="end" onClick={onAdornmentClick} size="small">
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {showPopover ? (
        <Popover
          id={`${id}-popover`}
          open={displayCalendar}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={onPopperClose}
          className={clsx(!fromDate && !toDate ? 'isEmpty' : '')}
        >
          <Box>
            {/* @ts-ignore */}
            <DateRange
              ranges={[
                {
                  startDate: fromDate,
                  endDate: toDate,
                  key: 'selection',
                },
              ]}
              onChange={onSelectDateRanges}
              editableDateInputs={false}
              value={value}
              showMonthAndYearPickers={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              staticRanges={undefined}
              inputRanges={undefined}
              className={classes.dateRange}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Box>
          <Box className={classes.buttonGroup}>
            <Button className={classes.applyButton} onClick={onPopperClose} color="secondary">
              Apply
            </Button>
          </Box>
        </Popover>
      ) : (
        <Box>
          {/* @ts-ignore */}
          <DateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: 'selection',
              },
            ]}
            onChange={onSelectDateRanges}
            editableDateInputs={false}
            value={value}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            staticRanges={undefined}
            inputRanges={undefined}
            className={classes.dateRangeFullWidth}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { inputSize?: string }>(theme => ({
  fullWidth: {
    width: '100%',
  },
  dateRangeFullWidth: {
    width: '100%',
  },
  buttonGroup: {
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
  },
  endAdornment: {
    '@media (max-width: 400px)': {
      marginLeft: -10,
      marginRight: -12,
    },
  },
  clearButton: {
    '@media (max-width: 400px)': {
      marginRight: -12,
    },
  },
  applyButton: {},
  pickerInput: {
    '&& .MuiInputBase-input': {
      color: theme.palette.common.black,
      '-webkit-text-fill-color': theme.palette.common.black, // Display input as normal, not disabled
    },
  },
  dateRange: {},
}));
