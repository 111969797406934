import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { AppBar, Toolbar } from '@mui/material';

interface IFooter {
  clientName: string;
  height: number;
}

export const Footer: FC<IFooter> = ({ clientName, height }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.footerContainer}
      position="fixed"
      color="primary"
      component="footer"
      sx={{ height, zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <Toolbar disableGutters>
        {clientName} {new Date().getFullYear()}. All Rights Reserved.
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    footerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'auto',
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  };
});
