import { DeleteForever } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { MultiFileUpload } from '../../components/file/multi-image-upload';
import { Table } from '../../components/table/Table';
import { getFile } from '../../fetch/files';
import { deleteLicense } from '../../fetch/license';
import { downloadFile } from '../../helpers';
import { ILicense } from '../../models';
import { IFile } from '../../models/files';

interface ILicenseInfo {
  licenses: ILicense[];
  isLoading: boolean;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  validateForm: any;
  values: any;
  fetchDVM: () => void;
  readOnly: boolean;
}
export const LicenseInfo: FC<ILicenseInfo> = ({
  licenses,
  isLoading,
  errors,
  setFieldValue,
  validateForm,
  values,
  fetchDVM,
  readOnly,
}) => {
  const [licensesCopy, setLicensesCopy] = useState(values.licenses);
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<IFile[]>([]);

  const isMobile = useMediaQuery('(max-width: 960px)');

  useEffect(() => {
    setLicensesCopy(licenses);
  }, [licenses, isLoading]);

  const addLicense = () => {
    setLicensesCopy((prev: any) => {
      const newLicense = [
        ...prev,
        {
          licenseId: -Math.floor(1000000 + Math.random() * 9000000).toString(),
          state: '',
          expirationDate: new Date(),
          file: null,
        },
      ];
      setFieldValue('licenses', newLicense);

      return newLicense;
    });
  };

  const updateLicenseData = (
    license: ILicense,
    columnId: string,
    value: string,
    rowIndex: number
  ) => {
    setLicensesCopy((old: ILicense[]) => {
      const newLicenses = old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...license,
            [columnId]: value,
          };
        }
        return row;
      });

      return newLicenses;
    });
  };

  const updateAcceptLicenseData = (
    license: ILicense,
    columnId: string,
    value: string,
    rowIndex: number
  ) => {
    setLicensesCopy((old: ILicense[]) => {
      const newLicenses = old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...license,
            [columnId]: value,
          };
        }
        return row;
      });

      setFieldValue('licenses', newLicenses);
      return newLicenses;
    });
  };

  const getFileName = (res: any) => {
    let filename = '';
    let disposition = res.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };
  const handleDelete = async (licenseId: number) => {
    const result = window.confirm('Are you sure you want to delete this license?');
    if (result) {
      try {
        if (licenseId > 0) await deleteLicense(licenseId);
        setLicensesCopy((prev: any) => {
          const newLicenses = prev.filter((license: any) => license.licenseId !== licenseId);
          setFieldValue('licenses', newLicenses);

          validateForm();
          return newLicenses;
        });

        enqueueSnackbar(`license deleted.`, {
          variant: 'success',
        });
        fetchDVM();
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting license, please try again.`, {
          variant: 'error',
        });
      }
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'State',
        accessor: 'state',
        sort: false,
        Cell: (props: any) => {
          const [val, setVal] = useState(licensesCopy?.[props?.row?.index]?.state);
          return (
            <>
              <TextField
                disabled={readOnly}
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onChange={(e: any) => setVal(e.target.value)}
                onBlur={() => {
                  updateAcceptLicenseData(
                    props?.row?.original,
                    props?.column?.id,
                    val,
                    props?.row?.index
                  );
                }}
                placeholder={props.column.Header}
                autoComplete="nope"
                value={val}
                InputProps={{
                  accessKey: `input-${props.row.index}-${props.column.id}`,
                }}
              />
            </>
          );
        },
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        sort: false,
        Cell: (props: any) => {
          const [val, setVal] = useState('');
          return (
            <>
              {!isMobile ? (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      toolbarPlaceholder={'Expiration Date'}
                      inputFormat="MM/dd/yyyy"
                      value={licensesCopy?.[props?.row?.index]?.expirationDate}
                      onChange={val => setVal(val)}
                      disabled={readOnly}
                      onAccept={val => {
                        updateAcceptLicenseData(
                          props?.row?.original,
                          props?.column?.id,
                          val,
                          props?.row?.index
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          disabled={readOnly}
                          onBlur={() => {
                            updateAcceptLicenseData(
                              props?.row?.original,
                              props?.column?.id,
                              val,
                              props?.row?.index
                            );
                          }}
                          required={false}
                          size="small"
                          variant="standard"
                          {...params}
                          error={errors && !!errors?.licenses?.[props?.row?.index]?.expirationDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Typography variant="body2">
                    {errors && errors?.licenses?.[props?.row?.index]?.expirationDate}
                  </Typography>
                </>
              ) : (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarPlaceholder={'Expiration Date'}
                      inputFormat="MM/dd/yyyy"
                      value={licensesCopy?.[props?.row?.index]?.expirationDate}
                      onChange={val => setVal(val)}
                      disabled={readOnly}
                      onAccept={val => {
                        setFieldValue(`licenses.[${props?.row?.index}].expirationDate`, val);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          disabled={readOnly}
                          onBlur={() => setFieldValue('licenses', licensesCopy)}
                          variant="standard"
                          size="small"
                          {...params}
                          error={errors && !!errors?.licenses?.[props?.row?.index]?.expirationDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Typography variant="body2">
                    {errors && errors?.licenses?.[props?.row?.index]?.expirationDate}
                  </Typography>
                </>
              )}
            </>
          );
        },
      },
      {
        Header: 'File',
        accessor: 'file',
        sort: false,
        Cell: (props: any) => {
          return (
            <>
              {props?.row?.original?.file?.azureUri ? (
                <Button
                  key={props.row.original.file.fileId}
                  onClick={async () => {
                    const res = await getFile(props?.row?.original?.file?.fileId);
                    const fileName = getFileName(res);
                    const binaryData = [];
                    binaryData.push(res.data);
                    downloadFile(binaryData, fileName);
                  }}
                  size={'large'}
                  variant={'contained'}
                  component={'span'}
                >
                  {values?.licenses?.[props?.row?.index]?.file?.fileName}
                </Button>
              ) : (
                <>
                  {readOnly ? (
                    <Typography variant="body2">There is no file to display</Typography>
                  ) : (
                    <>
                      <MultiFileUpload
                        multi={false}
                        imageKey={`${props?.row?.index}`}
                        handleFileChange={async val => {
                          setFiles(prev => [...prev, ...val]);
                          setFieldValue(`licenses[${props?.row?.index}].file`, val?.[0].file);
                        }}
                        fileName={
                          props?.row?.original?.file?.name || props?.row?.original?.file?.fileName
                        }
                        files={files.map(file => {
                          return file.title;
                        })}
                      />
                      {errors && errors?.locationShifts?.[props?.row?.index]?.file && (
                        <Typography variant="body2">
                          {errors && errors?.locationShifts?.[props?.row?.index]?.file}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          );
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: (props: any) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <IconButton onClick={() => handleDelete(props?.row?.original?.licenseId)}>
                <DeleteForever />
              </IconButton>
            </Box>
          );
        },
      },
    ].filter(column => (readOnly ? column.id !== 'actions' : true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenses, isLoading]);

  return (
    <Grid container spacing={3}>
      {!readOnly && (
        <Grid item xs={12}>
          <Button onClick={() => addLicense()}>Add</Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={licensesCopy}
          hidePagination
          isLoading={isLoading}
          useTableProps={{
            // @ts-ignore
            updateMyData: updateLicenseData,
          }}
        />
      </Grid>
    </Grid>
  );
};
