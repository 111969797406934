import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { IColumn } from '../../models/util';
import { Table } from '../../components/table/Table';
import { getDvmUpcomingShifts } from '../../fetch';
import { IDVM, IUpcomingShift } from '../../models';
import { useSnackbar } from 'notistack';
import { formatDateWithDay, formatMoney } from '../../helpers';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Clear } from '@mui/icons-material';
import { CancelScheduleModal } from './cancel-schedule-modal';
import { Coin } from '../../components/icons/coin';

export interface IDvmSchedule {
  dvm: IDVM | null;
  isContractor: boolean;
}
export const DvmSchedule: FC<IDvmSchedule> = ({ dvm, isContractor }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [upcomingShifts, setUpcomingShifts] = useState<IUpcomingShift[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shiftId, setShiftId] = useState<number>();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const classes = useStyles({ isMobile });

  const fetchUpcomingShifts = async () => {
    setIsLoading(true);
    try {
      const res = await getDvmUpcomingShifts(dvm?.dvmId ?? 0);
      setUpcomingShifts(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading upcoming shifts, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingShifts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = (shiftId: number, dvmId: number) => {
    setShiftId(shiftId);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setShiftId(undefined);
    setIsModalOpen(false);
  };

  const columns = useMemo((): IColumn[] => {
    return (
      [
        {
          Header: 'Date',
          accessor: 'date',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return <span>{formatDateWithDay(original.shiftDate)}</span>;
          },
        },
        {
          Header: 'Location Name',
          accessor: 'locationName',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return (
              <Link href={original.googleMapsUrl} target={'_blank'}>
                {original.locationName}
              </Link>
            );
          },
        },
        {
          Header: 'Shift Hours',
          accessor: 'shiftTime',
        },
        {
          Header: 'Rate',
          accessor: 'amount',
          columnAlignment: 'right' as 'right',
          // TypeScript was complaining this was set as 'string' and not 'left' | 'center' | 'right' I think it has to do with the filters
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return <span>{formatMoney(original.amount, 0)}</span>;
          },
        },
        {
          Header: 'Incentive',
          accessor: 'incentive',
          // TypeScript was complaining this was set as 'string' and not 'left' | 'center' | 'right' I think it has to do with the filters
          columnAlignment: 'right' as 'right',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return (
              <span>
                {original?.incentive ? <Coin maxHeight={20} /> : null}{' '}
                {original?.incentive ? formatMoney(original.incentive, 0) : null}
              </span>
            );
          },
        },
        {
          Header: 'Total Payment',
          accessor: 'totalPayment',
          // TypeScript was complaining this was set as 'string' and not 'left' | 'center' | 'right' I think it has to do with the filters
          columnAlignment: 'right' as 'right',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return (
              <span className={classes.noWrap}>
                {original?.incentive && isMobile ? <Coin maxHeight={20} /> : null}{' '}
                {original?.totalPayment ? formatMoney(original.totalPayment, 0) : null}
              </span>
            );
          },
        },
        {
          Header: '',
          accessor: 'actions',
          id: 'actions',
          Cell: ({
            cell: {
              row: { original },
            },
          }: {
            cell: { row: { original: IUpcomingShift } };
          }) => {
            return (
              <Box
                sx={{
                  textAlign: {
                    xs: 'left',
                    md: 'center',
                  },
                }}
              >
                <Button
                  className={classes.cancelButton}
                  color="primary"
                  onClick={() => handleCancel(original.shiftId, dvm?.dvmId ?? 0)}
                >
                  <Clear />
                  {isMobile ? null : ' Cancel'}
                </Button>
              </Box>
            );
          },
        },
      ]
        // This removes the columns that are not needed for the full time employee view
        .filter(column => (isContractor ? true : column.accessor !== 'incentive'))
        .filter(column => (isContractor ? true : column.accessor !== 'totalPayment'))
        .filter(column => (isContractor ? true : column.accessor !== 'amount'))
        .filter(column => (isContractor ? true : column.accessor !== 'actions'))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid className={classes.centered} item xs={12}>
          <div style={isMobile ? { width: '100%' } : undefined}>
            {!isLoading && upcomingShifts.length < 1 ? (
              <Typography>No upcoming scheduled shifts</Typography>
            ) : (
              <Table
                containerClasses={classes.tableOverflow}
                headerClasses={classes.tableCell}
                cellClasses={classes.tableCell}
                columns={columns
                  .filter(col =>
                    isMobile
                      ? col.accessor === 'date' ||
                        col.accessor === 'locationName' ||
                        col.accessor === 'totalPayment' ||
                        col.accessor === 'shiftTime' ||
                        col.accessor === 'actions'
                      : true
                  )
                  .map(col =>
                    col.Header === 'Total Payment' ? { ...col, Header: 'Payment' } : col
                  )}
                data={upcomingShifts}
                hidePagination
                isLoading={isLoading}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <CancelScheduleModal
        open={isModalOpen}
        onClose={handleClose}
        shiftRequestId={shiftId}
        dvmId={dvm?.dvmId ?? 0}
        fetchRequests={() => fetchUpcomingShifts()}
      />
    </>
  );
};
const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) => ({
  shiftTableWrapper: {
    width: '100%',
  },
  centered: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  cancelButton: {
    width: '100%',
    maxWidth: '125px',
    minWidth: '1px',
    padding: ({ isMobile }) => (isMobile ? 0 : '6px 16px'),
    '& svg': {
      width: '.75em',
      height: '.75em',
      marginRight: ({ isMobile }) => (isMobile ? 0 : '6px'),
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableOverflow: {
    overflowY: 'auto',
  },
  tableCell: {
    padding: ({ isMobile }) => (isMobile ? '6px' : '6px 16px'),
  },
}));
