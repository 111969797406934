import { Box, Button, Grid, IconButton, useMediaQuery } from '@mui/material';
import { FC, useState, useEffect, useMemo } from 'react';
import { Loader, Page } from '../../components';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn } from '../../models/util';
import { Add, DeleteForever, EditLocationAlt } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { deleteHoliday, getHolidays } from '../../fetch/holidays';
import { IHoliday } from '../../models/holidays';
import { HolidayModal } from './add-edit-holiday-modal';
import { formatDate } from '../../helpers';

export const Holidays: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [holidays, setHolidays] = useState<IHoliday[]>([]);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentHoliday, setCurrentHoliday] = useState<IHoliday | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const fetchHolidays = async () => {
    try {
      setIsLoading(true);
      const res = await getHolidays();
      setHolidays(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading holidays, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setIsModalOpen(true);
  };
  const handleEdit = (holidayId: number | string) => {
    setCurrentHoliday(holidays.find(holiday => holiday.holidayId === holidayId));
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setCurrentHoliday(undefined);
    setIsModalOpen(false);
  };

  const handleDelete = async (holidayId: number | string) => {
    console.log(holidayId);
    const result = window.confirm('Are you sure you want to delete this Holiday?');
    if (result) {
      try {
        setIsDeleting(true);
        await deleteHoliday(holidayId);
        await fetchHolidays();
        enqueueSnackbar('Holiday Deleted!', {
          variant: 'success',
        });
      } catch (error: any) {
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting holiday, please try again.`, {
          variant: 'error',
        });
        console.log(error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IHoliday } };
        }) => {
          return <span>{formatDate(original.date)}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IHoliday } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original.holidayId);
                }}
              >
                Edit
              </Button>
              <IconButton
                className={classes.button}
                onClick={() => handleDelete(original.holidayId)}
              >
                <DeleteForever />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidays]);

  return (
    <Page
      title={'Holidays'}
      additionalHeaderContent={
        <Button
          startIcon={<Add />}
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={() => handleAdd()}
        >
          New Holiday
        </Button>
      }
    >
      {(isDeleting || isLoading) && (
        <Loader type="fullscreen" position="centered" title="Loading..." />
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={holidays}
            hidePagination
            isLoading={isLoading}
            ResponsiveComponent={isMobile ? MobileTable : undefined}
            mobileProps={{
              fields: columns
                .filter(col => col.Header)
                .map(col => ({
                  name: col.Header,
                  accessor: col.accessor,
                  Cell: col?.Cell,
                })),
              handleEdit: (holiday: IHoliday) => {
                handleEdit(holiday.holidayId);
              },
              handleDelete: (holiday: IHoliday) => handleDelete(holiday.holidayId),
            }}
          />
        </Grid>
      </Grid>
      <HolidayModal
        open={isModalOpen}
        onClose={handleClose}
        currentHoliday={currentHoliday}
        fetchHolidays={() => fetchHolidays()}
      />
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
