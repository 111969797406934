import { FC } from 'react';
import { Page } from '../../components';
import { Grid, Link, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const PrivacyPolicy: FC = () => {
  const classes = useStyles();

  return (
    <Page title={'Privacy Policy'}>
      <Grid container spacing={3}>
        <Grid item mt={1}>
          American Veterinary Group (“we”, “us”) are committed to protecting and respecting your
          privacy. This Privacy Notice sets out the basis on which the personal data collected from
          you, or that you provide to us, will be processed by us in connection with our DVM
          Scheduling processes. For the purpose of the General Data Protection Regulation (“GDPR”)
          the Data Controller is American Veterinary Group. We use Vetcher, an online software
          product provided by American Veterinary Group (t/a Vetcher Software), to assist with our
          DVM Scheduling process. We use Vetcher to process personal information as a data processor
          on our behalf. Vetcher is only entitled to process your personal data in accordance with
          our instructions. Where you apply for an opportunity posted by us, these Privacy Notice
          provisions will apply to our processing of your personal information, in addition to our
          other Privacy Notice which is available on{' '}
          <Link href="https://www.americanveterinarygroup.com/">our website</Link>.
        </Grid>
        <Grid item mb={4}>
          <Typography className={classes.headingTextColor} variant="h2">
            Your Personal Information
          </Typography>
          <Typography variant="subtitle1" mt={2}>
            Information we collect from you.
          </Typography>
          <List
            subheader={
              'We collect and process some or all the following types of information from you:'
            }
          >
            <ListItem>
              <ListItemText primary="Information you provide. We may collect and store personal information you register for our Service or provide to us in some other manner. For example, when you register with the Service, we collect your name, email address, password, contact information, and employment information. If you are an employer, we also collect information about your business, payment information, and any information you provide about your employees, including your employees’ contact information and pay-scale. We may also collect any communications between you and Vetcher, any information you provide if you take part in any interactive features of the Service, and any other information you provide to us." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Data collected through the use of the Service. After you set up your account, we collect information in connection with your use of the Service, including your work schedule, your availability, and your time records." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Information we receive from third parties. From time to time, we may receive information about you from third parties, such as identity verification, payroll vendors, and other information. We may also collect information about you that is publicly available." />
            </ListItem>
          </List>
          <Box>
            We use this information to operate, maintain, and provide to you the features and
            functionality of the Service, as well as to communicate directly with you. We may
            compile analytics and share performance data on a de-identified or aggregate basis. We
            may send you communications that contain information about our products or Service, or
            information about third parties’ products or services we think you may enjoy. We may
            also send you Service-related communications (e.g., account verification, messages and
            schedules, change or updates to features of the Service, technical and security
            notices).
          </Box>
          <Typography variant="subtitle1" my={2}>
            How we use cookies and other tracking technology to collect information.
          </Typography>
          <Box>
            We, and our third-party partners, automatically collect certain types of usage
            information when you visit our Service, read our communications, or otherwise engage
            with us. We typically collect this information through a variety of tracking
            technologies, including cookies, Flash objects, web beacons, file information and
            similar technology (collectively, “tracking technologies”). For example, we collect
            information about your device and its software, such as your IP address, browser type,
            Internet service provider, platform type, device type, operating system, date and time
            stamp, a unique ID that allows us to uniquely identify your browser, and other such
            information. We also collect information about the way you use our Service, for example,
            the site from which you came and the site to which you are going when you leave our
            website, the pages you visit, the links you click, how frequently you access the
            Service, whether you open emails or click the links contained in emails, whether you
            access the Service from multiple devices, and other actions you take on the Service.
            When you access our Service from a mobile device, we may collect unique identification
            numbers associated with your device or our mobile application (including, for example, a
            UDID, Unique ID for Advertisers (“IDFA”), Google AdID, or Windows Advertising ID),
            mobile carrier, device type, model and manufacturer, mobile device operating system
            brand and model, phone number, and depending on your mobile device settings, your
            geographical location data, including GPS coordinates (e.g., latitude and/or longitude)
            or similar information regarding the location of your mobile device, or we may be able
            to approximate a device’s location by analyzing other information, like an IP address.
            We may collect analytics data, or use third-party analytics tools, to help us measure
            traffic and usage trends for the Service and to understand more about the demographics
            of our users. We may also work with third party partners to employ technologies,
            including the application of statistical modeling tools, which attempt to recognize you
            across multiple devices. Although we do our best to honor the privacy preferences of our
            users, we are unable to respond to Do Not Track signals set by your browser at this
            time.
          </Box>

          <Box my={2}>
            We use or may use the data collected through tracking technologies to: (a) remember
            information so that you will not have to re-enter it during your visit or the next time
            you visit the site; (b) provide custom, personalized content and information, including
            targeted content and advertising; (c) identify you across multiple devices; (d) provide
            and monitor the effectiveness of our Service; (e) monitor aggregate metrics such as
            total number of visitors, traffic, usage, and demographic patterns on our website; (f)
            diagnose or fix technology problems; and (g) otherwise to plan for and enhance our
            Service.
          </Box>

          <Box my={2}>
            If you would prefer not to accept cookies, most browsers will allow you to: (i) change
            your browser settings to notify you when you receive a cookie, which lets you choose
            whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to
            automatically reject cookies. Please note that doing so may negatively impact your
            experience using the Service, as some features and services on our Service may not work
            properly. Depending on your mobile device and operating system, you may not be able to
            delete or block all cookies. You may also set your e-mail options to prevent the
            automatic downloading of images that may contain technologies that would allow us to
            know whether you have accessed our e-mail and performed certain functions with it.
            Deleting cookies does not delete Local Storage Objects (LSOs) such as Flash objects and
            HTML5. If you choose to delete Flash objects from our sites, then you may not be able to
            access and use all or part of the sites or benefit from the information and services
            offered.
          </Box>

          <Box my={2}>
            We and our third-party partners may also use cookies and tracking technologies for
            advertising purposes.
          </Box>

          <Typography variant="subtitle1" my={2}>
            Sharing Information
          </Typography>
          <Box my={2}>
            We may share your personal information in the instances described below.{' '}
          </Box>
          <Box my={2}>
            Other companies owned by or under common ownership as Vetcher, which also includes our
            subsidiaries (i.e., any organization we own or control) or our ultimate holding company
            (i.e., any organization that owns or controls us) and any subsidiaries it owns. These
            companies will use your personal information in the same way as we can under this
            Policy; Third party vendors, consultants and other service providers that perform
            services on our behalf, in order to carry out their work for us, which may include
            identifying and serving targeted advertisements, hosting services, or providing
            analytics services; Other parties in connection with any company transaction, such as a
            merger, sale of all or a portion of company assets or shares, reorganization, financing,
            change of control or acquisition of all or a portion of our business by another company
            or third party or in the event of bankruptcy or related or similar proceedings; and
            third parties as required to (i) satisfy any applicable law, regulation, subpoena/court
            order, legal process or other government request, (ii) enforce our Terms of Service,
            including the investigation of potential violations thereof, (iii) investigate and
            defend ourselves against any third party claims or allegations, (iv) protect against
            harm to the rights, property or safety of Vetcher, its users or the public as required
            or permitted by law and (v) detect, prevent or otherwise address criminal (including
            fraud or stalking), security or technical issues.
          </Box>
          <Box mt={2} mb={3}>
            We may also share information with others in an aggregated and anonymous form that does
            not reasonably identify you directly as an individual.
          </Box>
          <Typography className={classes.headingTextColor} variant="h2">
            How we store your personal data
          </Typography>
          <Typography variant="subtitle1" my={2}>
            Security
          </Typography>
          <Box my={2}>
            We take appropriate measures to ensure that all personal data is kept secure including
            security measures to prevent personal data from being accidentally lost, or used or
            accessed in any unauthorized way. We limit access to your personal data to those who
            have a genuine business need to view it. Those processing your information will do so
            only in an authorized manner and are subject to a duty of confidentiality.
          </Box>
          <Box my={2}>
            We also have procedures in place to deal with any suspected data security breach. We
            will notify you and any applicable regulator of a suspected data security breach where
            we are legally required to do so.
          </Box>
          <Box mt={2} mb={3}>
            Unfortunately, the transmission of information via the internet is not completely
            secure. Although we will do our best to protect your personal data, we cannot guarantee
            the security of your data transmitted through any online means, therefore any
            transmission remains at your own risk.
          </Box>

          <Typography className={classes.headingTextColor} variant="h2" my={2}>
            Your rights
          </Typography>
          <List
            dense
            subheader={
              'Under the General Data Protection Regulation, you have a number of important rights. In summary, those include rights to:'
            }
          >
            <ListItem className={classes.listItem}>
              <ListItemText primary="Access to your personal data and to certain other supplementary information that this Privacy Notice is already designed to address." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Require us to correct any mistakes in your information which we hold." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Request the erasure of personal data concerning you in certain situations." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Request access to the personal data concerning you which you have provided to us, in a structured, commonly used, and machine-readable format and have the right to transmit those data to a third party in certain situations." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Object at any time to processing of personal data concerning you for direct marketing." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Object in certain other situations to our continued processing of your personal data." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Otherwise restrict our processing of your personal data in certain circumstances." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Claim compensation for damages caused by our breach of any data protection laws." />
            </ListItem>
          </List>
          <Box mt={2}>If you would like to exercise any of those rights, please either:</Box>
          <List dense>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={
                  <span>
                    Contact us by email <Link href="mailto:info@vetcher.com">info@vetcher.com</Link>
                    , ensuring we have enough information to identify you, proving your identity,
                    address and confirming which information to which your request relates.
                  </span>
                }
              />
            </ListItem>
          </List>

          <Typography className={classes.headingTextColor} variant="h2" my={2}>
            How to complain
          </Typography>
          <Box my={2}>
            We hope that we can resolve any query or concern you raise about our use of your
            information.
          </Box>
          <Box mt={2}>
            The General Data Protection Regulation also gives you right to lodge a complaint with a
            supervisory authority, in particular in the European Union (or European Economic Area)
            state where you work, normally live or where any alleged infringement of data protection
            laws occurred.
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  headingTextColor: {
    color: theme.palette.common.black,
  },
  listItem: {
    position: 'relative',
    '&::before': {
      content: '"•"',
      position: 'absolute',
      left: 0,
      color: theme.palette.text.primary,
      fontSize: '1.2em',
    },
  },
}));
