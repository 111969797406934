import { IAppRole, IAppUser, IUserInfo } from '../models';
import { IResponse } from '../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/Admin';

export const getUsers = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  searchText?: string;
  isActive?: string;
}): Promise<IResponse<IUserInfo[]>> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/usermanagement`, filters);
    return data;
  } catch (error) {
    logError(error, 'getUsers failed');
    return Promise.reject(error);
  }
};

export const getUser = async (userId: number): Promise<IAppUser> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/usermanagement/${userId}`);
    return data;
  } catch (error) {
    logError(error, 'getUser failed');
    return Promise.reject(error);
  }
};

export const updateUserRoles = async (
  id: number,
  roles: (string | undefined)[],
  locations: any[]
): Promise<any> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}/usermanagement/${id}`, { roles, locations });
    return data;
  } catch (error) {
    logError({error, payload: {id, roles, locations}}, 'updateUserRoles failed');
    return Promise.reject(error);
  }
};

export const getAppRoles = async (): Promise<IAppRole[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/AppRoles`);
    return data;
  } catch (error) {
    logError(error, 'getAppRoles failed');
    return Promise.reject(error);
  }
};