import { IHolidayContainer } from './../models/holidays';
import { authFetch } from './fetch';
import { IHoliday } from '../models/holidays';
import { logError } from '../services';

const baseUrl = '/admin/holidays';

export const getHolidays = async (): Promise<IHoliday[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}`);
    return data;
  } catch (error) {
    logError(error, 'getHolidays failed');
    return Promise.reject(error);
  }
};

export const createHoliday = async (holiday: IHolidayContainer): Promise<string> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, holiday);
    return data;
  } catch (error) {
    logError({error, payload: holiday}, 'createHoliday failed');
    return Promise.reject(error);
  }
};

export const updateHoliday = async (holiday: IHolidayContainer): Promise<string> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}`, holiday);
    return data;
  } catch (error) {
    logError({error, payload: holiday}, 'updateHoliday failed');
    return Promise.reject(error);
  }
};
export const deleteHoliday = async (holidayId: number | string): Promise<string> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${holidayId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteHoliday failed');
    return Promise.reject(error);
  }
};
