import { FC, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { ICalendarMenuOption } from '.';

export interface ICalendarEventMenu {
  menuItems: ICalendarMenuOption[];
  darkBackground?: boolean;
}
export const CalendarEventMenu: FC<ICalendarEventMenu> = ({
  menuItems,
  darkBackground = false,
}) => {
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="dvm-event-menu-button"
        aria-controls={open ? 'dvm-event-menu-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: darkBackground ? 'white' : '' }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="dvm-event-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dvm-event-menu-button',
        }}
      >
        {' '}
        {menuItems.map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            key={`${item.label}-${index}`}
            onClick={() => {
              item.action();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
