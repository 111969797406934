import {
  Grid,
  Skeleton,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FC, ReactNode, useEffect } from 'react';
import {
  Cell,
  ColumnInterface,
  Row,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  UseTableOptions,
} from 'react-table';
import { formatPercent, getLocalDate, getLocalDateTime } from '../../helpers';
import { Pagination } from './Pagination';

// `ITableColumn` is our version of `Column`, or rather, `ColumnInterface`
export interface ITableColumn<D extends object = {}> extends ColumnInterface<D> {
  accessor?: ((item: Row<D>) => string | number) | string;
  canFilter?: boolean;
  className?: string;
  columnAlignment?: TableCellProps['align'];
  filterType?: 'input' | 'autocomplete';
  handleClickColumn?: (columnId: any) => void;
  hideLoad?: boolean;
  isCentered?: boolean;
  isCurrency?: boolean;
  isDate?: boolean;
  isDateTime?: boolean;
  isNumber?: boolean;
  isPercent?: boolean;
  isServerSorted?: boolean;
  isServerSortedDesc?: boolean;
  overrideWidth?: number;
  sort?: boolean;
}

interface ITable<D extends object = {}> extends Pick<UseTableOptions<D>, 'data' | 'columns'> {
  cellClasses?: string | ((original: D) => string);
  centerPagination?: boolean;
  containerClasses?: string;
  handlePage?: (val: number) => void;
  handleRowsPerPage?: (val: number) => void;
  headerClasses?: string;
  hideDeleted?: boolean;
  hidePagination?: boolean;
  isLoading?: boolean;
  loadingPageSize?: number;
  LoadMoreComponent?: FC;
  mobileProps?: Record<string, unknown>;
  noResultsText?: string;
  onRowsPerPageChange?: (rows: number) => void;
  ResponsiveComponent?: FC<any>;
  ResponsiveComponentLoader?: FC;
  rowClasses?: string | ((original: D) => string);
  rowOnClick?: (val: unknown) => void;
  serverPage?: number;
  serverRecordCount?: number;
  serverPerPage?: number;
  stickyHeader?: boolean;
  tableSize?: 'medium' | 'small';
  useTableProps?: UseTableOptions<D>;
  Cell?: (data: any) => JSX.Element;
  rowsPerPageOptions?: number[];
}

const currencyRE = /\B(?=(\d{3})+(?!\d))/g;

const formatCell = (cell: Cell<{}>): ReactNode => {
  const tColumn = cell.column as ITableColumn; // ColumnInstance with extra properties

  if (tColumn.id === 'states') {
    const codes = cell.value.map((state: any) => state.code).join(', ');

    return (
      <Tooltip title={codes} enterDelay={500} leaveDelay={200}>
        <span>{codes}</span>
      </Tooltip>
    );
  }

  if (tColumn.id === 'name') {
    return (
      <Tooltip title={cell.value} enterDelay={500} leaveDelay={200}>
        <span>{cell.render('Cell')}</span>
      </Tooltip>
    );
  }

  if (tColumn.isDate) return getLocalDate(cell.value);
  if (tColumn.isDateTime) return getLocalDateTime(cell.value);
  else if (tColumn.isCurrency) return `$${Number(cell.value).toFixed(2).replace(currencyRE, ',')}`;
  else if (tColumn.isPercent) return formatPercent(cell.value);
  else return cell.render('Cell');
};

const getCellStyle = (column: ITableColumn, type: 'header' | 'cell'): React.CSSProperties => {
  let style: React.CSSProperties = { cursor: 'inherit' };

  if (column.id === 'states' || column.id === 'name') {
    style = {
      ...style,
      maxWidth: '300px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };
  }

  if (column.isDate) {
    style = {
      ...style,
    };
  } else if (column.isNumber) {
    style = {
      ...style,
      textAlign: 'right',
      paddingRight: '3rem',
    };
  } else if (column.isCurrency) {
    style = {
      ...style,
    };
  } else if (column.isCentered) {
    style = {
      ...style,
      textAlign: 'center',
    };
  }

  if (column.overrideWidth) {
    style = {
      ...style,
      width: column.overrideWidth,
      maxWidth: column.overrideWidth,
      wordWrap: 'break-word', // IE11
      overflowWrap: 'break-word',
    };
  }

  if (type === 'header') {
    style = {
      ...style,
      fontWeight: 'bold',
      cursor: column.handleClickColumn ? 'pointer' : 'inherit',
      color: '#a81f25',
    };
  }

  return style;
};

export const Table: FC<ITable> = ({
  cellClasses = '',
  centerPagination,
  columns,
  containerClasses = '',
  data,
  headerClasses,
  hideDeleted,
  hidePagination,
  isLoading,
  loadingPageSize,
  LoadMoreComponent,
  mobileProps,
  noResultsText = 'No Results',
  onRowsPerPageChange,
  ResponsiveComponent,
  ResponsiveComponentLoader,
  rowClasses,
  rowOnClick,
  serverPage,
  serverRecordCount,
  serverPerPage,
  handlePage,
  handleRowsPerPage,
  stickyHeader = false,
  tableSize = 'small',
  useTableProps = {},
  rowsPerPageOptions,
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      userPageCount: 1,
      manualPagination: hidePagination,
      autoResetPage: isLoading,
      autoResetSortBy: isLoading,
      ...useTableProps,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const setPage = (page: number) => {
    gotoPage(page);
  };

  // update page size if we hide pagination
  useEffect(() => {
    data.length ? setPageSize(Number(data.length)) : setPageSize(Number(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // always start on first page if data length changes, ie; filter was applied
  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);
  return (
    <>
      <TableContainer className={clsx(containerClasses, stickyHeader ? classes.stickyHeader : '')}>
        <MaUTable
          stickyHeader={stickyHeader}
          size={tableSize}
          {...getTableProps()}
          style={ResponsiveComponent && !isLoading ? { display: 'block' } : undefined}
        >
          {isDesktop || !ResponsiveComponent ? (
            <>
              {/** TABLE HEADER */}
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {/** TABLE HEADER COLUMNS */}
                    {headerGroup.headers.map((column: any) => (
                      <TableCell
                        align={column?.columnAlignment ?? column?.isCurrency ? 'right' : 'left'}
                        {...((column as ITableColumn).sort !== false
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : column.getHeaderProps())}
                        title={column.title || ''}
                        className={headerClasses}
                        style={getCellStyle(column, 'header')}
                        onClick={e => {
                          const { handleClickColumn, sort } = column as ITableColumn;
                          const sortProps: any = column.getHeaderProps(
                            column.getSortByToggleProps()
                          );

                          // If the column is sortable and there's an onClick handler, then call it
                          const { onClick } = sortProps || {};
                          sort !== false && onClick && onClick(e);

                          // Also run column click handler if passed
                          handleClickColumn && handleClickColumn(column.id);
                        }}
                      >
                        {(column as ITableColumn).sort !== false ||
                        (column as ITableColumn).isServerSorted ? (
                          <TableSortLabel
                            active={column.isSorted || (column as ITableColumn).isServerSorted}
                            direction={
                              column.isSortedDesc || (column as ITableColumn).isServerSortedDesc
                                ? 'desc'
                                : 'asc'
                            }
                          >
                            {column.render('Header')}
                          </TableSortLabel>
                        ) : (
                          column.render('Header')
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              {/** TABLE BODY ROWS */}
              <TableBody>
                {isLoading
                  ? new Array(loadingPageSize || pageSize || 3).fill('').map((_, j) => (
                      <TableRow
                        key={`table-row-skeleton-${j}`}
                        {...(rowOnClick ? { onClick: rowOnClick } : {})}
                      >
                        {/** LOADING SKELETON */}
                        {columns.map((column: any, i) => (
                          <TableCell
                            align={column?.columnAlignment ?? 'left'}
                            key={`skeleton-cell-${i}`}
                            style={getCellStyle(column as ITableColumn, 'cell')}
                            className={
                              typeof cellClasses === 'function' ? cellClasses({}) : cellClasses
                            }
                          >
                            {column.hideLoad ? null : <Skeleton />}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  : page.map((row: Row) => {
                      prepareRow(row);
                      return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                        <TableRow
                          {...row.getRowProps()}
                          className={
                            typeof rowClasses === 'function' ? rowClasses(row.original) : rowClasses
                          }
                          {...(rowOnClick ? { onClick: () => rowOnClick(row.original) } : {})}
                        >
                          {row.cells.map(cell => {
                            const tableColumn: ITableColumn | undefined = cell?.column;
                            return (
                              <TableCell
                                align={
                                  tableColumn?.columnAlignment || tableColumn?.isCurrency
                                    ? 'right'
                                    : 'left'
                                }
                                {...cell.getCellProps()}
                                className={clsx(
                                  tableColumn?.className ?? '',
                                  typeof cellClasses === 'function'
                                    ? cellClasses(row.original)
                                    : cellClasses,
                                  tableColumn?.className ?? ''
                                )}
                                style={getCellStyle(tableColumn, 'cell')}
                              >
                                {formatCell(cell)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ) : null;
                    })}
              </TableBody>
            </>
          ) : (
            <TableBody className={isLoading ? undefined : classes.mobileTable}>
              {/** TABLE BODY FROM RESPONSIVE COMPONENT */}
              {isLoading ? (
                <TableRow>
                  <TableCell
                    className={clsx(
                      classes.mobileCell,
                      typeof cellClasses === 'function' ? cellClasses({}) : cellClasses
                    )}
                  >
                    {ResponsiveComponentLoader ? <ResponsiveComponentLoader /> : <Skeleton />}
                  </TableCell>
                </TableRow>
              ) : (
                (page as Row[]).map((row, i) => {
                  prepareRow(row);
                  return !hideDeleted || (hideDeleted && !(row.original as any).isDeleted) ? (
                    <TableRow
                      {...row.getRowProps()}
                      className={
                        typeof rowClasses === 'function' ? rowClasses(row.original) : rowClasses
                      }
                      {...(rowOnClick ? { onClick: () => rowOnClick(row.original) } : {})}
                      style={{ display: 'block' }}
                    >
                      <TableCell
                        key={`row-${i}-mobile-cell`}
                        className={clsx(
                          classes.mobileCell,
                          typeof cellClasses === 'function'
                            ? cellClasses(row.original)
                            : cellClasses
                        )}
                        style={{ display: 'block' }}
                      >
                        <ResponsiveComponent
                          key={`responsive-row-${i}`}
                          {...row}
                          {...useTableProps}
                          {...mobileProps}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null;
                })
              )}
            </TableBody>
          )}
        </MaUTable>

        {/** NO RESULTS DISPLAY */}
        {!isLoading && data.length === 0 && (
          <Grid container justifyContent="center">
            <Typography className={classes.noResults} variant="body1" gutterBottom>
              {noResultsText}
            </Typography>
          </Grid>
        )}

        {LoadMoreComponent && <LoadMoreComponent />}
      </TableContainer>

      {!isLoading &&
        data.length > 0 &&
        !hidePagination &&
        !isNaN(serverPage as number) &&
        !isNaN(serverPerPage as number) &&
        !isNaN(serverRecordCount as number) &&
        handlePage &&
        handleRowsPerPage && (
          <div className={classes.centerPagination}>
            <Pagination
              count={serverRecordCount as number}
              rowsPerPage={serverPerPage as number}
              page={serverPage as number}
              setPage={handlePage}
              setRowsPerPage={handleRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </div>
        )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  noResults: {
    marginTop: '1rem',
  },
  stickyHeader: {
    flexGrow: 1,
    maxHeight: '100%',
  },
  borderNone: {
    border: 'none',
  },
  mobileTable: {
    display: 'block',
  },
  mobileCell: {
    padding: 0,
    border: 0,
  },
  centerPagination: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
