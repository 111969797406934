import { IPostShiftRequest, IRSSShiftRequestContainer, IRSSUpdateShift, IRSSUpdateShiftRequest } from '../models';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/ShiftRequests';

export const createDVMShiftRequest = async (dvmShiftRequests: IPostShiftRequest): Promise<any> => {
  try {
    const data = await authFetch.post(`${baseUrl}/DVMCreateShiftRequests`, dvmShiftRequests);
    return data;
  } catch (error) {
    logError({error, payload: dvmShiftRequests}, 'createDVMShiftRequest failed');
    return Promise.reject(error);
  }
};

export const createRSSShiftRequest = async (
  shiftRequest: IRSSShiftRequestContainer
): Promise<any> => {
  try {
    const data = await authFetch.post(`${baseUrl}/RSSCreateShiftRequest`, shiftRequest);
    return data;
  } catch (error) {
    logError({error, payload: shiftRequest}, 'createRSSShiftRequest failed');
    return Promise.reject(error);
  }
};

export const updateRSSShiftRequest = async (
  shiftRequest: IRSSUpdateShiftRequest
): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/RSSUpdateShiftRequest`, shiftRequest);
    return data;
  } catch (error) {
    logError({error, payload: shiftRequest}, 'updateRSSShiftRequest failed');
    return Promise.reject(error);
  }
};

export const updateRSSShift = async (
  shiftRequest: IRSSUpdateShift
): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/RSSUpdateShiftRequestByShift`, shiftRequest);
    return data;
  } catch (error) {
    logError({error, payload: shiftRequest}, 'updateRSSShift failed');
    return Promise.reject(error);
  }
};

export const cancelShiftRequest = async (shiftRequestId: number, reason: string): Promise<any> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}/DVMCancelShiftRequest`, {shiftRequestId, reason});
    return data;
  } catch (error) {
    logError({error, payload: {shiftRequestId, reason}}, 'cancelShiftRequest failed');
    return Promise.reject(error);
  }
};

export const getShiftRequestsCancelationActivity = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
}): Promise<any> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/cancelation-activity`, filters);
    return data;
  } catch (error) {
    logError(error, 'getShiftRequestsCancelationActivity failed');
    return Promise.reject(error);
  }
};

export const acknowledgeCancelation = async (shiftRequestId: number, isAcknowledged: boolean): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/${shiftRequestId}/acknowledge-cancelation?IsAcknowledged=${isAcknowledged}`, {});
    return data;
  } catch (error) {
    logError({error, payload: {shiftRequestId, isAcknowledged}}, 'acknowledgeCancelation failed');
    return Promise.reject(error);
  }
};