import { Box, Grid, useMediaQuery, FormControlLabel, Checkbox } from '@mui/material';
import { FC, useState, useEffect, useMemo } from 'react';
import { Loader, Page } from '../../components';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn, sortable } from '../../models/util';
import {
  getShiftRequestsCancelationActivity,
  acknowledgeCancelation,
} from '../../fetch/shift-requests';
import { IShiftRequestsCancelationActivity } from '../../models/shift-requests';
import { formatDate, formatUtcToLocalShortFriendlyDateWithTime } from '../../helpers';
import { Pagination } from '../../components/table/Pagination';

export const Activity: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shiftRequests, setShiftRequests] = useState<IShiftRequestsCancelationActivity[]>([]);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [isSaving, setSaving] = useState<{
    [id: number]: boolean;
  }>({});
  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [selectedSort, setSelectedSort] = useState<string>('CanceledDate');
  const [sortDirection, setSortDirection] = useState<{
    ReadState?: sortable;
    DvmName?: sortable;
    LocationName?: sortable;
    ShiftDate?: sortable;
    CanceledDate?: sortable;
  }>({
    CanceledDate: 'Desc',
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchShiftRequests = async () => {
    try {
      setIsLoading(true);
      const res = await getShiftRequestsCancelationActivity({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
      });
      setShiftRequests(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading holidays, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShiftRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection]);

  const handleCheckboxComplete = async (original: IShiftRequestsCancelationActivity) => {
    setSaving({
      ...isSaving,
      [original.shiftRequestId]: true,
    });

    // Optimistically update the UI
    const updatedShiftRequests = shiftRequests.map(item =>
      item.shiftRequestId === original.shiftRequestId
        ? { ...item, cancelationAcknowledged: !item.cancelationAcknowledged }
        : item
    );
    setShiftRequests(updatedShiftRequests);

    try {
      await acknowledgeCancelation(original.shiftRequestId, !original.cancelationAcknowledged);
    } catch (error: any) {
      setShiftRequests(shiftRequests);
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error updating item, please try again.`, {
        variant: 'error',
      });
    } finally {
      setSaving({
        ...isSaving,
        [original.shiftRequestId]: false,
      });
    }
  };

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const columns = useMemo((): IColumn[] => {
    const baseColumns: IColumn[] = [
      {
        Header: 'DVM Name',
        accessor: 'dvmName',
        isServerSorted: selectedSort === 'DvmName',
        isServerSortedDesc: sortDirection.DvmName === 'Desc',
        handleClickColumn: () => handleClickColumn('DvmName'),
      },
      {
        Header: 'Location',
        accessor: 'location',
        isServerSorted: selectedSort === 'LocationName',
        isServerSortedDesc: sortDirection.LocationName === 'Desc',
        handleClickColumn: () => handleClickColumn('LocationName'),
      },
      {
        Header: 'Shift Date',
        accessor: 'shiftDate',
        isServerSorted: selectedSort === 'ShiftDate',
        isServerSortedDesc: sortDirection.ShiftDate === 'Desc',
        handleClickColumn: () => handleClickColumn('ShiftDate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestsCancelationActivity } };
        }) => {
          return <span>{formatDate(original?.shiftDate)}</span>;
        },
      },
      {
        Header: 'Canceled Date/Time',
        accessor: 'canceledDateTime',
        isServerSorted: selectedSort === 'CanceledDate',
        isServerSortedDesc: sortDirection.CanceledDate === 'Desc',
        handleClickColumn: () => handleClickColumn('CanceledDate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestsCancelationActivity } };
        }) => {
          return (
            <span>{formatUtcToLocalShortFriendlyDateWithTime(original?.canceledDateTime)}</span>
          );
        },
      },
    ];

    if (!isMobile) {
      baseColumns.unshift({
        Header: 'Mark as read',
        accessor: 'cancelationAcknowledged',
        isServerSorted: selectedSort === 'ReadState',
        isServerSortedDesc: sortDirection.ReadState === 'Desc',
        handleClickColumn: () => handleClickColumn('ReadState'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestsCancelationActivity } };
        }) => {
          return (
            <Box display="flex" paddingLeft={4}>
              <FormControlLabel
                color="primary"
                control={
                  <Checkbox
                    checked={original.cancelationAcknowledged === true}
                    id="orders-checkbox"
                    onChange={(_, checked) => handleCheckboxComplete(original)}
                  />
                }
                label=""
              />
            </Box>
          );
        },
      });
    }

    return baseColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftRequests, isMobile]);

  return (
    <Page title={'Activity'}>
      {isLoading && <Loader type="fullscreen" position="centered" title="Loading..." />}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={shiftRequests}
            hidePagination
            isLoading={isLoading}
            ResponsiveComponent={isMobile ? MobileTable : undefined}
            mobileProps={{
              fields: columns
                .filter(col => col.Header)
                .map(col => ({
                  name: col.Header,
                  accessor: col.accessor,
                  Cell: col?.Cell,
                })),
              mobileAccordionLabel: 'dvmName',
              handleCheckboxComplete: async (original: IShiftRequestsCancelationActivity) => {
                await handleCheckboxComplete(original);
              },
            }}
          />
          {!isLoading && (
            <Grid item xs={12} container justifyContent="center">
              <Pagination
                page={page}
                count={recordCount}
                rowsPerPage={perPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
