import * as localForage from 'localforage';

/**
 * Sets an item in local storage. NOTE: Only for use within the browser.
 * @param key string
 * @param value any
 * @returns boolean
 */
export const setLocalStorage = async (key: string, value: any): Promise<boolean> => {
    try {
        const item = JSON.stringify(value);
        await localForage.setItem(key, item);
        return true;
    } catch (error) {
        console.error('setLocalStorage', error);
        return false;
    }
};

/**
 * Gets an item from local storage. NOTE: Only for use within the browser.
 * @param key string
 * @returns any
 */
export const getLocalStorage = async (key: string): Promise<any | undefined> => {
    try {
        const item: any = await localForage.getItem(key);
        if (item) {
            const parsed = JSON.parse(item);
            return parsed;
        } else {
            return undefined;
        }
    } catch (error) {
        console.error('getLocalStorage', error);
        return undefined;
    }
};

/**
 * Removes an item from local storage. NOTE: Only for use within the browser.
 * @param key string
 * @returns boolean
 */
export const removeLocalStorage = async (key: string): Promise<boolean> => {
    try {
        await localForage.removeItem(key);
        return true;
    } catch (error) {
        console.error('removeLocalStorage', error);
        return false;
    }
};
