import { ArrowBack } from '@mui/icons-material';
import { Divider, Grid, IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { PageTitle } from './PageTitle';
import { useHistory } from 'react-router';

export interface IPageWithBackTitleProps {
  backText?: string;
  backUrl?: string;
  title?: string;
  additionalHeaderContent?: JSX.Element;
  subTitle?: string | JSX.Element;
  showDivider?: boolean;
  isExternalLink?: boolean;
}

export const PageTitleWithBack: FC<IPageWithBackTitleProps> = ({
  backText = 'Back',
  showDivider = true,
  backUrl,
  title,
  additionalHeaderContent,
  subTitle,
  isExternalLink = false,
}) => {
  const classes = useStyles({ marginBottom: false });
  const history = useHistory();
  return (
    <>
      <Grid container className={classes.titleWrapper}>
        <Grid item>
          <IconButton
            title={backText}
            onClick={() =>
              isExternalLink
                ? (window.location.href = backUrl as string)
                : history.push(backUrl as string)
            }
            size="large"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.pageTitleWrapper}>
          <PageTitle
            showDivider={false}
            title={title}
            additionalHeaderContent={additionalHeaderContent}
            subTitle={subTitle}
          />
        </Grid>
      </Grid>
      {showDivider && title && <Divider className={classes.divider} />}
    </>
  );
};

const useStyles = makeStyles<Theme, { marginBottom: boolean }>(theme => ({
  divider: {
    marginTop: 0,
  },
  pageTitleWrapper: {
    '@media (min-width: 768px)': {
      flex: 1,
    },
  },
  titleWrapper: {
    flexWrap: 'nowrap',
  },
  subTitle: {
    display: 'block',
    color: '#000',
    marginTop: 5,
  },
}));
