import { Box, Button, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMsal } from '@azure/msal-react';
import { Theme, lighten } from '@mui/material/styles';
import {
  AccountCircle as AccountCircleIcon,
  ArrowForwardOutlined as ArrowForwardOutlinedIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import clsx from 'clsx';
import { FC, useContext, useState } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Logo } from './';
import { UserContext } from '../../context';
const config = require('../../buildSettings.json');

interface IHeaderProps {
  setShowNav(showNav: boolean): void;
  showNav: boolean;
  height: number;
}

export const Header: FC<IHeaderProps> = ({ setShowNav, showNav, height }) => {
  const { user, isDVMPending, isActive } = useContext(UserContext);
  const { instance } = useMsal();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          height,
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: theme => theme.palette.common.white,
        }}
      >
        <Toolbar className={classes.toolbar} disableGutters sx={{ height }}>
          {/* MENU BUTTON */}
          <IconButton
            onClick={
              !isDVMPending && isActive ? () => setShowNav(!showNav) : () => setShowNav(false)
            }
            color="primary"
            aria-label="Toggle sidebar navigation text."
          >
            <MenuIcon className={classes.hamburger} />
          </IconButton>

          {/* LOGO */}
          <Logo />

          <Grid item className={classes.searchCol}>
            <div className={classes.innerCol}>
              {/* DROPDOWN BUTTON */}
              <Button
                onClick={event => setAnchorEl(event.currentTarget)}
                size="medium"
                variant="contained"
                className={clsx(classes.headerButton, classes.accountButton)}
                aria-controls="customized-menu"
                aria-haspopup="true"
              >
                <AccountCircleIcon className={clsx(classes.accountIcon, classes.accountIcon)} />
                <span className={clsx(classes.buttonText, classes.accountText)}>
                  {user?.account?.username}
                </span>
              </Button>

              {/* DROPDOWN MENU */}
              <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {/* LOGOUT */}
                <MenuItem
                  onClick={async () => {
                    try {
                      // Log out of MSAL
                      await instance.logoutRedirect({
                        postLogoutRedirectUri: `${config.REACT_APP_MSAL_REDIRECT}`,
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  className={clsx(classes.logOutItem, classes.accountItem)}
                >
                  <ArrowForwardOutlinedIcon className={classes.iconFill} />
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    innerCol: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      flex: '1',
      justifyContent: 'flex-end',
    },
    searchCol: {
      paddingRight: '1rem',
      flexGrow: 1,
    },
    headerButton: {
      color: theme.palette.common.black,
      display: 'flex',
      height: '45px',
      marginBottom: 'auto',
      marginTop: 'auto',
      justifyContent: 'space-between',
    },
    hamburger: {
      fill: theme.palette.primary.main,
    },
    accountButton: {
      backgroundColor: lighten(theme.palette.common.white, 0.4),
      '&:hover': {
        backgroundColor: lighten(theme.palette.common.white, 0.4),
      },
    },
    buttonText: {
      color: theme.palette.common.black,
      fontWeight: 'normal',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    accountText: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline',
      },
    },
    accountIcon: {
      height: '31px',
      width: '31px',
      color: theme.palette.secondary.main,
    },
    logOutItem: {
      color: theme.palette.secondary.main,
    },
    iconFill: {
      fill: theme.palette.secondary.main,
    },
    accountItem: {
      '& > svg': {
        marginRight: theme.spacing(0.5),
      },
    },
    toolbar: {
      marginLeft: theme.spacing(1),
      columnGap: theme.spacing(1.5),
    },
  };
});
