import React, { createContext, Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { theme } from '../styles';
import { useMediaQuery } from '@mui/material';
import { SIDENAV_WIDTH_EXPANDED, SIDENAV_WIDTH_COLLAPSED } from '../components';

interface INavContext {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  sideNavWidth: number;
}

interface INavContextHandlerProps {
  children: React.ReactNode;
}

export const NavContext = createContext<INavContext>({
  isExpanded: false,
  setIsExpanded: () => {},
  sideNavWidth: 0,
});

export const NavContextHandler: FC<INavContextHandlerProps> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px)`);

  const sideNavWidth = useMemo(() => {
    if (isExpanded && isMobile) {
      // Show Icons and Labels
      return SIDENAV_WIDTH_EXPANDED;
    }
    if (isExpanded) {
      // Show Icons and Labels
      return SIDENAV_WIDTH_EXPANDED;
    } else if (!isExpanded) {
      // Only show Icons
      return SIDENAV_WIDTH_COLLAPSED;
    } else {
      // Hide completely
      return 0;
    }
  }, [isExpanded, isMobile]);

  return (
    <NavContext.Provider
      value={{
        isExpanded,
        setIsExpanded,
        sideNavWidth,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
