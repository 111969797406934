import { authFetch } from './fetch';
import { logError } from '../services';
const baseUrl = '/admin/LegalEntities';

export const getLegalEntities = async (): Promise<any> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}`);
    return data;
  } catch (error) {
    logError(error, 'getLegalEntities failed');
    return Promise.reject(error);
  }
};

export const getLegalEntity = async (legalEntityId: string | number): Promise<any> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${legalEntityId}`);
    return data;
  } catch (error) {
    logError(error, 'getLegalEntity failed');
    return Promise.reject(error);
  }
};

export const createLegalEntity = async (info: {
  name: string;
  code: string;
  stateIds: number[] | null;
  taxType: string;
}): Promise<any> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, info);
    return data;
  } catch (error: any) {
    logError({error}, 'createLegalEntity failed');
    return Promise.reject(error);
  }
};

export const updateLegalEntity = async (legalEntityId: string | number, info: {
  name: string;
  code: string;
  stateIds: number[] | null;
  taxType: string;
}): Promise<any> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}/${legalEntityId}`, info);
    return data;
  } catch (error: any) {
    logError({error}, 'updateLegalEntity failed');
    return Promise.reject(error);
  }
};
