import {
  ICalendarLocation,
  IFTPutSchedulePersist,
  IMarketCalendarSchedule,
  IRSSPutSchedulePersist,
} from './../models/schedule';
import { authFetch } from '.';
import { logError } from '../services';
const baseUrl = '/Schedules';

interface ISchedulingParams {
  marketIds?: never;
  locationIds: number;
  dateFrom: Date;
  dateTo: Date;
}

interface IMarketSchedulingParams {
  marketIds: number;
  locationIds?: never;
  dateFrom: Date;
  dateTo: Date;
  IncludeInactiveShifts: boolean;
}

type ILocationSchedulingParams = ISchedulingParams | IMarketSchedulingParams;

interface IScheduledDaysParams {
  dateFrom: Date;
  dateTo: Date;
}

export const getSchedule = async (
  params?: ILocationSchedulingParams
): Promise<ICalendarLocation[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/Locations`, params);
    return data;
  } catch (error) {
    logError(error, 'getSchedule failed');
    return Promise.reject(error);
  }
};

export const putFTPersist = async (requests: IFTPutSchedulePersist): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/FTPersist`, requests);
    return data;
  } catch (error: any) {
    logError({error, payload: requests}, 'putFTPersist failed');
    return Promise.reject(error);
  }
};

export const putRSSPersist = async (requests: IRSSPutSchedulePersist): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/RSSPersist`, requests);
    return data;
  } catch (error: any) {
    logError({error, payload: requests}, 'putRSSPersist failed');
    return error.response.data;
  }
};

export const getMarketSchedule = async (
  params?: ILocationSchedulingParams
): Promise<IMarketCalendarSchedule[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/Markets`, params);
    return data;
  } catch (error) {
    logError(error, 'getMarketSchedule failed');
    return Promise.reject(error);
  }
};
export const getScheduledDaysForDVM = async (
  dvmId: string,
  params?: IScheduledDaysParams
): Promise<string[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetScheduledDaysForDVM/${dvmId}`, params);
    return data;
  } catch (error) {
    logError(error, 'getScheduledDaysForDVM failed');
    return Promise.reject(error);
  }
};
