import { ILicense } from './../models/dvms';
import { authFetch } from './fetch';
import { logError } from '../services';
const baseUrl = '/license';

export const createLicense = async (license: ILicense, dvmId: number): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('LicenseId', '0');
    formData.append('DVMId', dvmId.toString());
    formData.append('State', license.state);
    if (license.expirationDate) {
      formData.append('ExpirationDate', new Date(license.expirationDate).toUTCString());
    } else {
      //@ts-ignore
      formData.append('ExpirationDate', '');
    }
    formData.append('FileDetails', license.file ?? null);
    const { data } = await authFetch.post(`${baseUrl}`, formData);
    return data;
  } catch (error) {
    logError({error, payload: {license, dvmId}}, 'createLicense failed');
    return Promise.reject(error);
  }
};

export const updateLicense = async (license: ILicense, dvmId: number): Promise<any> => {
  const formData = new FormData();
  formData.append('LicenseId', license.licenseId.toString());
  formData.append('DVMId', dvmId.toString());
  formData.append('State', license.state);
  if (license.expirationDate) {
    formData.append('ExpirationDate', new Date(license.expirationDate).toUTCString());
  } else {
    //@ts-ignore
    formData.append('ExpirationDate', '');
  }

  formData.append('FileDetails', license.file ?? null);
  try {
    const data = await authFetch.put(`${baseUrl}`, formData);
    return data;
  } catch (error) {
    logError({error, payload: {license, dvmId}}, 'updateLicense failed');
    return Promise.reject(error);
  }
};

export const deleteLicense = async (licenseId: number): Promise<any> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${licenseId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteLicense failed');
    return Promise.reject(error);
  }
};
