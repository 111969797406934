import { FC, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import { FilterList, Close, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { IDropdownResponse } from '../../models/util';

interface IUserFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  statuses: IDropdownResponse[];
  selectedStatus: string | null;
  setSelectedStatus: (val: string) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  handleSearch: (val: string) => void;
  hasAppliedFilters: boolean;
  setHasAppliedFilters: (val: boolean) => void;
}

export const UserFilters: FC<IUserFilters> = ({
  isLoading,
  applyFilters,
  statuses,
  selectedStatus,
  setSelectedStatus,
  setSearchValue,
  searchValue,
  handleSearch,
  hasAppliedFilters,
  setHasAppliedFilters,
}) => {
  const [isMobileFilterButtonOpen, toggleMobileFilter] = useState<boolean>(false);
  const classes = useStyles({ isMobileFilterButtonOpen });

  const noFilters = !selectedStatus && !searchValue;
  return (
    <>
      <Grid container spacing={1} alignItems="center" className={classes.searchWrapper}></Grid>
      <Button
        color="secondary"
        variant="contained"
        className={classes.mobileButton}
        startIcon={<FilterList />}
        endIcon={isMobileFilterButtonOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => {
          toggleMobileFilter(!isMobileFilterButtonOpen);
        }}
      >
        Filters
      </Button>
      {!isMobileFilterButtonOpen && <Divider className={classes.divider} />}
      <Grid container spacing={1} alignItems="center" className={classes.wrapper}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            variant="standard"
            autoComplete="nope"
            label="Search"
            name="search"
            classes={{ root: classes.searchRoot }}
            value={searchValue}
            size="small"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setHasAppliedFilters(true);
                applyFilters();
              }
            }}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1}>
          <FormControl
            classes={{ root: classes.formControlRoot }}
            fullWidth
            variant="standard"
            size="small"
          >
            <InputLabel htmlFor="Status" className={classes.ellipsis}>
              Status
            </InputLabel>
            <Select
              disabled={isLoading}
              name="Status"
              labelId="Status"
              id="Status"
              value={selectedStatus ?? ''}
              onChange={({ target: { value } }) => {
                setSelectedStatus(value ?? '');
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && searchValue.length > 0) {
                  setHasAppliedFilters(true);
                  applyFilters();
                }
              }}
            >
              <MenuItem key={`all`} value={''}>
                All
              </MenuItem>
              {statuses?.map((status, index) => {
                return (
                  <MenuItem key={`${status.value}`} value={status.value}>
                    {status.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={isLoading}
            startIcon={<FilterList />}
            onClick={() => {
              if (noFilters) {
                setHasAppliedFilters(false);
                applyFilters();
              } else {
                setHasAppliedFilters(true);
                applyFilters();
              }
            }}
          >
            Apply Filters
          </Button>
          {hasAppliedFilters && (
            <Button
              className={clsx(classes.button, classes.resetButton)}
              variant="contained"
              color="inherit"
              disabled={isLoading}
              startIcon={<Close />}
              onClick={() => {
                setSearchValue('');
                setHasAppliedFilters(false);
                setSelectedStatus('');
                applyFilters(true);
              }}
            >
              Reset
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobileFilterButtonOpen?: boolean }>((theme: Theme) => ({
  mobileButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    marginTop: 4,
    marginBottom: 4,
    height: 40,
    textTransform: 'capitalize',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginLeft: theme.spacing(2),
    },
  },
  resetButton: {
    '@media (min-width: 600px)': {
      marginLeft: 11,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  wrapper: ({ isMobileFilterButtonOpen }) =>
    isMobileFilterButtonOpen
      ? {
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
        }
      : {
          display: 'none',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
          },
        },
  divider: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  gridItem: {
    padding: `3px 8px !important`,
    [theme.breakpoints.up('sm')]: {
      padding: `8px !important`,
    },
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
  ellipsis: {
    fontSize: 14,
    width: 'auto',
    whiteSpace: 'normal',
    overflow: 'visible',
    '@media (min-width: 960px)': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '@media (min-width: 1400px)': {
      width: 'auto',
      whiteSpace: 'normal',
      overflow: 'visible',
    },
  },
  formControlRoot: {
    marginTop: 0,
  },
  textFieldRoot: {
    marginBottom: 0,
  },
  searchWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
  },
  clearSearchButton: {
    '@media (min-width: 408px)': {
      marginTop: 3,
      marginLeft: theme.spacing(1),
    },
  },
  searchButton: {
    minWidth: '8rem',
  },
  searchRoot: {
    marginTop: 0,
  },
}));
