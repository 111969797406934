import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { FC, ReactNode } from 'react';

interface IModal {
  actions?: JSX.Element;
  children: ReactNode;
  customButtonHandler?: Function;
  customButtonIcon?: JSX.Element;
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  /** Defaults to true. */
  fullWidth?: boolean;
  /** Defaults to false */
  isLoading?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  noPaddingContent?: boolean;
  subtitle?: string;
  titleContent?: JSX.Element | string;
}

/**
 * @usage
 * ```TypeScript
 * const Component: FC = () => {
 *   const [isModalOpen, setIsModalOpen] = useState(false);
 *   return (
 *     <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
 *       ...
 *     </Modal>
 *   );
 * ```
 * }
 */
export const Modal: FC<IModal & DialogProps> = ({
  actions,
  children,
  customButtonHandler,
  customButtonIcon,
  DialogContentProps,
  DialogTitleProps,
  fullWidth = true,
  isLoading = false,
  maxWidth,
  noPaddingContent,
  subtitle,
  titleContent,
  ...dialogProps
}) => {
  const classes = modalStyles({ noPaddingContent });

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth ?? 'lg'} {...dialogProps}>
      {/* CIRCLE LOADER */}
      <Backdrop open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {/* MODAL CONTENT */}
      {!isLoading && (
        <div className={classes.dialogContainer}>
          <MuiDialogTitle classes={{ root: classes.dialogTitle }} {...DialogTitleProps}>
            {titleContent && (
              <Typography className={classes.title} variant="h6">
                {titleContent}
              </Typography>
            )}
            {customButtonHandler && (
              <IconButton
                className={classes.customButton}
                onClick={() => customButtonHandler()}
                size="large"
              >
                {customButtonIcon}
              </IconButton>
            )}
            <IconButton
              aria-label="Close"
              className={classes.close}
              onClick={() => {
                dialogProps?.onClose?.({}, 'backdropClick');
              }}
              size="large"
            >
              <Close />
            </IconButton>
          </MuiDialogTitle>

          <MuiDialogContent
            classes={{ root: classes.dialogContent }}
            dividers={false}
            {...DialogContentProps}
          >
            {subtitle && (
              <Typography className={classes.subtitle} variant="h4">
                {subtitle}
              </Typography>
            )}
            {children}
          </MuiDialogContent>

          {actions && <DialogActions>{actions}</DialogActions>}
        </div>
      )}
    </Dialog>
  );
};

const modalStyles = makeStyles<Theme, { noPaddingContent?: boolean }>((theme: Theme) => {
  return {
    dialogContainer: {
      padding: '12px',
    },
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    customButton: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    dialogContent: {
      // minHeight is to give <Loader /> component top and bottom space, if shown with no content to overlay
      minHeight: theme.spacing(4),
      padding: ({ noPaddingContent }) => (noPaddingContent ? 0 : theme.spacing(0.5, 1.5, 1.5)),
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(1, 4, 1, 1.5),
    },
    subtitle: {
      color: theme.palette.secondary.main,
      fontSize: 28,
      lineHeight: 1.3,
      margin: theme.spacing(0, 0, 1),
    },
    title: {
      fontSize: 20,
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
  };
});
