import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/files';

export const getFile = async (fileId: string): Promise<any> => {
  try {
    const data = await authFetch.get(`${baseUrl}/${fileId}`, null, { responseType: 'blob' });
    return data;
  } catch (error) {
    logError(error, 'getFile failed');
    return Promise.reject(error);
  }
};
