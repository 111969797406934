import { authFetch } from './fetch';
import { ILocation, ILocationContainer } from '../models/locations';
import { IResponse } from '../models/util';
import { logError } from '../services';

const baseUrl = '/locations';

export const getLocations = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
}): Promise<IResponse<ILocation[]>> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}`, filters);
    return data;
  } catch (error) {
    logError(error, 'getLocations failed');
    return Promise.reject(error);
  }
};
export const getLocation = async (locationId: number): Promise<ILocation> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${locationId}`);
    return data;
  } catch (error) {
    logError(error, 'getLocation failed');
    return Promise.reject(error);
  }
};

export const createLocation = async (location: ILocationContainer): Promise<string> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, location);
    return data;
  } catch (error) {
    logError({error, payload: location}, 'createLocation failed');
    return Promise.reject(error);
  }
};

export const updateLocation = async (location: ILocationContainer): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}`, location);
    return data;
  } catch (error) {
    logError({error, payload: location}, 'updateLocation failed');
    return Promise.reject(error);
  }
};
export const deleteLocation = async (locationId: number): Promise<ILocation> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${locationId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteLocation failed');
    return Promise.reject(error);
  }
};
