import { authFetch } from './fetch';
import {
  DVMPeekInfo,
  IDVM,
  IDVMTable,
  IDVMUpdateContainer,
  IUpcomingShift,
  IPendingShiftRequest,
  IShiftRequestHistory,
} from '../models/dvms';
import { IDropdownResponse, IResponse } from '../models/util';
import { logError } from '../services';

const baseUrl = '/dvms';

export const getDvms = async (filters?: {
  DVMName?: string;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  city?: string;
  state?: string;
  dvmType?: string;
  DVMStatuses?: string[];
}): Promise<IResponse<IDVMTable[]>> => {
  try {
    const activeFilters = filters || {};

    const queryParams = new URLSearchParams();
    Object.keys(activeFilters).forEach(key => {
      const value = activeFilters[key as keyof typeof activeFilters];
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // For array fields with items, add each value under the same key
          value.forEach(item => queryParams.append(key, item));
        } else if (key.toLowerCase() === 'dvmstatuses') {
          // If DVMStatuses is empty, still add it to the query string with no value
          queryParams.append(key, '');
        }
      } else if (value !== undefined) {
        // For non-array fields, just set the parameter
        queryParams.set(key, value.toString());
      }
    });

    const { data } = await authFetch.get(`${baseUrl}?${queryParams.toString()}`);
    return data;
  } catch (error) {
    logError(error, 'getDvms failed');
    return Promise.reject(error);
  }
};

export const getDvm = async (dvmId: number): Promise<IDVM> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}`);
    return data;
  } catch (error) {
    logError(error, 'getDvm failed');
    return Promise.reject(error);
  }
};

export const updateDvm = async (dvm: IDVMUpdateContainer): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}`, dvm);
    return data;
  } catch (error) {
    logError({ error, payload: dvm }, 'updateDvm failed');
    return Promise.reject(error);
  }
};

export const updateLimitedDvm = async (dvm: IDVMUpdateContainer): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}/limited`, dvm);
    return data;
  } catch (error) {
    logError({ error, payload: dvm }, 'updateLimitedDvm failed');
    return Promise.reject(error);
  }
};

export const deleteDvm = async (dvmId: number): Promise<any> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${dvmId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteDvm failed');
    return Promise.reject(error);
  }
};

export const getDvmStatuses = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/DVMStatuses`);
    return data;
  } catch (error) {
    logError(error, 'getDvmStatuses failed');
    return Promise.reject(error);
  }
};

export const getDvmTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/DVMTypes`);
    return data;
  } catch (error) {
    logError(error, 'getDvmTypes failed');
    return Promise.reject(error);
  }
};

export const getDvmRequestsAndShifts = async (
  dvmId: number,
  mondayDate: string
): Promise<DVMPeekInfo> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}/RequestsAndShifts`, {
      MondayDate: mondayDate,
    });
    return data;
  } catch (error) {
    logError(error, 'getDvmRequestAndShifts failed');
    return Promise.reject(error);
  }
};

export const getDvmUpcomingShifts = async (dvmId: number): Promise<IUpcomingShift[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}/UpcomingShiftsForDVM`);
    return data;
  } catch (error) {
    logError(error, 'getDvmUpcomingShifts failed');
    return Promise.reject(error);
  }
};

export const getDvmPendingShiftRequests = async (
  dvmId: number
): Promise<IPendingShiftRequest[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}/PendingShiftRequests`);
    return data;
  } catch (error) {
    logError(error, 'getDvmPendingShiftRequests failed');
    return Promise.reject(error);
  }
};

export const getDvmShiftRequestHistory = async (
  dvmId: number,
  filters: {
    dateFrom: string;
    dateTo: string;
  }
): Promise<IShiftRequestHistory[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}/ShiftRequestHistory`, filters);
    return data;
  } catch (error) {
    logError(error, 'getDvmShiftRequestHistory failed');
    return Promise.reject(error);
  }
};

export const createDvm = async (info: {
  firstName: string;
  lastName: string;
  email: string;
}): Promise<number> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, info);
    return data;
  } catch (error: any) {
    logError({error}, 'createDvm failed');
    return Promise.reject(error);
  }
};

export const cancelDvmShift = async (
  shiftId: number,
  dvmId: number,
  reason: string
): Promise<number> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}/DVMCancelShift`, {shiftId, dvmId, reason});
    return data;
  } catch (error: any) {
    logError({ error, payload: {shiftId, dvmId, reason} }, 'cancelDvmShift failed');
    return Promise.reject(error);
  }
};

export const getScheduledShiftCount = async (dvmId: number): Promise<any> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${dvmId}/DVMDeactivationConfirmation`);
    return data;
  } catch (error) {
    logError(error, 'getScheduledShiftCount failed');
    return Promise.reject(error);
  }
};

export const verifyPendingEmail = async (dvmId: string, newEmail: string,  confirmationCode: string ): Promise<void> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}/${dvmId}/verify-email`, { newEmail, confirmationCode});
    return data;
  } catch (error) {
    logError(error, 'verifyPendingEmail failed');
    return Promise.reject(error);
  }
};


export const startEmailChange = async (dvmId: string, newEmail: string, ): Promise<void> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}/${dvmId}/start-email-change`, { newEmail, dvmId});
    return data;
  } catch (error) {
    logError(error, 'startEmailChange failed');
    return Promise.reject(error);
  }
};