import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FC } from 'react';
import image from '../../images/vetcher-logo-small-compressed.png';

interface ILogo {
  className?: string;
}

export const Logo: FC<ILogo> = ({ className }) => {
  const classes = useStyles();

  return <img src={image} alt="Urgent Vet" className={clsx(classes.appLogo, className)} />;
};

const useStyles = makeStyles(() => ({
  appLogo: {
    margin: 'auto 0',
    width: '158px',
  },
}));
