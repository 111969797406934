import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import {
  IMarketCalendarLocation,
  IMarketCalendarShiftRequest,
  IShift,
} from '../../models/schedule';
import { Typography, Checkbox, Link, alpha } from '@mui/material';
import clsx from 'clsx';
import { Coin } from '../icons/coin';
import { RSSAddEditModal } from './rss-add-modal';
import { CalendarEventMenu } from './calendar-event-menu';
import { ICalendarMenuOption } from '.';
import { RSSMarketRepeaterContext } from '../../context/rss-market-repeater';

interface IRSSCheckbox {
  checkedRequests: IMarketCalendarShiftRequest[];
  rejectedRequests: any[];
  request: IMarketCalendarShiftRequest;
  handleApprove: (request: IMarketCalendarShiftRequest) => void;
  handleReject: (request: IMarketCalendarShiftRequest) => void;
  handleReinstate: ((request: IMarketCalendarShiftRequest) => void) | undefined;
  reinstatedRequests?: IMarketCalendarShiftRequest[];
  onSchedule: IShift[];
  handleModalOpen: () => Promise<void>;
  fetchSchedule: (() => Promise<void>) | undefined;
  location: IMarketCalendarLocation;
  thisDaysIncentive: number | null;
}

export const RSSCheckbox: FC<IRSSCheckbox> = ({
  checkedRequests,
  rejectedRequests,
  request,
  handleApprove: toggleApprove,
  handleReject,
  handleReinstate,
  onSchedule,
  handleModalOpen,
  fetchSchedule,
  location,
  thisDaysIncentive,
  reinstatedRequests,
}) => {
  const getStatusLabel = useCallback(
    (request: IMarketCalendarShiftRequest) => {
      if (
        rejectedRequests
          ?.map?.((req: any) => req.shiftRequestId)
          ?.find(
            (reqId: number | string) =>
              reqId === (request as IMarketCalendarShiftRequest)?.shiftRequestId
          )
      ) {
        return 'Rejected';
      }
      if (
        checkedRequests?.find(
          (req: IMarketCalendarShiftRequest) =>
            req.shiftRequestId === (request as IMarketCalendarShiftRequest)?.shiftRequestId
        )
      ) {
        return 'Not Saved';
      }
      if (
        reinstatedRequests?.find(
          (req: IMarketCalendarShiftRequest) =>
            req.shiftRequestId === (request as IMarketCalendarShiftRequest)?.shiftRequestId
        )
      ) {
        return 'Reinstate';
      }
      return request.status || '';
    },
    [checkedRequests, rejectedRequests, reinstatedRequests]
  );

  const determineClassFromStatus = (request: IMarketCalendarShiftRequest) => {
    if (request.hasBeenReinstated && request.status === 'Pending') {
      return classes.inactiveReinstated;
    }

    if (request.status === 'Pending') {
      return classes.inactivePending;
    } else if (request.status === 'Approved') {
      return classes.inactiveApproved;
    } else if (request.status === 'Rejected') {
      return classes.inactiveRejected;
    } else if (request.status === 'Canceled') {
      if (request?.cancelationSource === 'RSS') {
        return classes.inactiveCanceledByRSS;
      }
      return classes.inactiveCanceledByDVM;
    }
  };

  const currentStatus = useMemo(() => {
    return getStatusLabel(request);
  }, [getStatusLabel, request]);

  const classes = useStyles({ reinstated: request?.hasBeenReinstated });
  const selectedRelief =
    (!!checkedRequests &&
      checkedRequests?.find(
        (req: { shiftRequestId: number }) => req.shiftRequestId === request.shiftRequestId
      )) ??
    false;

  const { allPayloads } = useContext(RSSMarketRepeaterContext);
  const isDisabled = useCallback(() => {
    const sameLocationSameDate =
      !!checkedRequests &&
      !!checkedRequests?.find(
        (req: IMarketCalendarShiftRequest) =>
          req.locationId === request.locationId &&
          new Date(req.shiftDate).getTime() === new Date(request.shiftDate).getTime()
      );
    const sameDVMsameDate =
      !!checkedRequests &&
      checkedRequests?.find(
        (req: IMarketCalendarShiftRequest) =>
          req.dvmId === request.dvmId &&
          new Date(req.shiftDate).getTime() === new Date(request.shiftDate).getTime()
      );

    const sameDVMsameDateScheduled = onSchedule
      .map(sched => {
        return { dvmId: sched.dvmId, shiftDate: new Date(sched.shiftDate).getTime() };
      })
      .some(
        schedule =>
          schedule.dvmId === request.dvmId &&
          new Date(schedule.shiftDate).getTime() === new Date(request.shiftDate).getTime()
      );

    const selectedDVMRequest = allPayloads?.shiftRequestApproval?.requests?.find(
      (dvm: any) =>
        // same dvm
        dvm.dvmId === request.dvmId &&
        dvm.date === request.shiftDate &&
        dvm.locationId === request.locationId
    );

    // These are the 'rules' that govern if a checkbox is disabled or is clickable
    let shouldBeDisabled = false;

    const isSelectedAcrossMarkets = allPayloads?.shiftRequestApproval?.requests?.filter(
      (req: any) => req.dvmId === request.dvmId
    );

    const DVMIsAlreadySelectedOnSameDate = isSelectedAcrossMarkets?.some(
      selected => selected.date === request.shiftDate
    );

    if (sameLocationSameDate || sameDVMsameDate || sameDVMsameDateScheduled) {
      // If you are in the same location at the same time as the selected DVM, you should be disabled
      // if you are the same DVM as the selected DVM on the same day, you should be disabled
      shouldBeDisabled = true;
    }

    if (DVMIsAlreadySelectedOnSameDate) {
      shouldBeDisabled = true;
    }

    if (!!selectedDVMRequest) {
      // if you are the selectedDVM themselves, you should NOT be disabled
      shouldBeDisabled = false;
    }

    return shouldBeDisabled;
  }, [allPayloads, checkedRequests, onSchedule, request]);

  const isChecked = !!selectedRelief;

  const selectedReliefPendingReinstatement =
    (!!reinstatedRequests &&
      reinstatedRequests?.find(
        (req: { shiftRequestId: number }) => req.shiftRequestId === request.shiftRequestId
      )) ??
    false;

  const isPendingReinstatement = !!selectedReliefPendingReinstatement;

  const [isRSSAddModalOpen, setIsRSSAddModalOpen] = useState(false);
  const [RSSModalSelectedShiftRequest, setRSSModalSelectedShiftRequest] =
    useState<IMarketCalendarShiftRequest | null>(null);

  useEffect(() => {
    if (RSSModalSelectedShiftRequest) {
      setIsRSSAddModalOpen(true);
    }
  }, [RSSModalSelectedShiftRequest]);

  const menuItems: ICalendarMenuOption[] = [
    {
      action: () => {
        toggleApprove(request);
      },
      label: 'Approve',
      disabled: currentStatus === 'Rejected',
    },
    {
      action: () => {
        if (currentStatus === 'Not Saved') toggleApprove(request);
        handleReject(request);
      },
      label: currentStatus === 'Rejected' ? 'Unreject' : 'Reject',
      disabled: false,
    },
  ];

  const reinstateMenuItems: ICalendarMenuOption[] = [
    {
      action: () => {
        handleReinstate?.(request || {});
      },
      label: isPendingReinstatement ? 'Keep Pending' : 'Reinstate to Pending',
      disabled: false,
    },
  ];

  const getMenuItems = () => {
    if (request.status === 'Rejected' || request.status === 'Canceled') {
      return reinstateMenuItems;
    }
    return menuItems;
  };

  return (
    <>
      <div
        key={request.shiftRequestId}
        className={clsx(
          classes.bid,
          isChecked && classes.checkedBid,
          determineClassFromStatus(request)
        )}
      >
        <div>
          <Typography onClick={handleModalOpen} className={classes.bidName}>
            {request.dvmName}
          </Typography>
          <Link
            className={classes.link}
            color={'inherit'}
            onClick={() => {
              setRSSModalSelectedShiftRequest(request);
            }}
          >
            <Typography>
              {`$${request.totalAmount}`}
              {request.hasIncentive ? <Coin maxHeight={20} /> : null}
            </Typography>
          </Link>
        </div>
        <div className={classes.eventMenuContainer}>
          <CalendarEventMenu menuItems={getMenuItems()} />
        </div>
        {!(request.status === 'Rejected' || request.status === 'Canceled') && (
          <Checkbox
            disabled={isDisabled()}
            classes={{
              root: isChecked ? classes.checkbox : '',
            }}
            checked={isChecked}
            onChange={() => {
              toggleApprove(request);
            }}
            className={classes.checkboxArea}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )}

        {(request.status === 'Rejected' || request.status === 'Canceled') && (
          <Checkbox
            classes={{
              root: isPendingReinstatement ? classes.pendingCheckbox : '',
            }}
            checked={isPendingReinstatement}
            onChange={() => {
              handleReinstate?.(request || {});
            }}
            className={classes.checkboxArea}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )}

        <div className={classes.statusLabelContainer}>
          <Typography className={classes.statusLabel} variant="caption">
            {currentStatus}
          </Typography>
        </div>
      </div>
      <RSSAddEditModal
        isOpen={isRSSAddModalOpen}
        onClose={() => {
          setIsRSSAddModalOpen(false);
          setRSSModalSelectedShiftRequest(null);
        }}
        fetchSchedule={() => fetchSchedule?.()}
        date={RSSModalSelectedShiftRequest?.shiftDate as Date}
        scheduledShift={RSSModalSelectedShiftRequest ?? undefined}
        location={location as IMarketCalendarLocation}
        thisDaysIncentive={thisDaysIncentive}
      />
    </>
  );
};
const useStyles = makeStyles<Theme, { reinstated: boolean }>((theme: Theme) => ({
  bid: {
    width: '80%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    minHeight: '7rem',
    borderTop: `3px solid ${theme.palette.grey[300]}`,
    borderRight: `3px solid ${theme.palette.grey[300]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(1, 0.25, 0),
    alignSelf: 'flex-start',
    '&:last-of-type': {
      marginBottom: theme.spacing(1),
    },
    '&.reinstated': {
      borderTop: `3px solid ${theme.palette.primary.main}`,
      borderRight: `3px solid ${theme.palette.primary.main} !important`,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    position: 'relative',
  },
  checkedBid: {
    backgroundColor: ({ reinstated }) =>
      reinstated ? theme.palette.primary.light : theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  pendingCheckbox: { '&.Mui-checked': { color: theme.palette.secondary.main } },
  checkbox: { '&.Mui-checked': { color: theme.palette.common.white } },
  checkboxArea: {
    marginTop: '1.5rem',
  },
  bidName: {
    textDecoration: 'underline',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eventMenuContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  statusLabelContainer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    textAlign: 'right',
    marginRight: '.5rem',
  },
  statusLabel: {
    backgroundColor: alpha(theme.palette.common.white, 0),
  },
  //Reinstated
  inactiveReinstated: {
    backgroundColor: '#E1F7FF',
    borderTop: `3px solid ${theme.palette.secondary.light}`,
    borderRight: `3px solid ${theme.palette.secondary.light} !important`,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
  },
  //Rejected
  inactiveRejected: {
    backgroundColor: theme.palette.grey[200],
    borderTop: `3px solid ${theme.palette.grey[500]}`,
    borderRight: `3px solid ${theme.palette.grey[500]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[500]}`,
    borderLeft: `3px solid ${theme.palette.grey[500]}`,
  },
  //Canceled by RSS
  inactiveCanceledByRSS: {
    backgroundColor: theme.palette.grey[200],
    borderTop: `3px solid ${theme.palette.grey[500]}`,
    borderRight: `3px solid ${theme.palette.grey[500]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[500]}`,
    borderLeft: `3px solid ${theme.palette.grey[500]}`,
  },
  //Canceled by DVM
  inactiveCanceledByDVM: {
    backgroundColor: theme.palette.common.white,
    borderTop: `3px solid ${theme.palette.error.dark}`,
    borderRight: `3px solid ${theme.palette.error.dark} !important`,
    borderBottom: `3px solid ${theme.palette.error.dark}`,
    borderLeft: `3px solid ${theme.palette.error.dark}`,
  },
  //Pending/ Not Selected
  inactivePending: {
    borderTop: `3px solid ${theme.palette.grey[200]}`,
    borderRight: `3px solid ${theme.palette.grey[200]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[200]}`,
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
  },
  //Approved
  inactiveApproved: {
    color: theme.palette.common.white,
    backgroundColor: '#4c5076',
    justifyContent: 'space-around',
  },
}));
