import { authFetch } from '.';
import { IAvailableShift, IMapLocation } from '../models/maps';
import { logError } from '../services';

const baseUrl = '/maps';

export const getMapLocations = async (
  startDate: Date | null,
  endDate: Date | null
): Promise<IMapLocation[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/availableLocationShifts`, {
      startDate,
      endDate,
    });
    return data;
  } catch (error) {
    logError(error, 'getMapLocations failed');
    return Promise.reject(error);
  }
};

export const getAvailableDVMShifts = async (
  locationId: number,
  startDate: Date,
  endDate: Date
): Promise<IAvailableShift[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/AvailableDVMShifts`, {
      locationId,
      startDate,
      endDate,
    });
    return data;
  } catch (error) {
    logError(error, 'getAvailableDVMShifts failed');
    return Promise.reject(error);
  }
};
