import { FC } from 'react';
import { Page } from '../../components';
import { Grid, Link } from '@mui/material';

export const DvmDisabled: FC = () => {
  return (
    <Page title={'Account Inactive'}>
      <Grid container spacing={3}>
        <Grid item>
          Login Error. Please contact{' '}
          <Link href="mailto:relief@vetcher.com">relief@vetcher.com</Link> for assistance and
          someone will be in touch with you shortly.
        </Grid>
      </Grid>
    </Page>
  );
};
