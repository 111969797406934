import { FC, useContext, useEffect, useState } from 'react';
import { Loader, Page } from '../../components';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMsal } from '@azure/msal-react';
import { setTimeout } from 'timers';
import { verifyPendingEmail } from '../../fetch';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/user';

export const VerifyEmail: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(true);

  const { instance } = useMsal();
  const [errorMessage, setErrorMessage] = useState('');

  const { isDVM } = useContext(UserContext);
  const verifyEmail = async (dvmId: string, email: string, code: string) => {
    try {
      setIsVerifyingEmail(true);

      await verifyPendingEmail(dvmId, email, code);
      enqueueSnackbar(`Email successfully updated!`, {
        variant: 'success',
      });
      if (isDVM) {
        setTimeout(() => {
          instance.logoutRedirect();
        }, 5000);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      console.log('Error verifying email: ', errorMessage ?? error.message ?? error);
      setErrorMessage(errorMessage ?? 'Email was not updated.');
      enqueueSnackbar(errorMessage ?? `Email was not updated.`, {
        variant: 'error',
      });
    } finally {
      setIsVerifyingEmail(false);
    }
  };

  const email = queryParameters.get('email')?.toString();
  const code = queryParameters.get('code')?.toString();
  const dvmId = queryParameters.get('dvmId')?.toString();
  useEffect(() => {
    if (email && code && dvmId) {
      verifyEmail(dvmId ?? '', email ?? '', code ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, code, dvmId]);

  return (
    <Page title={'Verify Email'}>
      <Grid container spacing={3}>
        {isVerifyingEmail && <Loader position="centered" type="fullscreen" />}
        <Grid item>{'Please wait while we verify your email.' || errorMessage}</Grid>
      </Grid>
    </Page>
  );
};
