import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IHoliday } from '../../models/holidays';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { createHoliday, updateHoliday } from '../../fetch/holidays';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface IHolidayModal {
  open: boolean;
  onClose: () => void;
  currentHoliday?: IHoliday;
  fetchHolidays: () => {};
}

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  date: Yup.string().required('Required'),
});

export const HolidayModal: FC<IHolidayModal> = ({
  open,
  onClose,
  currentHoliday,
  fetchHolidays,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width: 960px)');
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: currentHoliday?.name ?? '',
          date: currentHoliday?.date ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            holiday: {
              holidayId: currentHoliday?.holidayId ?? 0,
              name: values.name,
              date: values.date,
            },
          };
          try {
            if (!!currentHoliday) {
              await updateHoliday(data);
              enqueueSnackbar(`Holiday updated!`, {
                variant: 'success',
              });
            }
            if (!currentHoliday) {
              await createHoliday(data);
              enqueueSnackbar(`Holiday created!`, {
                variant: 'success',
              });
            }
            actions.resetForm();
            fetchHolidays();
            onClose();
          } catch (error: any) {
            if (!!currentHoliday) {
              const errorMessage = error?.response?.data?.Detail;
              enqueueSnackbar(errorMessage || `Holiday was not updated.`, {
                variant: 'error',
              });
            }
            if (!currentHoliday) {
              const errorMessage = error?.response?.data?.Detail;
              enqueueSnackbar(errorMessage || `Holiday was not created`, {
                variant: 'success',
              });
            }
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                onClose();
              }}
              maxWidth="md"
            >
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <div>
                    <Typography variant="h5">
                      {!!currentHoliday ? `Edit ${currentHoliday?.name}` : `Add New Holiday`}
                    </Typography>
                    <div className={classes.content}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="standard"
                            autoComplete="nope"
                            label="Holiday Name"
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            size="small"
                            required
                            onChange={e => setFieldValue('name', e.target.value)}
                            error={touched.name && errors && errors.name ? true : false}
                            helperText={touched.name && errors && errors.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            {!isMobile ? (
                              <DesktopDatePicker
                                label={'Holiday Date'}
                                inputFormat="MM/dd/yyyy"
                                value={values.date}
                                onChange={(e: any) => {
                                  setFieldValue('date', e);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    required
                                    size="small"
                                    variant="standard"
                                    {...params}
                                    error={touched.date && errors && errors.date ? true : false}
                                    helperText={touched.date && errors && errors.date}
                                  />
                                )}
                              />
                            ) : (
                              <MobileDatePicker
                                label={'Holiday Date'}
                                inputFormat="MM/dd/yyyy"
                                value={values.date}
                                onChange={(e: any) => {
                                  setFieldValue('date', e);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    required
                                    variant="standard"
                                    size="small"
                                    {...params}
                                    error={touched.date && errors && errors.date ? true : false}
                                    helperText={touched.date && errors && errors.date}
                                  />
                                )}
                              />
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Box marginTop="1rem">
                    <Button
                      className={classes.button}
                      disabled={!dirty || isSubmitting || !isValid}
                      type="submit"
                      startIcon={<Edit />}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting}
                      startIcon={<Close />}
                      onClick={() => {
                        //@ts-ignore
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm(
                            'You have unsaved changes, are you sure you want to exit?'
                          );
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          resetForm();
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
