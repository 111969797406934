import { Grid, Icon } from '@mui/material';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC } from 'react';

export interface IIconHeadingProps {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  variant: TypographyTypeMap['props']['variant'];
  style?: React.CSSProperties;
}

export const IconHeading: FC<IIconHeadingProps> = ({ title, variant, icon, style }) => {
  return (
    <>
      <Grid container direction="row" spacing="6" style={style}>
        <Grid item>
          <Icon component={icon} />
        </Grid>
        <Grid item>
          <Typography variant={variant}>{title}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
