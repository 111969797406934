import { Camera } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

interface IMultiImageUpload {
  handleFileChange: (val: any[], file?: File[]) => void;
  files: string[];
  disabled?: boolean;
  isRequired?: boolean;
  showSource?: boolean;
  handleUploadClick?: () => void;
  multi?: boolean;
  imageKey?: string;
  fileName?: string;
}

export const MultiFileUpload: FC<IMultiImageUpload> = ({
  handleUploadClick,
  handleFileChange,
  files,
  disabled = false,
  isRequired = false,
  showSource = true,
  multi = true,
  imageKey,
  fileName,
}) => {
  const classes = useStyles();

  const createBase64Image = async (file: Blob) => {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCapture = async (target: EventTarget & HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const files = [];
        const base64Images: Array<{
          title: string;
          urlPath: string;
          contents: string;
          isUploaded: boolean;
          whenTaken: Date | string;
          servicePhotoId: string;
          file: File;
        }> = [];
        // Get fileList Object, convert to an array and get the values before they disappear
        // https://stackoverflow.com/questions/25333488/why-isnt-the-filelist-object-an-array
        const allFiles: File[] = Array.from(target.files);
        const fileLength = target.files.length;
        for (let i = 0; i < fileLength; i++) {
          const file = allFiles[i];
          const base64 = (await createBase64Image(file)) as string;
          const newUrl = URL.createObjectURL(file);

          files.push(file);
          base64Images.push({
            title: file.name,
            urlPath: newUrl,
            contents: base64.split(',')[1],
            isUploaded: false,
            whenTaken: '',
            servicePhotoId: Math.floor(1000000 + Math.random() * 9000000).toString(),
            file: file,
          });
        }
        handleFileChange(base64Images, files);
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.buttonsContainer}>
        <input
          disabled={disabled}
          className={classes.input}
          id={`icon-button-file-${imageKey}`}
          type="file"
          capture="environment"
          multiple={multi}
          onChange={e => {
            handleCapture(e.target);
            // reset the input value so you can re-upload the same photo if you want to
            e.target.value = '';
          }}
        />
        <Box>
          <label htmlFor={`icon-button-file-${imageKey}`}>
            <Button
              onClick={handleUploadClick}
              disabled={disabled}
              // className={classes.button}
              // style={{ margin: 0 }}
              startIcon={<Camera />}
              size={'large'}
              variant={'contained'}
              component={'span'}
            >
              {fileName || <span>UPLOAD FILE{multi ? <span>(S)</span> : null}</span>}
            </Button>
          </label>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  heading: {
    display: 'block',
    marginBottom: theme.spacing(0.75),
  },
  buttonsContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  imgBox: {
    maxWidth: '100%',
    flexDirection: 'column',
  },
  img: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  removeButton: {
    color: theme.palette.error.main,
  },
  imageHelpText: {
    color: theme.palette.error.main,
  },
  button: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));
