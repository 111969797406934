/**
 *
 * @param path string the key of the object you are trying to resolve the value of
 * @param obj the object that gets passed in to check against
 * @returns the value of the object at the given path, i.e. path='test.test.value' obj={{ test: { test: { value: 'TEST' } }  }}
 */
export const resolveObjectField = (path: string, obj: any) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

export const truncate = (input: string, max: number, append?: JSX.Element) => {
  if (input.length > max) {
    return (
      <>
        {`${input.substring(0, max)}...`}
        {append}
      </>
    );
  }
  return input;
};
