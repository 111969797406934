import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { IColumn } from '../../models/util';
import { Table } from '../../components/table/Table';
import { getDvmShiftRequestHistory } from '../../fetch';
import { IDVM, IShiftRequestHistory } from '../../models';
import { useSnackbar } from 'notistack';
import { formatDate, formatDateWithDay, formatMoney } from '../../helpers';
import { format } from 'date-fns';
import { DateRangePicker } from '../../components/input/date-range';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IDateRange } from '../../models/date';
import { Check } from '@mui/icons-material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import { Coin } from '../../components/icons/coin';

export interface IDvmRequestHsitory {
  dvm: IDVM | null;
}
export const DvmRequestHistory: FC<IDvmRequestHsitory> = ({ dvm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingShifts, setUpcomingShifts] = useState<IShiftRequestHistory[]>([]);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const classes = useStyles({ isMobile });

  const fromDate = new Date();
  fromDate.setDate(new Date().getDate() - 29);

  const toDate = new Date();
  toDate.setDate(new Date().getDate());

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: null,
    endDate: null,
    key: 'selection',
    inputValue: '',
  });

  const fetchUpcomingShifts = async () => {
    setIsLoading(true);
    try {
      const res = await getDvmShiftRequestHistory(dvm?.dvmId ?? 0, {
        dateFrom: formatDate(selectedDateRange.startDate) ?? '',
        dateTo: formatDate(selectedDateRange.endDate) ?? '',
      });
      setUpcomingShifts(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading shift request history, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // set default date range on load
  useEffect(() => {
    if (!selectedDateRange.startDate && !selectedDateRange.endDate) {
      const defaultDateRange = format(fromDate, 'M/d/yyyy') + ' - ' + format(toDate, 'M/d/yyyy');

      setSelectedDateRange({
        startDate: fromDate,
        endDate: toDate,
        key: defaultDateRange,
        inputValue: defaultDateRange,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      fetchUpcomingShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Date',
        accessor: 'shiftDate',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestHistory } };
        }) => {
          return <span>{`${formatDateWithDay(original.shiftDate)}`}</span>;
        },
      },
      {
        Header: 'Location',
        accessor: 'locationName',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestHistory } };
        }) => {
          return (
            <>
              <span
                className={clsx(
                  classes.mobileIcon,
                  original.status === 'Approved' ? classes.approvedIcon : '',
                  original.status === 'Rejected' ? classes.rejectedIcon : '',
                  original.status === 'Pending' ? classes.pendingIcon : ''
                )}
              >
                {original.status === 'Approved' ? <Check /> : ''}
                {original.status === 'Pending' ? <QuestionMarkIcon /> : ''}
                {original.status === 'Rejected' ? <ClearIcon /> : ''}
              </span>
              <span className={classes.statusText}>{original.status}</span>
            </>
          );
        },
      },
      {
        Header: 'Rate',
        accessor: 'amount',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestHistory } };
        }) => {
          return <span>{formatMoney(original.amount, 0)}</span>;
        },
      },
      {
        Header: 'Incentive',
        accessor: 'incentive',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestHistory } };
        }) => {
          return (
            <span>
              {original?.incentive ? <Coin maxHeight={20} /> : null}
              {` `}
              {original?.incentive ? formatMoney(original.incentive, 0) : null}
            </span>
          );
        },
      },
      {
        Header: 'Total Payment',
        accessor: 'totalPayment',
        columnAlignment: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IShiftRequestHistory } };
        }) => {
          return (
            <span className={classes.noWrap}>
              {original?.incentive && isMobile ? <Coin maxHeight={20} /> : null}{' '}
              {formatMoney(original.totalPayment, 0)}
            </span>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Grid container spacing={6}>
      <Grid item container xs={12}>
        <Grid item xs={12} lg={3}>
          <DateRangePicker
            id="filterDateRange"
            label="Date Range"
            value={selectedDateRange}
            isDisabled={isLoading}
            onChange={value => {
              setSelectedDateRange(value);
            }}
            fullWidth
            inputSize="small"
          />
        </Grid>
      </Grid>
      <Grid className={classes.centered} item container xs={12}>
        <Grid item xs={12} className={classes.centered}>
          <div style={isMobile ? { width: '100%' } : undefined}>
            {!isLoading && upcomingShifts.length < 1 ? (
              <Typography className={classes.noShiftMessage} align="center">
                No shift request history found.
              </Typography>
            ) : (
              <Table
                containerClasses={classes.tableOverflow}
                headerClasses={classes.tableCell}
                cellClasses={classes.tableCell}
                columns={columns
                  .filter(col =>
                    isMobile
                      ? col.accessor === 'shiftDate' ||
                        col.accessor === 'locationName' ||
                        col.accessor === 'totalPayment' ||
                        col.accessor === 'status'
                      : true
                  )
                  .map(col =>
                    col.Header === 'Total Payment' ? { ...col, Header: 'Payment' } : col
                  )}
                data={upcomingShifts}
                hidePagination
                isLoading={isLoading}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  noShiftMessage: {
    marginTop: '1em',
  },
  statusText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileIcon: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '& svg': {
      width: '.75em',
      height: '.75em',
    },
  },
  approvedIcon: {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
  rejectedIcon: {
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  pendingIcon: {
    '& svg': {
      color: theme.palette.common.black,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableOverflow: {
    overflowY: 'auto',
  },
  tableCell: {
    padding: ({ isMobile }) => (isMobile ? '6px' : '6px 16px'),
  },
}));
