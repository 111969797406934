import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

/** @see https://mui.com/customization/breakpoints/ */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // retains the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
  }
  // // https://mui.com/material-ui/customization/typography/#variants
  interface TypographyVariants {
    labels: CSSProperties;
  }

  // // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    labels?: CSSProperties;
  }

  interface Palette {
    warmNeutral: Palette['primary'];
    coolNeutral: Palette['primary'];
  }
  interface PaletteOptions {
    warmNeutral: PaletteOptions['primary'];
    coolNeutral: PaletteOptions['primary'];
  }
}

// // Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    labels: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#EA1B2D',
      contrastText: '#fff',
    },
    secondary: {
      main: '#39839D',
      contrastText: '#fff',
    },
    warmNeutral: {
      main: '#EEE4DC',
    },
    coolNeutral: {
      main: '#EEF7FA',
    },
    success: {
      main: '#A0AD6A',
      contrastText: '#fff',
    },
    divider: grey[300],
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 767,
      tablet: 1500,
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 14,
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    h1: {
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h2: {
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h3: {
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h4: {
      color: '#EA1B2D',
      lineHeight: 1.6,
      fontWeight: 700,
    },
    h5: {
      color: '#EA1B2D',
      lineHeight: 1.6,
      fontWeight: 700,
    },
    h6: {
      color: '#EA1B2D',
      fontSize: 14,
      lineHeight: 1.6,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    body1: {
      lineHeight: 1.6,
    },
    body2: {
      lineHeight: 1.6,
    },
    labels: {
      color: '#002855', // primary,
      fontWeight: 'bold',
    },
    subtitle1: {
      lineHeight: 1.6,
      fontWeight: 'bold',
    },
    subtitle2: {
      lineHeight: 1.6,
      fontWeight: 'normal',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: 1.2,
      borderRadius: '50%',
    },
    caption: {
      lineHeight: 1.6,
    },
    overline: {
      lineHeight: 1.6,
      textTransform: 'uppercase',
    },
  },
});

// Define Responsive Typography
// Mobile: Minor Second 1.067 w/ 18 base: https://type-scale.com/?size=18&scale=1.125
// Desktop: Custom (Major Second) - 1.125 w/ 16 base: https://type-scale.com/?size=18&scale=1.2
theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: 23.33,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 25.63,
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: 21.87,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 22.78,
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: 20.49,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 20.25,
  },
};
theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: 19.21,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 18,
  },
};
theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: 18,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 16,
  },
};
theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: 14,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 14,
  },
};
theme.typography.body2 = {
  ...theme.typography.body2,
  fontSize: 14,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 14,
  },
};
theme.typography.labels = {
  ...theme.typography.labels,
  fontSize: 16,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 16,
  },
};
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: 18,

  [theme.breakpoints.up('mobile')]: {
    fontSize: 20,
  },
};
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  fontSize: 18,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 20,
  },
};
theme.typography.button = {
  ...theme.typography.button,
  fontSize: 12,
};
theme.typography.caption = {
  ...theme.typography.caption,
  fontSize: 12,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 14,
  },
};
theme.typography.overline = {
  ...theme.typography.overline,
  fontSize: 12,
  [theme.breakpoints.up('mobile')]: {
    fontSize: 14,
  },
};

// Default Props and Component Overrides
theme.components = {
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: '1rem',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary', // most common, secondary action
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(.75, 2),
        borderRadius: '24px',
        fontSize: 12,
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
      fullWidth: true,
      margin: 'normal',
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: 'dense',
    },
    styleOverrides: {
      // @ts-ignore
      input: ({ ownerState }) => ({
        cursor:
          ownerState.readOnly === true || ownerState.disabled === true ? 'not-allowed' : 'unset',
      }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      color: 'primary',
      size: 'small',
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        ':hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
      },
      input: {
        lineHeight: 1.6,
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      color: 'primary',
      margin: 'dense',
    },
    styleOverrides: {
      shrink: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiFormLabel: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiFormControlLabel: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      autoComplete: 'nope',
      size: 'small',
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
      color: 'secondary',
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
      color: 'secondary',
    },
  },
  MuiDivider: {
    defaultProps: {
      variant: 'fullWidth',
    },
    styleOverrides: {
      fullWidth: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  },
};
