import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link as MLink } from 'react-router-dom';

interface ILink {
  className?: string;
  to: string;
  type?: 'primary' | 'white';
}

export const Link: FC<ILink> = ({ to, children, className, type = 'primary' }) => {
  const classes = useStyles();

  return (
    <MLink to={to} className={clsx(classes[type], className)}>
      {/* @ts-ignore */}
      {children}
    </MLink>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.palette.background.paper,
    textDecoration: 'none',
  },
  primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
