import { AppBar, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { Loader, Page } from '../../components';
import { IDVM } from '../../models';
import { useSnackbar } from 'notistack';
import { Prompt, Redirect, useParams } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getDvm } from '../../fetch/dvms';
import { UserContext } from '../../context';
import { DvmProfile } from './dvm-profile';
import { DvmSchedule } from './dvm-schedule';
import { AccountBox, Schedule } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HistoryIcon from '@mui/icons-material/History';
import { DvmPendingRequests } from './dvm-requests';
import { DvmRequestHistory } from './dvm-request-history';

enum DVMTabs {
  Profile,
  Schedule,
  Requests,
  History,
}

export const DvmDetails: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dvm, setDvm] = useState<IDVM | null>(null);

  const isMobile = useMediaQuery('(max-width: 960px)');

  const { enqueueSnackbar } = useSnackbar();
  const { dvmId }: { dvmId: string } = useParams();
  const { user, isDVM } = useContext(UserContext);
  const isEdit = true;
  const [selectedTab, setSelectedTab] = useState<DVMTabs>(DVMTabs.Profile);
  const [isProfilePageDirty, setIsProfilePageDirty] = useState(false);

  const dvmIsContractor = dvm?.type === 1;

  const classes = useStyles();

  const fetchDvm = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await getDvm(id);
      setDvm(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVM, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDVM = async () => {
    if (!dvmId && user?.validation?.value?.dvmInformation?.dvmId) {
      await fetchDvm(parseInt(user?.validation?.value?.dvmInformation?.dvmId));
    }

    if (dvmId && isEdit) {
      await fetchDvm(parseInt(dvmId));
    }
  };
  useEffect(() => {
    getDVM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningMessage = 'You have unsaved changes, are you sure you want to continue?';
  // Handles a tab change if the form state is dirty
  const handleTabChangeWithoutSave = (value: number) => {
    if (isProfilePageDirty) {
      const result = window.confirm(warningMessage);
      if (result) {
        setSelectedTab(value);
        if (DVMTabs.Profile !== value) setIsProfilePageDirty(false);
      } else {
        return;
      }
    } else {
      setSelectedTab(value);
      if (DVMTabs.Profile !== value) setIsProfilePageDirty(false);
    }
  };

  // They are a DVM but don't have their data setup correctly, or are trying to URL hack
  if ((isDVM && !dvmId && !user?.validation?.value?.dvmInformation?.dvmId) || (isDVM && dvmId)) {
    return <Redirect to={`not-found`} />;
  }

  if (isLoading) {
    return <Loader position="centered" type="fullscreen" />;
  }

  return (
    <Page
      paddingBottom={24}
      title={isEdit ? `${dvm?.firstName ?? ''} ${dvm?.lastName ?? ''}` : 'Add New DVMs'}
    >
      {/* Handles navigating away from the page */}
      <Prompt when={isProfilePageDirty} message={_ => warningMessage} />
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.tabBar}>
          <AppBar
            classes={{ root: classes.tabBarRoot }}
            position="static"
            color="default"
            component="div"
          >
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, value) => {
                handleTabChangeWithoutSave(value);
              }}
              variant={isMobile ? 'scrollable' : 'standard'}
            >
              <Tab
                icon={<AccountBox />}
                iconPosition={isMobile ? 'top' : 'start'}
                className={classes.profileTabs}
                id="profile"
                aria-controls="profile"
                label={'Profile'}
              />
              <Tab
                icon={<DateRangeIcon />}
                iconPosition={isMobile ? 'top' : 'start'}
                className={classes.profileTabs}
                id="schedule"
                aria-controls="schedule"
                label={'Schedule'}
              />
              {dvmIsContractor && [
                <Tab
                  icon={<Schedule />}
                  iconPosition={isMobile ? 'top' : 'start'}
                  className={classes.profileTabs}
                  id="requests"
                  aria-controls="requests"
                  label={'Requests'}
                  key={'request'}
                />,
                <Tab
                  icon={<HistoryIcon />}
                  iconPosition={isMobile ? 'top' : 'start'}
                  className={classes.profileTabs}
                  id="history"
                  aria-controls="history"
                  label={'History'}
                  key={'history'}
                />,
              ]}
            </Tabs>
          </AppBar>
        </Grid>

        {selectedTab === DVMTabs.Profile && dvm && (
          <Grid item xs={12}>
            <DvmProfile
              dvm={dvm}
              isLoading={isLoading}
              getDVM={getDVM}
              setIsDirty={setIsProfilePageDirty}
            />
          </Grid>
        )}
        {selectedTab === DVMTabs.Schedule && (
          <Grid item xs={12}>
            <DvmSchedule dvm={dvm} isContractor={dvmIsContractor} />
          </Grid>
        )}
        {selectedTab === DVMTabs.Requests && (
          <Grid item xs={12}>
            <DvmPendingRequests dvm={dvm} />
          </Grid>
        )}
        {selectedTab === DVMTabs.History && (
          <Grid item xs={12}>
            <DvmRequestHistory dvm={dvm} />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tabBar: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    width: '100vw',
    marginLeft: '-24px',
    paddingTop: '0!important',
  },
  tabBarRoot: {
    width: '100vw',
  },
  profileTabs: {
    fontSize: 14,
    borderRadius: 0,
  },
}));
