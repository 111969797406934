import { AppBar, Button, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { Loader, Page } from '../../components';
import { useSnackbar } from 'notistack';
import { Add, Gavel, Paid } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getLegalEntities } from '../../fetch/legal-entities';
import { ILegalEntityList } from '../../models/legal-entitites';
import { LegalEntityModal } from './add-legal-entity-modal';
import { LegalEntities } from './tabs/legal-entities';
import { W2DVMs } from './tabs/w2-dvms';
import { W2DVMsModal } from './add-w2-dvms-modal';
import { IW2PaymentReserves } from '../../models/w2-payment-reserve';
import { getDVMsWithW2Reserves } from '../../fetch/w2-payment-reserve';
import { sortable } from '../../models/util';

enum AdminEntitiesTabs {
  LegalEntities,
  W2Witholdings,
}

export const EntityManagement: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [legalEntities, setLegalEntities] = useState<ILegalEntityList[]>([]);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [isLegalEntityModalOpen, setIsModalOpen] = useState(false);
  const [isW2DVMModalOpen, setIsW2DVMModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentLegalEntityId, setCurrentLegalEntityId] = useState<number | string>('');
  const [currentDVMId, setCurrentDVMId] = useState<number | string>('');

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<AdminEntitiesTabs>(
    AdminEntitiesTabs.LegalEntities
  );

  const fetchLegalEntities = async () => {
    try {
      setIsLoading(true);
      const res = await getLegalEntities();
      setLegalEntities(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading legal entities, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [isLoadingW2Reserves, setIsLoadingW2Reserves] = useState<boolean>(false);
  const [currentW2PaymentReserves, setCurrentW2PaymentReserves] = useState<IW2PaymentReserves[]>(
    []
  );

  // filters
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);

  // sorting
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [sortDirection, setSortDirection] = useState<{
    Name?: sortable;
    Reserve?: sortable;
    EffectiveDate?: sortable;
  }>({
    Name: 'Asc',
  });

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const fetchDVMsWithW2Reserves = async () => {
    try {
      setIsLoadingW2Reserves(true);
      const res = await getDVMsWithW2Reserves({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],

        name: searchValue,
      });
      setCurrentW2PaymentReserves(res.records);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVMs, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoadingW2Reserves(false);
    }
  };

  useEffect(() => {
    if (selectedTab === AdminEntitiesTabs.LegalEntities) fetchLegalEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === AdminEntitiesTabs.W2Witholdings) fetchDVMsWithW2Reserves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedSort, sortDirection, searchValue]);

  const handleAdd = () => {
    setIsEdit(false);
    setIsModalOpen(true);
  };

  const handleAddW2DVM = () => {
    setIsEdit(false);
    setIsW2DVMModalOpen(true);
  };

  const handleLegalEntitiesEdit = (legalEntityId: number | string) => {
    setIsEdit(true);
    setCurrentLegalEntityId(legalEntityId);
    setIsModalOpen(true);
  };

  const handleLegalEntityModalClose = () => {
    setCurrentLegalEntityId('');
    setIsModalOpen(false);
  };

  const handleW2DVMEdit = (dvmId: number | string) => {
    setIsEdit(true);
    setCurrentDVMId(dvmId);
    setIsW2DVMModalOpen(true);
  };

  const handleW2DVMlClose = () => {
    setCurrentDVMId('');
    setIsW2DVMModalOpen(false);
  };

  const handleTabChangeWithoutSave = (value: number) => {
    setSelectedTab(value);
  };

  return (
    <Page
      title={'Entity Management'}
      paddingBottom={24}
      additionalHeaderContent={
        <>
          {selectedTab === AdminEntitiesTabs.LegalEntities && (
            <Button
              startIcon={<Add />}
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() => handleAdd()}
            >
              New Legal Entity
            </Button>
          )}
          {selectedTab === AdminEntitiesTabs.W2Witholdings && (
            <Button
              startIcon={<Add />}
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() => handleAddW2DVM()}
            >
              New W2 DVM
            </Button>
          )}
        </>
      }
    >
      {isLoading && <Loader type="fullscreen" position="centered" title="Loading..." />}
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.tabBar}>
          <AppBar
            classes={{ root: classes.tabBarRoot }}
            position="static"
            color="default"
            component="div"
          >
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, value) => {
                handleTabChangeWithoutSave(value);
              }}
              variant={isMobile ? 'scrollable' : 'standard'}
            >
              <Tab
                icon={<Gavel />}
                iconPosition={isMobile ? 'top' : 'start'}
                className={classes.profileTabs}
                id="legalEntity"
                aria-controls="legalEntity"
                label={'Legal Entities'}
              />
              <Tab
                icon={<Paid />}
                iconPosition={isMobile ? 'top' : 'start'}
                className={classes.profileTabs}
                id="w2DVMs"
                aria-controls="w2DVMs"
                label={'W2 DVMs'}
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          {selectedTab === AdminEntitiesTabs.LegalEntities && (
            <LegalEntities
              handleEdit={handleLegalEntitiesEdit}
              legalEntities={legalEntities}
              setLegalEntities={setLegalEntities}
              isLoading={isLoading}
            />
          )}
          {selectedTab === AdminEntitiesTabs.W2Witholdings && (
            <W2DVMs
              handleEdit={handleW2DVMEdit}
              dvmsWithW2Reserves={currentW2PaymentReserves}
              setDvmsWithW2Reserves={setCurrentW2PaymentReserves}
              isLoading={isLoadingW2Reserves}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              hasAppliedFilters={hasAppliedFilters}
              setHasAppliedFilters={setHasAppliedFilters}
              handleClickColumn={handleClickColumn}
              sortDirection={sortDirection}
              selectedSort={selectedSort}
              handleSearch={(val: string) => {
                setSearchValue(val);
              }}
            />
          )}
        </Grid>
      </Grid>
      <LegalEntityModal
        open={isLegalEntityModalOpen}
        onClose={handleLegalEntityModalClose}
        currentLegalEntityId={currentLegalEntityId}
        fetchLegalEntities={fetchLegalEntities}
        isEdit={isEdit}
      />
      <W2DVMsModal
        open={isW2DVMModalOpen}
        onClose={handleW2DVMlClose}
        currentDVMId={currentDVMId}
        fetchW2DVMs={fetchDVMsWithW2Reserves}
        isEdit={isEdit}
      />
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tabBar: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    width: '100vw',
    marginLeft: '-24px',
    paddingTop: '0!important',
    [theme.breakpoints.down('mobile')]: {
      width: '100vw',
    },
  },
  tabBarRoot: {
    width: '100vw',
  },
  profileTabs: {
    fontSize: 14,
    borderRadius: 0,
  },
}));
