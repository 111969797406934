import { FC, useContext, useEffect } from 'react';
import { Loader } from '../../components';
import { Box, Button, Typography } from '@mui/material';
import { IAvailableShift, IMapLocation } from '../../models/maps';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import { IDateRange } from '../../models/date';
import { UserContext } from '../../context';
import coinIncentiveIcon from '../../images/coin-incentive.png';

interface ILoadingShifts {
  isLoadingAvailableShifts: boolean;
}

const LoadingShifts: FC<ILoadingShifts> = ({ isLoadingAvailableShifts }) =>
  isLoadingAvailableShifts ? (
    <Loader position={'centered'} />
  ) : (
    <Typography>No Available Shifts</Typography>
  );

interface IShiftOverlay {
  isLoading: boolean;
  fetchMapLocations: () => Promise<void>;
  isLoadingAvailableShifts: boolean;
  availableShifts: IAvailableShift[];
  requestedShifts: IAvailableShift[];
  setRequestedShifts: React.Dispatch<React.SetStateAction<IAvailableShift[]>>;
  mapLocations: IMapLocation[] | null;
  selectedDateRange: IDateRange;
  setIsRequestShiftsOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  isOverlayCovering: boolean;
  setIsOverlayCovering: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLocationId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedLocationId: number | null;
  overlayContainerHeight: string;
}
export const ShiftOverlay: FC<IShiftOverlay> = ({
  isLoading,
  isLoadingAvailableShifts,
  availableShifts,
  requestedShifts,
  setRequestedShifts,
  mapLocations,
  isOverlayCovering,
  setIsOverlayCovering,
  setSelectedLocationId,
  selectedLocationId,
  overlayContainerHeight,
}) => {
  const classes = useStyles({ overlayContainerHeight: overlayContainerHeight });
  const { isDVM, isDVMDeactivated } = useContext(UserContext);

  useEffect(() => {
    if (selectedLocationId === null) setIsOverlayCovering(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId]);

  const isShiftSelected = (shift: IAvailableShift) => {
    return (
      requestedShifts
        .filter((reqShifts: IAvailableShift) => reqShifts.locationId === shift.locationId)
        .filter(
          (reqShifts: IAvailableShift) => reqShifts.shiftDateFormatted === shift.shiftDateFormatted
        ).length > 0
    );
  };

  const canUserBid = mapLocations?.find(
    location => location.locationId === selectedLocationId
  )?.authorizedToWork;
  return !isLoading && mapLocations ? (
    <div className={clsx(classes.overlay, isOverlayCovering ? classes.cover : null)}>
      {/* Shift request overlay */}
      <div className={classes.shiftContainer}>
        <Typography variant="h3">
          {mapLocations?.find(location => location.locationId === selectedLocationId)?.name}
        </Typography>
        {!isLoadingAvailableShifts && availableShifts.length > 0 ? (
          availableShifts.map((shift: IAvailableShift) => {
            const isSelected = isShiftSelected(shift);
            return (
              <div
                key={`${shift.locationId} ${shift.shiftDateFormatted}`}
                className={classes.shift}
              >
                <p
                  className={classes.date}
                >{`${shift.shiftDateFormatted} (${shift.shiftDateDayOfWeek})`}</p>
                <Box className={classes.requestContainer}>
                  {shift?.incentiveRate ? (
                    <img className={classes.coin} src={coinIncentiveIcon} alt={'incentive-coin'} />
                  ) : null}
                  <Button
                    disabled={!isDVM || !canUserBid || isDVMDeactivated}
                    variant={isSelected ? 'contained' : 'outlined'}
                    onClick={() =>
                      isSelected
                        ? setRequestedShifts(prev => {
                            return prev.filter(reqShift => {
                              const isCorrectShiftToDelete =
                                shift.locationId === reqShift.locationId &&
                                shift.shiftDateFormatted === reqShift.shiftDateFormatted;
                              if (isCorrectShiftToDelete) delete shift.amount;
                              return !isCorrectShiftToDelete;
                            });
                          })
                        : setRequestedShifts(prev => {
                            return [...prev, shift];
                          })
                    }
                  >
                    {shift.shiftTime}
                  </Button>
                </Box>
              </div>
            );
          })
        ) : (
          <LoadingShifts isLoadingAvailableShifts={isLoadingAvailableShifts} />
        )}
      </div>
    </div>
  ) : null;
};

const useStyles = makeStyles<Theme, { overlayContainerHeight: string }>((theme: Theme) => ({
  overlay: {
    backgroundColor: '#f6f6f6',
    position: 'absolute',
    height: 0,
    width: '100%',
    bottom: 0,
    left: 0,
    visibility: 'hidden',
    transition: '0.3s',
    overflow: 'hidden',
    overflowY: 'auto',
    borderTop: `5px solid ${theme.palette.secondary.main}`,
  },
  coin: {
    maxHeight: '28px',
    marginRight: theme.spacing(1),
  },
  requestContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cover: {
    visibility: 'visible',
    height: '60%',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  requestShiftsCover: {
    visibility: 'visible',
    height: ({ overlayContainerHeight }) => overlayContainerHeight,
    width: '100%',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.default,
  },
  shiftContainer: {
    display: 'flex',
    flexDirection: 'column',
    xOverflow: 'scroll',
    padding: '.5rem 1rem',
  },
  requestShifts: {
    width: '100%',
    borderRadius: 0,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    position: 'sticky',
    bottom: 0,
  },
  close: {
    // May need to update the :last-child if/when this button is removed so it leaves space for the Request Shifts (n) button.
    margin: '.5rem 1rem 3rem',
    alignSelf: 'flex-end',
  },
  date: { padding: '0 .25rem 0 0' },
  shift: { display: 'flex', justifyContent: 'space-between' },
}));
