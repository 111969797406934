export const handleError = (res: any) => {
  if (res && res.Detail) {
    return res.Detail;
  } else if (
    res &&
    res.Errors &&
    Object.values(res.Errors)[0] &&
    Object.values(Object.values(res.Errors)[0] as any)[0]
  ) {
    return `Error, ${Object.values(Object.values(res.Errors)[0] as any)[0]}`;
  } else return 'Something went wrong.';
};
