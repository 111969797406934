import { Add } from '@mui/icons-material';
import { Box, Button, Fade, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Loader, Modal } from '../../components';
import { createDvm } from '../../fetch';

interface IAddContractorModal {
  isOpen: boolean;
  onClose: () => void;
}
const Schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
});

export const AddContractorModal: FC<IAddContractorModal> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          const dvmId = await createDvm(values);
          enqueueSnackbar(`Contractor Created!`, {
            variant: 'success',
          });

          history.push(`/dvms/${dvmId}`);
        } catch (error: any) {
          const errorMessage = error?.response?.data?.Detail;
          enqueueSnackbar(
            errorMessage ||
              `Something went wrong, please make sure the user is not already in the system.`,
            {
              variant: 'error',
            }
          );
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        resetForm,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
        validateForm,
      }) => {
        return (
          <Modal
            titleContent={'Add Contractor'}
            open={isOpen}
            onClose={() => {
              resetForm();
              onClose();
            }}
            maxWidth="md"
          >
            <Fade in={isOpen}>
              <Grid container>
                <Grid item xs={12}>
                  <Form onSubmit={handleSubmit} autoComplete="none">
                    {isSubmitting && (
                      <Loader type="fullscreen" position="centered" title="Loading..." />
                    )}
                    <Grid container spacing={2} paddingBottom={'24px'}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          variant="standard"
                          autoComplete="nope"
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          onBlur={handleBlur}
                          size="small"
                          onChange={e => {
                            setFieldValue('firstName', e.target.value);
                          }}
                          error={touched.firstName && errors.firstName ? true : false}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          variant="standard"
                          autoComplete="nope"
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onBlur={handleBlur}
                          size="small"
                          onChange={e => {
                            setFieldValue('lastName', e.target.value);
                          }}
                          error={touched.lastName && errors.lastName ? true : false}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          variant="standard"
                          autoComplete="nope"
                          label="Email"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          size="small"
                          onChange={e => {
                            setFieldValue('email', e.target.value);
                          }}
                          error={touched.email && errors.email ? true : false}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <Box marginTop="1rem">
                          <Button
                            disabled={!dirty || isSubmitting || !isValid}
                            type="submit"
                            startIcon={<Add />}
                            variant="contained"
                            color="primary"
                          >
                            ADD
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};
