import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const config = require('../buildSettings.json');
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
    config: {
        connectionString: `${config.APPLICATIONINSIGHTS_CONNECTION_STRING}`,
        instrumentationKey: '',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();