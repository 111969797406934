import { Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { isDate } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table } from '../../components/table/Table';
import { ILocationShift } from '../../models';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { IconHeading } from '../../components/icon-heading';

interface ILocationShifts {
  shifts: ILocationShift[];
  isLoading: boolean;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  validateForm: any;
  values: any;
}
export const LocationShifts: FC<ILocationShifts> = ({
  shifts,
  isLoading,
  errors,
  setFieldValue,
  validateForm,
  values,
}) => {
  const [shiftsCopy, setShiftsCopy] = useState(shifts);

  const classes = useStyles();

  useEffect(() => {
    setShiftsCopy(shifts);
  }, [shifts, isLoading]);

  const addShift = () => {
    setShiftsCopy(prev => {
      const newShifts = [
        ...prev,
        {
          locationShiftId: 0,
          effectiveDate: null,
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
          holiday: '',
        },
      ];
      setFieldValue('locationShifts', newShifts);

      return newShifts;
    });
  };

  const updateShiftsData = (shift: ILocationShift, columnId: string, value: string, id: number) => {
    setShiftsCopy((old: ILocationShift[]) => {
      const newShifts = old.map(row => {
        if (row.locationShiftId === id) {
          return {
            ...shift,
            [columnId]: value,
          };
        }
        return row;
      });
      setFieldValue('locationShifts', newShifts);

      return newShifts;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        sort: false,
        Cell: (props: any) => {
          const isMobile = useMediaQuery('(max-width: 960px)');
          // We need to keep and update the state of the cell normally
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.effectiveDate ?? ''
          );
          const onChange = (value: any) => {
            setValue(value);
            updateShiftsData(
              props.row.original,
              props.column.id,
              value,
              props.row.original.locationShiftId
            );
          };

          return (
            <>
              {!isMobile ? (
                <>
                  <DesktopDatePicker
                    toolbarPlaceholder={'Effective Date'}
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={onChange}
                    disabled
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        required={true}
                        size="small"
                        variant="standard"
                        {...params}
                        error={
                          !isDate(new Date(props?.cell?.row?.original?.effectiveDate)) ||
                          !props?.cell?.row?.original?.effectiveDate
                        }
                      />
                    )}
                  />
                  <Typography variant="body2">
                    {!isDate(new Date(props?.cell?.row?.original?.effectiveDate))
                      ? 'Invalid Date'
                      : ''}
                  </Typography>
                </>
              ) : (
                <>
                  <MobileDatePicker
                    toolbarPlaceholder={'Effective Date'}
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={onChange}
                    disabled
                    renderInput={(params: any) => (
                      <TextField
                        disabled
                        required={true}
                        variant="standard"
                        size="small"
                        {...params}
                        error={
                          !isDate(new Date(props?.cell?.row?.original?.effectiveDate)) ||
                          !props?.cell?.row?.original?.effectiveDate
                        }
                      />
                    )}
                  />
                  <Typography variant="body2">
                    {!isDate(new Date(props?.cell?.row?.original?.effectiveDate))
                      ? 'Invalid Date'
                      : ''}
                  </Typography>
                </>
              )}
            </>
          );
        },
      },
      {
        Header: 'Mon',
        accessor: 'monday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.monday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-monday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.monday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.monday}
              />
            </>
          );
        },
      },
      {
        Header: 'Tue',
        accessor: 'tuesday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.tuesday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-tuesday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.tuesday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.tuesday}
              />
            </>
          );
        },
      },
      {
        Header: 'Wed',
        accessor: 'wednesday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.wednesday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-wednesday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.wednesday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.wednesday}
              />
            </>
          );
        },
      },
      {
        Header: 'Thu',
        accessor: 'thursday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.thursday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-thursday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.thursday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.thursday}
              />
            </>
          );
        },
      },
      {
        Header: 'Fri',
        accessor: 'friday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.friday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-friday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.friday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.friday}
              />
            </>
          );
        },
      },
      {
        Header: 'Sat',
        accessor: 'saturday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.saturday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-saturday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.saturday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.saturday}
              />
            </>
          );
        },
      },
      {
        Header: 'Sun',
        accessor: 'sunday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.sunday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-sunday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.sunday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.sunday}
              />
            </>
          );
        },
      },
      {
        Header: 'Holiday',
        accessor: 'holiday',
        sort: false,
        Cell: (props: any) => {
          const [value, setValue] = useState<string>(
            values.locationShifts?.[props?.row?.index]?.holiday ?? ''
          );
          const onChange = (e: any) => {
            setValue(e.target.value);
          };

          return (
            <>
              <TextField
                disabled
                id={`${props.row.index}-${props.column.id}`}
                key={`${props.row.index}-${props.column.id}`}
                fullWidth
                size="small"
                variant="standard"
                onBlur={() =>
                  updateShiftsData(
                    props.row.original,
                    props.column.id,
                    value,
                    props.row.original.locationShiftId
                  )
                }
                placeholder={props?.column?.Header}
                autoComplete="nope"
                value={value}
                required={true}
                InputProps={{
                  accessKey: `input-${props.row.index}-holiday`,
                }}
                classes={{
                  root: classes.root,
                }}
                onChange={onChange}
                error={errors && !!errors?.locationShifts?.[props?.row?.index]?.holiday}
                helperText={errors && errors?.locationShifts?.[props?.row?.index]?.holiday}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shifts, shiftsCopy.length, isLoading]
  );

  return (
    <Grid key={'shifts'} className={classes.locationHours} container spacing={1}>
      <IconHeading icon={AccessTimeIcon} title="Location Hours" variant="h2" />
      <Grid className={classes.buttons} item xs={6}>
        {false && (
          <Button disabled onClick={() => addShift()}>
            Add
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={shiftsCopy}
          hidePagination
          isLoading={isLoading}
          useTableProps={{
            // @ts-ignore
            updateMyData: updateShiftsData,
          }}
        />
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  locationHours: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));
