import { FC, useEffect, useState } from 'react';
import { Page } from '../../components';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { CalendarGrid } from '../../components/calendar-grid';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import { getDVMsLookup, getLocationsForSchedulingLookup } from '../../fetch/lookups';
import { IDropdownResponse, IDVMDropdown, ILocationDropdown } from '../../models/util';
import { useSnackbar } from 'notistack';
import { IShift } from '../../models/schedule';
import { getSchedule, getScheduledDaysForDVM, putFTPersist } from '../../fetch';
import { startOfWeek, lastDayOfWeek, addDays } from 'date-fns';
import { deepEqual } from 'fast-equals';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { handleError } from '../../helpers';

export const FTScheduling: FC = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const [previousSelectedDate, setPreviousSelectedDate] = useState(new Date());
  const [previousDate, setPreviousDate] = useState(new Date());
  const isNotPhone = useMediaQuery('only screen and (min-width : 768px)');
  const { enqueueSnackbar } = useSnackbar();

  const isToday = (someDate: Date | null) => {
    if (someDate === null) return;

    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const handleChangeWithoutSave = () => {
    if (!deepEqual(scheduled, scheduledClone)) {
      const result = window.confirm('You have unsaved changes, are you sure you want to continue?');
      if (result) {
        setScheduled([]);
        return result;
      } else {
        return result;
      }
    } else {
      return true;
    }
  };

  // Dropdowns
  const [locations, setLocations] = useState<ILocationDropdown[]>();
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocationDropdown | null>(null);

  const fetchLocations = async () => {
    try {
      setAreLocationsLoading(true);
      const res = await getLocationsForSchedulingLookup();
      setLocations(res);
      setSelectedLocation(res[0]);
      //we are adding a day here because the ISO date is read as GMT when it is in fact UTC
      setSelectedDate(addDays(new Date(res[0]?.nextOpenShift), 1));
      setDate(addDays(new Date(res[0]?.nextOpenShift), 1));
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreLocationsLoading(false);
    }
  };

  const [dvms, setDvms] = useState<IDropdownResponse[]>();
  const [areDvmsLoading, setAreDvmsLoading] = useState(false);
  const [selectedDvm, setSelectedDvm] = useState<IDVMDropdown | null>(null);
  const [showAllDvms, setShowAllDvms] = useState<boolean>(false);

  const fetchDvms = async () => {
    try {
      setAreDvmsLoading(true);
      const res = await getDVMsLookup({
        locationId: selectedLocation?.value,
        showAllDvms: showAllDvms,
      });

      setDvms(res);
      setSelectedDvm(res[0]);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVMs, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreDvmsLoading(false);
    }
  };

  const [selectedDVMSchedule, setSelectedDVMSchedule] = useState<string[]>([]);
  const fetchDvmSchedule = async () => {
    try {
      const res = await getScheduledDaysForDVM(selectedDvm?.value as string, {
        dateFrom: startDate,
        dateTo: endDate,
      });
      setSelectedDVMSchedule(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(
        errorMessage ||
          `Error loading the schedule for ${selectedDvm?.description}, please try again.`,
        {
          variant: 'error',
        }
      );
      console.log(error);
    }
  };

  // Schedule
  const [startDate, setStartDate] = useState(startOfWeek(selectedDate, { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState(lastDayOfWeek(selectedDate, { weekStartsOn: 1 }));
  const [scheduled, setScheduled] = useState<IShift[]>([]);
  const [openDate, setOpenDate] = useState<Date | null>();
  const [closeDate, setCloseDate] = useState<Date | null>();
  const [scheduledClone, setScheduledClone] = useState<IShift[]>([]);

  const fetchSchedule = async () => {
    try {
      const res = await getSchedule({
        locationIds: parseInt(selectedLocation?.value as string),
        dateFrom: startDate,
        dateTo: endDate,
      });

      setOpenDate(res?.[0]?.openDate);
      setCloseDate(res?.[0]?.closeDate);
      setScheduled(res?.[0]?.shifts);
      setScheduledClone(res?.[0]?.shifts);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading the Schedule, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const editSchedule = async () => {
    try {
      const payload = {
        // FT doesn't handle shift Request Approvals, so this should always be null
        shiftRequestApproval: [],
        scheduledShiftDeletion:
          scheduled.filter(shift => shift.isDeleted)?.map(shift => shift.shiftId as number) ?? null,
        scheduledShiftCreation: scheduled
          .filter(shift => shift.shiftId === null)
          ?.map(shift => {
            return {
              shiftId: 0,
              dvmId: shift.dvmId as number,
              shiftDate: shift.shiftDate,
              locationId: selectedLocation?.value as string,
            };
          }),
      };

      const res = await putFTPersist(payload);

      if (res.Detail) {
        enqueueSnackbar(`${handleError(res)}`, {
          variant: 'error',
        });
      }

      if (!res.Detail) {
        await fetchSchedule();
        await fetchDvmSchedule();
      }
    } catch (error: any) {
      const errorMessage = handleError(error?.response?.data);
      enqueueSnackbar(errorMessage || 'Something went wrong. Your changes were not saved.', {
        variant: 'error',
      });
      await fetchSchedule();
      await fetchDvmSchedule();
    }
  };

  const handleDateClick = (date: Date) => {
    setScheduled((prev: IShift[]) => {
      return [
        ...prev,
        {
          shiftId: null,
          dvmId: selectedDvm?.value,
          dvmName: selectedDvm?.description,
          dvmType: selectedDvm?.dvmType,
          shiftDate: date,
          isUpdated: true,
          isDeleted: false,
          hasIncentive: false,
        },
      ];
    });
  };

  const handleRemoveClick = (date: Date) => {
    setScheduled((prev: IShift[]) => {
      let deletedShift = prev.find(s => new Date(s.shiftDate).getTime() === date.getTime());

      if (deletedShift) {
        if (deletedShift?.shiftId) {
          deletedShift.isDeleted = true;
        } else {
          return prev.filter(s => new Date(s.shiftDate).getTime() !== date.getTime());
        }
      }
      if (deletedShift) {
        return [...prev, deletedShift];
      }
      return [...prev];
    });
  };

  useEffect(() => {
    fetchLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      fetchDvms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, showAllDvms]);

  useEffect(() => {
    if (selectedLocation && startDate && endDate) {
      fetchSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, startDate, endDate]);

  useEffect(() => {
    if (selectedDvm) fetchDvmSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDvm, startDate, endDate]);

  useEffect(() => {
    // Don't refetch if the user didn't change the date.
    if (previousSelectedDate.getTime() === selectedDate.getTime()) return;
    setPreviousSelectedDate(selectedDate);
    setStartDate(startOfWeek(selectedDate, { weekStartsOn: 1 }));
    setEndDate(lastDayOfWeek(selectedDate, { weekStartsOn: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    setPreviousDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Page
      title={'FT Scheduling'}
      additionalHeaderContent={
        isNotPhone ? (
          <Grid
            alignItems={'center'}
            justifyContent={'flex-end'}
            container
            spacing={1}
            className={classes.schedPaginationControls}
          >
            <Grid item xs={3} className={'this-week-btn-wrap'}>
              <Button
                onClick={() => {
                  if (handleChangeWithoutSave()) {
                    setSelectedDate(new Date());
                    setDate(new Date());
                  }
                }}
                disabled={isToday(date)}
              >
                This Week
              </Button>
            </Grid>

            <Grid container item alignItems={'center'} xs={3}>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    if (handleChangeWithoutSave()) {
                      const prevWeek = new Date(date);
                      prevWeek.setDate(prevWeek.getDate() - 7);
                      setSelectedDate(prevWeek);
                      setDate(prevWeek);
                    }
                  }}
                  title={'Previous Week'}
                  className={classes.weekPaginationBtns}
                >
                  <ArrowBackIos style={{ fontSize: '12px' }} />
                </IconButton>
              </Grid>
              <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                  <DesktopDatePicker
                    toolbarPlaceholder={'Date'}
                    inputFormat="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={val => {
                      setDate(val as Date);
                    }}
                    onAccept={val => {
                      if (handleChangeWithoutSave()) {
                        setSelectedDate(val as Date);
                      } else {
                        setDate(previousDate);
                        setSelectedDate(previousSelectedDate);
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        onBlur={() => {
                          // Short circuit if the user clicked in the box and didn't change the date.
                          if (previousSelectedDate.getTime() === date.getTime()) return;
                          const earliestPossibleDate = new Date('January 1, 1900');
                          if (handleChangeWithoutSave()) {
                            if (date < earliestPossibleDate) {
                              setSelectedDate(earliestPossibleDate);
                              setDate(earliestPossibleDate);
                            } else {
                              setSelectedDate(date);
                              setDate(date);
                            }
                          } else {
                            setDate(previousSelectedDate);
                            setSelectedDate(_ => {
                              return new Date(previousSelectedDate);
                            });
                          }
                        }}
                        size="small"
                        variant="standard"
                        {...params}
                      ></TextField>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    if (handleChangeWithoutSave()) {
                      const nextWeek = new Date(date);
                      nextWeek.setDate(nextWeek.getDate() + 7);
                      setSelectedDate(nextWeek);
                      setDate(nextWeek);
                    }
                  }}
                  title={'Next Week'}
                  className={classes.weekPaginationBtns}
                >
                  <ArrowForwardIos style={{ fontSize: '12px' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : undefined
      }
    >
      {isNotPhone ? (
        <Box width="100%">
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControl fullWidth required={true} variant="standard" size="small">
                <InputLabel shrink={!!selectedLocation?.value} htmlFor="locationsLabel">
                  Location
                </InputLabel>
                <Select
                  fullWidth
                  name="locations"
                  labelId="locationsLabel"
                  id="locations"
                  disabled={areLocationsLoading}
                  value={parseInt(selectedLocation?.value as string)}
                  onChange={(event: any) => {
                    handleChangeWithoutSave();
                    const location = locations?.find(loc => loc.value === event.target.value);
                    setSelectedLocation(location ?? null);
                    if (location) {
                      //we are adding a day here because the ISO date is read as GMT when it is in fact UTC
                      setSelectedDate(addDays(new Date(location.nextOpenShift), 1));
                      setDate(addDays(new Date(location.nextOpenShift), 1));
                    }
                  }}
                >
                  {locations?.map((location: ILocationDropdown, index) => {
                    return (
                      <MenuItem key={`${index + 1}`} value={location.value}>
                        {location.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                value={selectedDvm as IDropdownResponse}
                onChange={(event, newValue: any) => {
                  setSelectedDvm(newValue);
                }}
                disabled={areDvmsLoading}
                selectOnFocus
                handleHomeEndKeys
                loading={areDvmsLoading}
                id="selected-dvm"
                options={dvms || []}
                getOptionLabel={(option: IDropdownResponse) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  return `${option.description}`;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    // InputLabelProps={{
                    //   className: classes.ellipsis,
                    // }}
                    key={params.id}
                    size="small"
                    autoComplete="on"
                    label="DVM"
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} alignSelf={'end'}>
              <FormControlLabel
                label="Show All"
                control={
                  <Checkbox checked={showAllDvms} onChange={() => setShowAllDvms(!showAllDvms)} />
                }
              />
            </Grid>
          </Grid>
          {locations && locations.length > 0 && !!selectedLocation?.value && scheduled && (
            <CalendarGrid
              selectedLocation={selectedLocation.value}
              selectedDvm={selectedDvm}
              locations={locations}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleDateClick={handleDateClick}
              handleRemoveClick={handleRemoveClick}
              scheduled={scheduled}
              selectedDVMSchedule={selectedDVMSchedule}
              allowDvmCardDelete={false}
              openDate={openDate}
              closeDate={closeDate}
            />
          )}
          <Box display={'flex'} flexDirection={'row-reverse'}>
            <Button
              className={classes.saveButton}
              disabled={deepEqual(scheduled, scheduledClone)}
              onClick={() => editSchedule()}
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">
          <AlertTitle>Scheduling not available at this screen resolution</AlertTitle>
        </Alert>
      )}
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  saveButton: {
    marginTop: theme.spacing(1),
  },
  schedPaginationControls: {
    '& .this-week-btn-wrap': {
      paddingTop: theme.spacing(1.75),
    },
  },
  weekPaginationBtns: {
    border: 'none',
    background: 'none',
    color: theme.palette.common.black,
    padding: 0,
    borderRadius: 0,
    minWidth: theme.spacing(4),

    '&:hover': {
      background: 'none',
    },
  },
}));
