import { Mail, Phone } from '@mui/icons-material';
import { Fade, Grid, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useMemo } from 'react';
import { formatDate, formatInputPhoneNumber, formatShortFriendlyDateWithTime } from '../../helpers';
import { DVMPeekInfo, requestAndShift } from '../../models';
import { Coin } from '../icons/coin';
import { Modal } from '../modal';
import { Table } from '../table/Table';

interface IInfoModal {
  isOpen: boolean;
  onClose: () => void;
  info: DVMPeekInfo | null;
}

export const InfoModal: FC<IInfoModal> = ({ isOpen, onClose, info }) => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return (
            <span>
              {original.shiftDate && original.shiftTime
                ? `${formatDate(original.shiftDate)} ${original.shiftTime}`
                : ''}
            </span>
          );
        },
      },
      {
        Header: 'Location',
        accessor: 'location',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return <span>{original.locationName ?? ''}</span>;
        },
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        sort: false,
        isNumber: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return <span>{original.rate ? `$${original.rate}` : ''}</span>;
        },
      },
      {
        Header: 'Incentive',
        accessor: 'incentive',
        sort: false,
        isNumber: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return (
            <span>
              {original.incentive ? <Coin maxHeight={20} /> : null}{' '}
              {original.incentive ? `$${original.incentive}` : ''}
            </span>
          );
        },
      },
      {
        Header: 'Total Payment',
        accessor: 'totalPayment',
        sort: false,
        isNumber: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return <span>{original.totalPayment ? `$${original.totalPayment}` : ''}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return <span>{original.status ?? ''}</span>;
        },
      },
      {
        Header: 'Status Date',
        accessor: 'statusDate',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: requestAndShift } };
        }) => {
          return (
            <span>
              {original.statusDate ? `${formatShortFriendlyDateWithTime(original.statusDate)}` : ''}
            </span>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [info]
  );

  return (
    <Modal
      titleContent={
        <Link target={'_blank'} href={`/dvms/${info?.dvmId}`}>
          {info?.name}
        </Link>
      }
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
    >
      <Fade in={isOpen}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Link
              className={classes.link}
              color={'secondary'}
              variant="body2"
              href={`tel:${info?.phone}`}
            >
              {<Phone />} {formatInputPhoneNumber(info?.phone)}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link
              className={classes.link}
              color={'secondary'}
              variant="body2"
              href={`mailto:${info?.email}`}
            >
              {<Mail />} {info?.email}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={columns}
              data={info?.requestsAndShifts ?? []}
              hidePagination
              isLoading={false}
            />
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
