import { FC } from 'react';
import { Page } from '../../components';
import { Grid, Link } from '@mui/material';

export const DvmPending: FC = () => {
    return (
    <Page title={'Registration In Progress'}>
        <Grid container spacing={3}>
            <Grid item>A Relief Staffing Specialist will be in touch with you shortly to schedule a time to speak with you. For any questions, please contact <Link href="mailto:relief@vetcher.com">relief@vetcher.com</Link></Grid>
        </Grid>
    </Page>
    );
};
  