import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import coinIncentiveIcon from '../../images/coin-incentive.png';

import { FC } from 'react';

export interface ICoin {
  maxHeight?: number;
}

export const Coin: FC<ICoin> = ({ maxHeight }) => {
  const classes = useStyles({ maxHeight });

  return <img className={classes.coin} src={coinIncentiveIcon} alt={'incentive-coin'} />;
};

const useStyles = makeStyles<Theme, { maxHeight: number | undefined }>((theme: Theme) => ({
  coin: {
    maxHeight: ({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '28px'),
    marginLeft: theme.spacing(1),
    marginBottom: '-5px',
  },
}));
