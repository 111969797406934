import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { FC, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Error } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { UserContextHandler, LoadProvider, NavContextHandler, UserContext } from './context';
import {
  Home,
  ReliefScheduling,
  FTScheduling,
  PendingPayments,
  Locations,
  LocationsDetails,
  Dvms,
  DvmPending,
  NotFound,
  Holidays,
  DvmDetails,
  Users,
  Activity,
  EntityManagement,
  DvmDisabled,
  PrivacyPolicy,
  TermsAndConditions,
} from './pages';
import { theme } from './styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PrivateRoute } from './components/skeleton/private-route';
import { Roles, Paths } from './constants';
import { Loader } from './components';
import buildSettings from './buildSettings.json';
import { LoadScript } from '@react-google-maps/api';
import { HeaderLoader } from './components/loader/header-loader';
import ErrorBoundary from './components/error-handling/error-boundary';
import { ErrorPage } from './pages/error';
import { VerifyEmail } from './pages/verify-email/verify-email';

const { REACT_APP_GA_TRACKING } = require('./buildSettings.json');

declare global {
  interface Window {
    ga: any;
  }
}

const initializeGoogleAnalytics = () => {
  if (!window.ga && REACT_APP_GA_TRACKING) {
    ReactGA.initialize(REACT_APP_GA_TRACKING, {
      gtagOptions: {
        titleCase: false,
      },
    });
  }
};

// define public facing paths
const publicPaths = ['/privacy-policy', '/terms-and-conditions'];

const AuthWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  // Check if the current route is one of the public paths
  const isPublicRoute = publicPaths.includes(location.pathname);

  if (isPublicRoute) {
    return <>{children}</>; // Render children without authentication wrapper
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorPage}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export const App: FC = () => {
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    //@ts-ignore TypeScript is complaining, but this is the best fix I've found so far. More info => https://github.com/facebook/create-react-app/issues/11771#issuecomment-1059644333
    window.process = { ...window.process };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <ErrorBoundary>
            <AuthWrapper>
              <LoadProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <NavContextHandler>
                    <UserContextHandler>
                      {/* @ts-ignore - This solves the type problem and won't break on the next install (suggested solution is to manually change the installed package), but this documents the reason this is throwing an error => https://github.com/iamhosseindhv/notistack/issues/485#issuecomment-1128778222 */}
                      <SnackbarProvider
                        iconVariant={{
                          error: <Error style={{ marginRight: '5px' }} />,
                        }}
                        maxSnack={3}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        TransitionComponent={Slide}
                        dense={false}
                      >
                        <LoadScript
                          loadingElement={<Loader position="centered" type="fullscreen" />}
                          googleMapsApiKey={`${buildSettings.REACT_APP_GOOGLE_KEY}`}
                        >
                          <Routes />
                        </LoadScript>
                      </SnackbarProvider>
                    </UserContextHandler>
                  </NavContextHandler>
                </LocalizationProvider>
              </LoadProvider>
            </AuthWrapper>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const Routes: FC = () => {
  const history = useHistory();
  const currentRoute = useLocation();
  const {
    user,
    isDVM,
    isDVMPending,
    isUserInAnyValidRole,
    isActive,
    account,
    isFetching,
    isSysAdmin,
    isSetUp,
    isDVMDeactivated,
  } = useContext(UserContext);

  // Redirects based on the User's Roles and Privileges
  useEffect(() => {
    // Avoid redirection for public routes
    if (publicPaths.includes(currentRoute.pathname)) {
      return;
    }
    // If the user is not in a valid role or not active, redirect to the 404 page
    if ((!isUserInAnyValidRole || !isActive) && !isFetching) {
      history.push(Paths.NOT_FOUND);
    } else {
      if (isDVM) {
        if (isDVMDeactivated) {
          history.push(Paths.DVM_DEACTIVATED);
        } else if (isDVMPending) {
          // If the user is a DVM with Pending status, redirect to the Pending DVM page
          history.push(Paths.DVM_PENDING);
        } else if (
          user?.validation?.value?.dvmInformation?.dvmType === 'Employee' &&
          user?.validation?.value?.dvmInformation?.dvmStatus === 'Active' &&
          currentRoute.pathname === Paths.HOME &&
          !isSysAdmin
        ) {
          // If user is Active DVM Employee and not a SysAdmin and we are on the home page, redirect them to their profile.
          history.push(Paths.DVM_PROFILE);
        } else {
          return;
        }
      } else {
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, account, isFetching]);

  if (isFetching && isSetUp) {
    return <Loader position="centered" type="fullscreen" />;
  }
  // isFetching && isDVM && !isSetUp
  if (isFetching && !isSetUp) {
    return (
      <HeaderLoader
        title="Registration In Progress"
        subtitle="Please wait while we setup your account for the first time. This may take a couple minutes."
        position="centered"
        type="fullscreen"
      />
    );
  }

  return (
    <Switch>
      <Route exact path={'/'} component={Home} />
      <Route exact path={'/not-found'} component={NotFound} />
      <Route exact path={'/error'} component={ErrorPage} />
      <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
      <Route exact path={'/terms-and-conditions'} component={TermsAndConditions} />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.DVM]}
        path={'/verify-email'}
        component={VerifyEmail}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.DVM]}
        path={'/dvm-pending'}
        component={DvmPending}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.DVM]}
        path={'/dvm-deactivated'}
        component={DvmDisabled}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/relief-scheduling'}
        component={ReliefScheduling}
      />
      <PrivateRoute
        exact
        allowedRoles={[
          Roles.SYSTEM_ADMIN,
          Roles.PRACTICE_MANAGER,
          Roles.OPERATIONS_MANAGER,
          Roles.RSS,
        ]}
        path={'/ft-scheduling'}
        component={FTScheduling}
      />
      <PrivateRoute
        exact
        allowedRoles={[
          Roles.SYSTEM_ADMIN,
          Roles.PRACTICE_MANAGER,
          Roles.OPERATIONS_MANAGER,
          Roles.RSS,
        ]}
        path={'/pending-payments'}
        component={PendingPayments}
      />
      <PrivateRoute exact allowedRoles={[Roles.DVM]} path={'/profile'} component={DvmDetails} />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/locations'}
        component={Locations}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/locations/:locationId'}
        component={LocationsDetails}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/locations/new-location'}
        component={LocationsDetails}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN]}
        path={'/legal-entities'}
        component={EntityManagement}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/dvms'}
        component={Dvms}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/dvms/:dvmId'}
        component={DvmDetails}
      />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN, Roles.RSS]}
        path={'/holidays'}
        component={Holidays}
      />
      <PrivateRoute exact allowedRoles={[Roles.SYSTEM_ADMIN]} path={'/users'} component={Users} />
      <PrivateRoute
        exact
        allowedRoles={[Roles.SYSTEM_ADMIN]}
        path={'/activity'}
        component={Activity}
      />
      <Route exact component={NotFound} />
    </Switch>
  );
};
