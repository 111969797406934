import { IDropdownResponse } from './../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/markets';

export const getStates = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/states`);
    return data;
  } catch (error) {
    logError(error, 'getStates failed');
    return Promise.reject(error);
  }
};

export const getDVMMarkets = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/dvmMarkets`);
    return data;
  } catch (error) {
    logError(error, 'getDVMMarkets failed');
    return Promise.reject(error);
  }
};
