import {
  IPendingPaymentUpdateContainer,
  IPendingPayment
} from './../models/payments';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/Payments';

export const getPendingPayments = async (): Promise<IPendingPayment[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetPendingPayments`);
    return data;
  } catch (error) {
    logError(error, 'getPendingPayments failed');
    return Promise.reject(error);
  }
};
export const updatePendingPaymentStatus = async (pendingPaymentUpdate: IPendingPaymentUpdateContainer): Promise<IPendingPayment> => {
  try {
    const { data } = await authFetch.put(`${baseUrl}/UpdatePendingPayment`, pendingPaymentUpdate);
    return data;
  } catch (error) {
    logError({error, payload: pendingPaymentUpdate}, 'updatePendingPaymentStatus failed');
    return Promise.reject(error);
  }
};