import { ICreateIncentiveRate, IIncentiveRate, IUpdateIncentiveRate } from '../models/incentiveRates';
import { IResponse } from '../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';


const baseUrl = '/IncentiveRates';
export const getIncentiveRates = async (locationId: string, filters?: {  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;}
): Promise<IResponse<IIncentiveRate[]>> => {
    try {
      const { data } = await authFetch.get(`${baseUrl}/paged/${locationId}`, filters);
      return data;
    } catch (error) {
      logError(error, 'getIncentiveRates failed');
      return Promise.reject(error);
    }
  };

  export const createIncentiveRate = async (incentiveRate: ICreateIncentiveRate): Promise<string> => {
    try {
      const { data } = await authFetch.post(`${baseUrl}`, {incentiveRate});
      return data;
    } catch (error: any) {
      logError({error, payload: {incentiveRate}}, 'getDvms failed');
      return Promise.reject(error);
    }
  };

  export const updateIncentiveRate = async (incentiveRate: IUpdateIncentiveRate): Promise<any> => {
    try {
      const data = await authFetch.put(`${baseUrl}`, {incentiveRate});
      return data;
    } catch (error: any) {
      logError({error, payload: {incentiveRate}}, 'updateIncentiveRate failed');
      return Promise.reject(error);
    }
  };

  export const deleteIncentiveRate = async (incentiveRateId:number): Promise<any> => {
    try {
      const data = await authFetch.delete(`${baseUrl}/${incentiveRateId}`);
      return data;
    } catch (error) {
      logError(error, 'deleteIncentiveRate failed');
      return Promise.reject(error);
    }
  };