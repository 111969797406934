import React, { FC, useEffect, useRef, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  alpha,
} from '@mui/material';
import { IAvailableShift, IMapLocation } from '../../models';
import { CheckCircle, Close, Search, KeyboardArrowLeft, EventAvailable } from '@mui/icons-material';
import { theme } from '../../styles';
import { createDVMShiftRequest } from '../../fetch/shift-requests';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { getMapLocations } from '../../fetch/maps';
import { IDateRange } from '../../models/date';
import coinIncentiveIcon from '../../images/coin-incentive.png';
import { formatMoney } from '../../helpers';

interface IRequestShifts {
  isRequestShiftsOverlay: boolean;
  setIsRequestShiftsOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestedShifts: React.Dispatch<React.SetStateAction<IAvailableShift[]>>;
  requestedShifts: IAvailableShift[];
  setInfoWindow: React.Dispatch<React.SetStateAction<number | null | undefined>>;
  setIsOverlayCovering: React.Dispatch<React.SetStateAction<boolean>>;
  overlayContainerHeight: string;
  selectedDateRange: IDateRange;
  setMapLocations: React.Dispatch<React.SetStateAction<IMapLocation[] | null>>;
}
export const RequestShifts: FC<IRequestShifts> = ({
  isRequestShiftsOverlay,
  setIsRequestShiftsOverlay,
  setRequestedShifts,
  requestedShifts,
  setInfoWindow,
  setIsOverlayCovering,
  overlayContainerHeight,
  selectedDateRange,
  setMapLocations,
}) => {
  const classes = useStyles({ overlayContainerHeight: overlayContainerHeight });
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px)`);

  const Schema = Yup.object().shape({
    requestedShifts: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number().required('All amounts are required'),
      })
    ),
  });

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [submittedRequests, setSubmittedRequests] = useState<IAvailableShift[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleError = (res: any) => {
    if (res && res.Detail) {
      return enqueueSnackbar(`Error, ${res.Detail}`, {
        variant: 'error',
      });
    } else if (
      res &&
      res.Errors &&
      Object.values(res.Errors)[0] &&
      Object.values(Object.values(res.Errors)[0] as any)[0]
    ) {
      return enqueueSnackbar(`Error, ${Object.values(Object.values(res.Errors)[0] as any)[0]}`, {
        variant: 'error',
      });
    }
  };
  const handleSubmit = async (values: IAvailableShift[], actions: any) => {
    try {
      const res = await createDVMShiftRequest({
        shiftRequests: values.map(shift => {
          return {
            locationId: shift.locationId,
            shiftDate: new Date(shift.shiftDate),
            amount: shift.amount,
          };
        }),
      });

      handleError(res);
      if (!res.Detail) {
        setShowSuccessScreen(true);
        setSubmittedRequests(values);
        setRequestedShifts([]);
        setInfoWindow(null);
        setIsOverlayCovering(false);
        overlay?.current?.scrollTo(0, 0);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error saving this Shift Request this, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const overlay = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (requestedShifts.length === 0 && !showSuccessScreen) {
      setIsRequestShiftsOverlay(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedShifts]);

  const updateMap = async () => {
    try {
      const res = await getMapLocations(selectedDateRange.startDate, selectedDateRange.endDate);
      setMapLocations(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading map locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
    }
  };

  return (
    <div
      ref={overlay}
      className={clsx(classes.overlay, isRequestShiftsOverlay ? classes.requestShiftsCover : null)}
    >
      {showSuccessScreen ? (
        <>
          <div className={classes.successContainer}>
            <div className={classes.shiftContainer}>
              <Typography variant="h2">Your Shift Request Has Been Received!</Typography>
            </div>
            <p>
              Your shift request was submitted successfully. We will reach out to you with updates
              as soon as they are available.
            </p>
            <div className={classes.checkCircleContainer}>
              <CheckCircle classes={{ root: classes.checkCircle }} />
            </div>
            <div>
              {submittedRequests.map(request => {
                return (
                  <div className={classes.submittedRequest}>
                    <Typography variant="h3">
                      {`${request.shiftDateFormatted} (${request.shiftDateDayOfWeek})`}
                    </Typography>
                    <div className={classes.inputLine}>
                      <Typography className={classes.submittedDescription}>
                        <span className={classes.submittedLocation}>
                          {request.locationName} {request.shiftTime}
                        </span>
                        <span className={classes.submittedAmount}>
                          {request?.incentiveRate ? (
                            <span>
                              ${parseInt(request.amount) + request?.incentiveRate} (
                              <img
                                className={classes.coinSubmitted}
                                src={coinIncentiveIcon}
                                alt={'incentive-coin'}
                              />
                              ${request?.incentiveRate}) / day
                            </span>
                          ) : (
                            `$${request.amount} / day`
                          )}
                        </span>
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {submittedRequests.length > 0 && (
            <Button
              className={classes.findMore}
              onClick={() => {
                setIsRequestShiftsOverlay(false);
                setShowSuccessScreen(false);
                updateMap();
              }}
              startIcon={<Search />}
            >
              {`Find More Shifts`}
            </Button>
          )}
        </>
      ) : (
        <>
          <div className={classes.shiftContainer}>
            <Typography variant="h2">Request Shifts</Typography>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              requestedShifts: requestedShifts.map(shift => {
                if (!shift.amount) {
                  return { ...shift, amount: '' };
                }
                return shift;
              }),
            }}
            onSubmit={(values, actions) => handleSubmit(values.requestedShifts, actions)}
            validationSchema={Schema}
            validateOnMount
            render={({ values, errors }) => (
              <Form>
                <FieldArray
                  name="requestedShifts"
                  render={arrayHelpers => {
                    return (
                      <>
                        <div className={classes.requestedShiftsContainer}>
                          {values.requestedShifts.map((reqShift, index) => {
                            return (
                              <>
                                <div
                                  className={classes.bid}
                                  key={`${reqShift.shiftDate}-${reqShift.locationId}`}
                                >
                                  <Typography variant="h3">{`${reqShift.shiftDateFormatted} (${reqShift.shiftDateDayOfWeek})`}</Typography>
                                  <div className={classes.inputLine}>
                                    <Typography className={classes.description}>
                                      {reqShift.locationName} {reqShift.shiftTime}
                                    </Typography>
                                    <div className={classes.input}>
                                      <TextField
                                        name={`${reqShift.locationId} ${reqShift.shiftDate}`}
                                        variant={'standard'}
                                        value={
                                          values.requestedShifts[
                                            requestedShifts.findIndex(
                                              shift =>
                                                shift?.locationId === reqShift?.locationId &&
                                                reqShift?.shiftDate === shift?.shiftDate
                                            )
                                          ]?.amount ?? ''
                                        }
                                        onChange={e => {
                                          const re = /^[0-9\b]+$/;
                                          // make sure it is number only before we update state
                                          if (e.target.value === '' || re.test(e.target.value)) {
                                            arrayHelpers.form.setFieldValue(
                                              `requestedShifts[${requestedShifts.findIndex(
                                                shift =>
                                                  shift.locationId === reqShift.locationId &&
                                                  reqShift.shiftDate === shift.shiftDate
                                              )}].amount`,
                                              e.target.value
                                            );
                                            const updatedShift = requestedShifts?.find(
                                              shift =>
                                                shift?.locationId === reqShift?.locationId &&
                                                reqShift?.shiftDate === shift?.shiftDate
                                            );
                                            if (updatedShift) {
                                              updatedShift.amount = e.target.value;
                                            }
                                          }
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">$</InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position="end">/day</InputAdornment>
                                          ),
                                        }}
                                        // https://github.com/mui/material-ui/issues/9046
                                        inputProps={{
                                          inputMode: 'numeric',
                                          pattern: '[0-9]*',
                                        }}
                                        error={
                                          arrayHelpers.form.getFieldMeta(
                                            `${reqShift.locationId} ${reqShift.shiftDate}`
                                          ).touched &&
                                          arrayHelpers.form.errors &&
                                          arrayHelpers.form.getFieldMeta(
                                            `${reqShift.locationId} ${reqShift.shiftDate}`
                                          ).error
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          arrayHelpers.form.getFieldMeta(
                                            `${reqShift.locationId} ${reqShift.shiftDate}`
                                          ).touched &&
                                          arrayHelpers.form.errors &&
                                          arrayHelpers.form.getFieldMeta(
                                            `${reqShift.locationId} ${reqShift.shiftDate}`
                                          ).error
                                        }
                                      />
                                    </div>
                                    <div className={classes.remove}>
                                      {!isMobile ? (
                                        <Button
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setRequestedShifts(prev => {
                                              return prev.filter(shift => {
                                                const isCorrectShiftToDelete =
                                                  shift.locationId === reqShift.locationId &&
                                                  shift.shiftDateFormatted ===
                                                    reqShift.shiftDateFormatted;
                                                if (isCorrectShiftToDelete) delete shift.amount;
                                                return !isCorrectShiftToDelete;
                                              });
                                            });
                                            arrayHelpers.form.validateForm();
                                          }}
                                          variant={'text'}
                                          startIcon={<Close />}
                                        >
                                          Remove
                                        </Button>
                                      ) : (
                                        <IconButton
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setRequestedShifts(prev => {
                                              return prev.filter(shift => {
                                                const isCorrectShiftToDelete =
                                                  shift.locationId === reqShift.locationId &&
                                                  shift.shiftDateFormatted ===
                                                    reqShift.shiftDateFormatted;
                                                if (isCorrectShiftToDelete) delete shift.amount;
                                                return !isCorrectShiftToDelete;
                                              });
                                            });
                                            arrayHelpers.form.validateForm();
                                          }}
                                        >
                                          <Close />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {reqShift?.incentiveRate && (
                                  <div className={classes.incentiveArea}>
                                    <img
                                      className={classes.coin}
                                      src={coinIncentiveIcon}
                                      alt={'incentive-coin'}
                                    />
                                    {` + ${formatMoney(reqShift.incentiveRate, 0)} Incentive`}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className={classes.buttons}>
                          <Button
                            className={classes.close}
                            onClick={() => setIsRequestShiftsOverlay(false)}
                            variant={'outlined'}
                          >
                            <KeyboardArrowLeft />
                            {`Select More`}
                          </Button>

                          <Button
                            disabled={!arrayHelpers.form.isValid || arrayHelpers.form.isSubmitting}
                            className={classes.submit}
                            startIcon={<EventAvailable />}
                            type={'submit'}
                          >
                            {`Submit Shifts (${requestedShifts.length})`}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </Form>
            )}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { overlayContainerHeight: string }>((theme: Theme) => ({
  overlay: {
    backgroundColor: '#f6f6f6',
    position: 'absolute',
    height: 0,
    width: '100%',
    bottom: 0,
    left: 0,
    visibility: 'hidden',
    transition: '0.3s',
    overflow: 'hidden',
  },
  incentiveArea: {
    padding: theme.spacing(2),
    margin: theme.spacing(-2, 1, 2, 1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    justifyContent: 'center',
  },
  coin: {
    maxHeight: '28px',
    marginRight: theme.spacing(1),
  },
  coinSubmitted: {
    maxHeight: '20px',
    marginRight: theme.spacing(0.5),
    marginBottom: '-5px',
  },
  requestShiftsCover: {
    visibility: 'visible',
    width: '100%',
    bottom: 0,
    left: 0,
    height: ({ overlayContainerHeight }) => overlayContainerHeight,
    backgroundColor: theme.palette.background.default,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
  },
  requestShifts: {
    width: '100%',
    borderRadius: 0,
    padding: theme.spacing(1.5),
    position: 'absolute',
    bottom: 0,
  },
  shiftContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem .5rem .75rem',
  },
  close: {
    alignSelf: 'flex-end',
    position: 'absolute',

    right: '.75em',
    top: '.75em',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '& svg': {
      height: '.75em',
      width: '.75em',
    },
  },
  submit: {
    width: 'calc(100% - 16px)',
    borderRadius: 0,
    padding: theme.spacing(1.5),
    position: 'absolute',
    bottom: '0',
    left: '8px',

    '&.Mui-disabled': {
      backgroundColor: '#a9a9a9',
    },
  },
  bid: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 1, 2, 1),
    backgroundColor: alpha(theme.palette.common.black, 0.05),
  },
  inputLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    flex: '8 1 auto',
    padding: theme.spacing(1),
  },
  submittedDescription: {
    flex: '8 1 auto',
    padding: theme.spacing(1, 0),
  },
  submittedLocation: {
    fontWeight: 'bold',
  },
  submittedRequest: {
    margin: theme.spacing(1, 0),
  },
  input: {
    flex: '1 1 auto',
    padding: theme.spacing(1),
  },
  remove: {
    flex: '1 4 auto',
    padding: theme.spacing(1),

    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    bottom: '45px',
    left: '15px',
  },
  requestedShiftsContainer: {
    overflow: 'auto',
    height: '68vh',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  checkCircleContainer: {
    display: 'flex',
    alignSelf: 'center',
  },
  checkCircle: {
    fontSize: '200px',
    color: theme.palette.secondary.main,
  },
  submittedAmount: {
    marginLeft: theme.spacing(2),
  },
  findMore: {
    width: '100%',
    borderRadius: 0,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    position: 'sticky',
    bottom: 0,
  },
}));
