import { FC } from 'react';
import { Page } from '../../components';
import { Grid, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

export const ErrorPage: FC = () => {
  const history = useHistory();
  //@ts-ignore
  const errorMessage = history.location.state?.error;
  return (
    <Page title={'Something went wrong'}>
      <Grid container spacing={3}>
        <Grid item>
          {errorMessage
            ? errorMessage
            : 'This page has encountered an error and it has been logged into our system. Please return to the home page or try again.'}{' '}
          If you continue to experience problems, contact{' '}
          <Link href="mailto:relief@vetcher.com">
            relief@vetcher.com
          </Link>
        </Grid>
      </Grid>
    </Page>
  );
};
