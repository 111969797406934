import { DvmDetails } from './../pages/dvms/dvm-details';
import { Holidays } from './../pages/holidays/holidays';
import { Locations } from '../pages/locations/locations';
import { ReliefScheduling } from '../pages/relief-scheduling/relief-scheduling';
import { FTScheduling } from '../pages/ft-scheduling/ft-scheduling';
import { EntityManagement } from '../pages/entity-management/entity-management';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC, ReactNode } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  Home as MUIHome,
  Today,
  AdminPanelSettingsTwoTone,
  GroupsTwoTone,
  AccountBox,
  StoreTwoTone,
  RuleTwoTone
} from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Home } from '../pages';
import { Dvms } from '../pages/dvms/dvms';
import { Users } from './../pages/users/users';
import { PendingPayments } from '../pages/pending-payments';
import { Activity } from './../pages/activity/activity';

export interface IRoute {
  sortOrder: number;
  label: string;
  path: typeof Paths[keyof typeof Paths];
  pageComponent: FC<any>;
  accessTokens?: string[];
  allowedRoles: string[];
  children?:
    | ReactNode
    | ((props: RouteChildrenProps<{ [x: string]: string | undefined }, unknown>) => ReactNode);
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}
export const Paths = {
  HOME: '/',
  RELIEF_SCHEDULING: '/relief-scheduling',
  FT_SCHEDULING: '/ft-scheduling',
  PENDING_PAYMENTS: '/pending-payments',
  DVM_PROFILE: '/profile',
  LOCATIONS: '/locations',
  LOCATION_DETAILS: '/locations/:locationId',
  DVMS: '/dvms',
  DVMS_DETAILS: '/dvms/:dvmId',
  DVM_PENDING: '/dvm-pending',
  DVM_DEACTIVATED: '/dvm-deactivated',
  HOLIDAYS: '/holidays',
  USERS: '/users',
  LEGALENTITIES: '/legal-entities',
  ACTIVITY: '/activity',
  NOT_FOUND: '/not-found',
};

export const Roles = {
  SYSTEM_ADMIN: 'SystemAdmin',
  RSS: 'RSS',
  OPERATIONS_MANAGER: 'OperationsManager',
  PRACTICE_MANAGER: 'PracticeManager',
  DVM: 'DVM',
};

export const Routes: IRoute[] = [
  {
    sortOrder: 0,
    label: 'Home',
    path: Paths.HOME,
    icon: MUIHome,
    pageComponent: Home,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.RSS, Roles.OPERATIONS_MANAGER, Roles.PRACTICE_MANAGER],
  },
  {
    sortOrder: 1,
    label: 'Relief Scheduling',
    path: Paths.RELIEF_SCHEDULING,
    icon: Today,
    pageComponent: ReliefScheduling,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.RSS],
  },
  {
    sortOrder: 2,
    label: 'FT Scheduling',
    path: Paths.FT_SCHEDULING,
    icon: CalendarMonthIcon,
    pageComponent: FTScheduling,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.OPERATIONS_MANAGER, Roles.PRACTICE_MANAGER, Roles.RSS],
  },
  {
    sortOrder: 3,
    label: 'Pending Payments',
    path: Paths.PENDING_PAYMENTS,
    icon: PaymentsIcon,
    pageComponent: PendingPayments,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.OPERATIONS_MANAGER, Roles.PRACTICE_MANAGER, Roles.RSS],
  },
  {
    sortOrder: 4,
    label: 'Profile',
    path: Paths.DVM_PROFILE,
    icon: AccountBox,
    pageComponent: DvmDetails,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.DVM],
  },
  {
    sortOrder: 5,
    label: 'Locations',
    path: Paths.LOCATIONS,
    icon: LocationOnOutlinedIcon,
    pageComponent: Locations,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.RSS],
  },
  {
    sortOrder: 6,
    label: 'Legal Entities',
    path: Paths.LEGALENTITIES,
    icon: StoreTwoTone,
    pageComponent: EntityManagement,
    allowedRoles: [Roles.SYSTEM_ADMIN],
  },
  {
    sortOrder: 7,
    label: 'Holidays',
    path: Paths.HOLIDAYS,
    icon: CelebrationOutlinedIcon,
    pageComponent: Holidays,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.RSS],
  },
  {
    sortOrder: 8,
    label: 'DVMs',
    path: Paths.DVMS,
    icon: GroupsTwoTone,
    pageComponent: Dvms,
    allowedRoles: [Roles.SYSTEM_ADMIN, Roles.RSS],
  },
  {
    sortOrder: 9,
    label: 'Users',
    path: Paths.USERS,
    icon: AdminPanelSettingsTwoTone,
    pageComponent: Users,
    allowedRoles: [Roles.SYSTEM_ADMIN],
  },
  {
    sortOrder: 10,
    label: 'Activity',
    path: Paths.ACTIVITY,
    icon: RuleTwoTone,
    pageComponent: Activity,
    allowedRoles: [Roles.SYSTEM_ADMIN],
  },
];
