import { authFetch } from './fetch';
import { logError } from '../services';
const baseUrl = '/dvms';

export const createInsurance = async (insuranceFile: any, dvmId: number): Promise<any> => {
  try {
    const formData = new FormData();
    if (insuranceFile) {
      formData.append('FileDetails', insuranceFile as File);
    } else {
      //@ts-ignore
      formData.append('FileDetails', '');
    }

    const { data } = await authFetch.post(`${baseUrl}/${dvmId}/Insurance`, formData);
    return data;
  } catch (error) {
    logError({error, payload: {insuranceFile, dvmId}}, 'createInsurance failed');
    return Promise.reject(error);
  }
};
export const deleteInsurance = async (dvmId: number): Promise<any> => {
  try {
    const data = await authFetch.delete(`${baseUrl}/${dvmId}/Insurance`);
    return data;
  } catch (error) {
    logError(error, 'deleteInsurance failed');
    return Promise.reject(error);
  }
};
