import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import React, { FC, useRef } from 'react';
import { InfoBox } from '@react-google-maps/api';
import { IMapLocation } from '../../models';
import { IconButton } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

interface ICustomInfoWindow {
  onCloseClick: any;
  location: IMapLocation;
  lat: number;
  lng: number;
}
const getPixelPositionOffset = (width: number, height: number) => {
  return {
    x: width / (width / 2),
    y: height + 50,
  };
};

export const CustomInfoWindow: FC<ICustomInfoWindow> = ({ onCloseClick, location, lat, lng }) => {
  const classes = useStyles();
  const position = { lat: lat, lng: lng };
  const infoBox = useRef(null);
  const options = {
    closeBoxURL: '',
    enableEventPropagation: true,
    alignBottom: true,
    pixelOffset: new google.maps.Size(30, 5),
  };

  return (
    <div>
      <InfoBox
        ref={infoBox}
        // @ts-ignore
        position={position}
        options={options}
        onCloseClick={() => console.log('close')}
        getPixelPositionOffset={getPixelPositionOffset}
        pixelOffset={getPixelPositionOffset}
      >
        <>
          <div className={classes.overlay}>
            <h3 className={classes.title}>{location.name}</h3>
            <p className={classes.address}>{location.street}</p>
            <p
              className={classes.address}
            >{`${location.city}, ${location.state} ${location.zip}`}</p>
            <h3>{`${location.shiftCount} available shifts`}</h3>
          </div>
          <IconButton
            className={classes.removeButton}
            onClick={() => {
              onCloseClick();
            }}
          >
            <HighlightOff />
          </IconButton>
        </>
      </InfoBox>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.secondary.main,
    marginTop: '1.25rem',

    margin: '.75rem 0 .75rem 0',
  },
  address: {
    color: '#848e9f',
    margin: '0 0 0 0',
  },
  overlay: {
    position: 'relative',
    padding: '.5rem 1rem',
    backgroundColor: '#fff',
    maxWidth: 185,

    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
    zIndex: -1,
  },
  removeButton: { position: 'absolute', top: 0, right: 0, padding: '0.1em' },
}));
