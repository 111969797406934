import { Box, Button, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';
import { Table } from '../../../components/table/Table';
import { MobileTable } from '../../../components/table/MobileTable';
import { IColumn } from '../../../models/util';
import { EditLocationAlt } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ILegalEntityList } from '../../../models/legal-entitites';

interface ILegalEntities {
  handleEdit: (legalEntityId: number | string) => void;
  legalEntities: ILegalEntityList[];
  setLegalEntities: (legalEntities: ILegalEntityList[]) => void;
  isLoading: boolean;
}
export const LegalEntities: FC<ILegalEntities> = ({
  handleEdit,
  legalEntities,
  setLegalEntities,
  isLoading,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const classes = useStyles();

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'States',
        accessor: 'states',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILegalEntityList } };
        }) => {
          const stateCodes = original.states.map(state => state.code);
          return <span>{stateCodes.join(', ')}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        sort: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILegalEntityList } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original?.legalEntityId);
                }}
              >
                Edit
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntities]);

  return (
    <Table
      columns={columns}
      data={legalEntities}
      hidePagination
      isLoading={isLoading}
      ResponsiveComponent={isMobile ? MobileTable : undefined}
      mobileProps={{
        fields: columns
          .filter(col => col.Header)
          .map(col => ({
            name: col.Header,
            accessor: col.accessor,
            Cell: col?.Cell,
          })),
        handleEdit: (legalEntity: ILegalEntityList) => {
          handleEdit(legalEntity?.legalEntityId);
        },
      }}
    />
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
