import { FC, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { IDropdownResponse } from '../../models/util';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { getCancelationReasons } from '../../fetch/lookups';
import { cancelShiftRequest } from '../../fetch';
import { useSnackbar } from 'notistack';
import { Close, Check } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';

interface ICancelRequestModal {
  open: boolean;
  onClose: () => void;
  shiftRequestId?: number;
  fetchPendingRequests: () => {};
}

const Schema = Yup.object().shape({
  reason: Yup.string().required('Required'),
});

export const CancelRequestModal: FC<ICancelRequestModal> = ({
  open,
  onClose,
  shiftRequestId,
  fetchPendingRequests,
}) => {
  const [isLoadingReasons, setLoadingReasons] = useState(false);
  const [reasons, setReasons] = useState<IDropdownResponse[]>([]);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fetchCancelationReasons = async () => {
    setLoadingReasons(true);
    try {
      const res = await getCancelationReasons();
      setReasons(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading Cancelation Reasons, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setLoadingReasons(false);
    }
  };

  useEffect(() => {
    fetchCancelationReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          reason: '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            shiftRequestId: shiftRequestId ?? 0,
            reason: values.reason ?? '',
          };
          try {
            await cancelShiftRequest(data?.shiftRequestId, data?.reason);
            fetchPendingRequests();
            onClose();
            actions.resetForm();
            enqueueSnackbar(`Requests updated!`, {
              variant: 'success',
            });
          } catch (error: any) {
            const errorMessage = error?.response?.data?.Detail;
            enqueueSnackbar(errorMessage || `Error updating this request, please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                onClose();
              }}
              maxWidth="md"
            >
              <Form onSubmit={handleSubmit} autoComplete="none">
                <div>
                  <Typography variant="h5">Please select a reason for cancelation:</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormControl fullWidth required={true} variant="standard" size="small">
                          <InputLabel htmlFor="reasonsLabel">Reason</InputLabel>
                          <Select
                            className={classes.reasonSelector}
                            fullWidth
                            name="reasons"
                            labelId="reasonsLabel"
                            id="reasons"
                            disabled={isLoadingReasons}
                            error={errors && errors.reason ? true : false}
                            value={values.reason}
                            onChange={e => {
                              setFieldValue('reason', e.target.value as string);
                            }}
                          >
                            {reasons?.map((reason: IDropdownResponse, index) => {
                              return (
                                <MenuItem key={`${index + 1}`} value={reason.value}>
                                  {reason.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {touched?.reason && errors?.reason && (
                            <FormHelperText error>Required</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!values.reason || isSubmitting}
                    type="submit"
                    startIcon={<Check />}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    startIcon={<Close />}
                    onClick={() => {
                      //@ts-ignore
                      if (!deepEqual(initialValues, values)) {
                        const result = window.confirm(
                          'You have unsaved changes, are you sure you want to exit?'
                        );
                        if (result) {
                          resetForm();
                          onClose();
                        } else {
                          return;
                        }
                      } else {
                        resetForm();
                        onClose();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  reasonSelector: {
    cursor: 'pointer',
  },
}));
