import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { FC, useState, useEffect, useMemo } from 'react';
import { Loader, Page } from '../../components';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn, IDropdownResponse, sortable } from '../../models/util';
import { IUserInfo } from '../../models/user';
import { getUser, getUsers } from '../../fetch';
import { formatShortFriendlyDateWithTime } from '../../helpers';
import { EditLocationAlt } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AddEditUserModal } from './add-edit-user-modal';
import { getAppRoles } from '../../fetch';
import { IAppRole, IAppUser } from '../../models';
import { getLocationLookup } from '../../fetch/lookups';
import { UserFilters } from './user-filters';

const STATUSES = [
  { description: 'Active', shorthand: 'Active', value: 'true' },
  { description: 'Inactive', shorthand: 'Inactive', value: 'false' },
];
export const Users: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUserInfo[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [selectedSort, setSelectedSort] = useState<string>('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState<{
    FirstName?: sortable;
    LastName?: sortable;
    Email?: sortable;
    Status?: sortable;
    LastLogin?: sortable;
  }>({
    FirstName: 'Asc',
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [appRoles, setAppRoles] = useState<IAppRole[] | null>(null);
  const [areAppRolesLoading, setAreAppRolesLoading] = useState(false);

  const [searchValue, setSearchValue] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [filters, setFilters] = useState<{
    isActive?: string;
  }>({});

  const fetchAppRoles = async () => {
    setAreAppRolesLoading(true);
    try {
      const res = await getAppRoles();
      setAppRoles(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading app roles, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreAppRolesLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const res = await getUsers({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
        searchText: searchValue,
        ...filters,
      });
      setUsers(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading users, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [locations, setLocations] = useState<IDropdownResponse[] | null>(null);
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);

  const fetchLocations = async () => {
    try {
      setAreLocationsLoading(true);
      const res = await getLocationLookup();
      setLocations(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreLocationsLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection, filters]);

  useEffect(() => {
    fetchAppRoles();
    fetchLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const [currentUser, setCurrentUser] = useState<IUserInfo | undefined>(undefined);
  const handleEdit = (user: IUserInfo) => {
    setCurrentUser(user);
    user.azureOID ? setIsModalOpen(true) : setIsModalOpen(false);
  };

  const [userRoles, setUserRoles] = useState<IAppUser | null>(null);
  const [isLoadingUserRoles, setisLoadingUserRoles] = useState(false);
  const fetchUser = async () => {
    try {
      setisLoadingUserRoles(true);
      const res = await getUser(currentUser?.userId as number);

      setUserRoles(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;

      setIsModalOpen(false);
      enqueueSnackbar(errorMessage || `Error loading users, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setisLoadingUserRoles(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'First Name',
        accessor: 'firstName',
        isServerSorted: selectedSort === 'FirstName',
        isServerSortedDesc: sortDirection.FirstName === 'Desc',
        handleClickColumn: () => handleClickColumn('FirstName'),
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        isServerSorted: selectedSort === 'LastName',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('LastName'),
      },
      {
        Header: 'Status',
        accessor: 'status',
        isServerSorted: selectedSort === 'Status',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('Status'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IUserInfo } };
        }) => {
          return <span>{original.isActive === true ? 'Active' : 'Inactive'}</span>;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        isServerSorted: selectedSort === 'Email',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('Email'),
      },
      {
        Header: 'Last Login',
        accessor: 'lastLoginDate',
        isServerSorted: selectedSort === 'LastLogin',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('LastLogin'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IUserInfo } };
        }) => {
          return <span>{formatShortFriendlyDateWithTime(original.lastLoginDate)}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IUserInfo } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<EditLocationAlt />}
                disabled={original.azureOID ? false : true}
                onClick={() => {
                  handleEdit(original);
                }}
              >
                Edit
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  return (
    <Page title={'Users'}>
      {isLoading && <Loader type="fullscreen" position="centered" title="Loading..." />}
      <UserFilters
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        applyFilters={(clearFilters?: boolean) => {
          if (clearFilters) {
            setFilters({});
            setSearchValue('');
          } else {
            setPage(0);
            setFilters({
              ...filters,
              isActive: status ?? undefined,
            });
          }
        }}
        setHasAppliedFilters={setHasAppliedFilters}
        handleSearch={(val: string) => {
          setPage(0);
          setSearchValue(val);
        }}
        isLoading={isLoading}
        selectedStatus={status}
        setSelectedStatus={setStatus}
        hasAppliedFilters={hasAppliedFilters}
        statuses={STATUSES}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={users}
            isLoading={isLoading}
            serverPage={page}
            serverRecordCount={recordCount}
            serverPerPage={perPage}
            handlePage={setPage}
            handleRowsPerPage={setRowsPerPage}
            ResponsiveComponent={isMobile ? MobileTable : undefined}
            mobileProps={{
              fields: columns
                .filter(col => col.Header)
                .map(col => ({
                  name: col.Header,
                  accessor: col.accessor,
                  Cell: col?.Cell,
                })),
              handleEdit,
            }}
          />
        </Grid>
      </Grid>
      <AddEditUserModal
        currentUser={currentUser}
        open={isModalOpen && !areLocationsLoading && !isLoadingUserRoles && !areAppRolesLoading}
        onClose={() => {
          setIsModalOpen(false);
          setCurrentUser(undefined);
        }}
        fetchUsers={() => fetchUsers()}
        appRoles={appRoles}
        locations={locations}
        // @ts-ignore
        userRoles={userRoles?.roles ?? []}
        // @ts-ignore
        userLocations={
          userRoles?.locations?.map(userLocation =>
            locations?.find(
              (location: IDropdownResponse) => +location.value === userLocation.locationId
            )
          ) ?? []
        }
      />
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
