import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { mapStyles } from './mapStyles';
import { IMapLocation } from '../../models/maps';
import { CustomInfoWindow } from './customInfoWindow';
import { useCurrentHeight } from '../../helpers/resize';
import { useMediaQuery } from '@mui/material';

interface ILatLng {
  lat: number;
  lng: number;
}
interface Props {
  locations: IMapLocation[];
  isMapLoaded: () => void;
  isMapLoading: boolean;
  showOverlay: (locationId: number) => void;
  handleInfoWindowClose: () => void;
  setInfoWindow: (locationId: number | null) => void;
  infoWindow: number | null;
  center: ILatLng;
  setCenter: React.Dispatch<React.SetStateAction<ILatLng>>;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}

export const Map = (props: Props) => {
  const windowHeight = useCurrentHeight();
  const mapContainerHeight = windowHeight - windowHeight * 0.3 + 'px';

  const { center, setCenter, zoom, setZoom } = props;
  const isMobile = useMediaQuery('(max-width: 960px)');
  return (
    <GoogleMap
      onTilesLoaded={() => {
        props.isMapLoaded();
      }}
      mapContainerStyle={{ height: mapContainerHeight }}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: !isMobile,
        styles: mapStyles,
        gestureHandling: 'greedy',
        zoom: zoom,
        center: center,
      }}
    >
      <MarkerClusterer
        // https://nooshu.com/blog/2012/10/03/marker-cluster-calculator-for-google-maps-v3/
        calculator={function (markers, numStyles) {
          const totalShiftsInCluster = markers.reduce(
            (prev, curr) => prev + parseInt(curr.getLabel() as string, 10),
            0
          );
          //create an index for icon styles
          let index = 0,
            //Count the total number of markers in this cluster
            count = markers.length,
            //Set total to loop through (starts at total number)
            total = count;
          /**
           * While we still have markers, divide by a set number and
           * increase the index. Cluster moves up to a new style.
           *
           * The bigger the index, the more markers the cluster contains,
           * so the bigger the cluster.
           */
          while (total !== 0) {
            //Create a new total by dividing by a set number
            total = parseInt(`${total / 5}`, 10);
            //Increase the index and move up to the next style
            index++;
          }

          /**
           * Make sure we always return a valid index. E.g. If we only have
           * 5 styles, but the index is 8, this will make sure we return
           * 5. Returning an index of 8 wouldn't have a marker style.
           */
          index = Math.min(index, numStyles);

          //Tell MarkerCluster this clusters details (and how to style it)
          return {
            text: `${totalShiftsInCluster}`,
            index: index,
          };
        }}
      >
        {clusterer => (
          <>
            {props.locations.map(location => {
              return (
                <Marker
                  key={location.locationId}
                  label={`${location.shiftCount}`}
                  position={{ lat: location.latitude, lng: location.longitude }}
                  clusterer={clusterer}
                  onClick={() => {
                    setCenter({
                      lat: location.latitude - 0.0039,
                      lng: location.longitude + 0.0042,
                    });
                    setZoom(15);
                    props.setInfoWindow(location.locationId);
                    props.showOverlay(location.locationId);
                  }}
                >
                  {props.infoWindow === location.locationId && (
                    <CustomInfoWindow
                      key={location.locationId}
                      onCloseClick={() => {
                        props.setInfoWindow(null);
                        props.handleInfoWindowClose();
                      }}
                      location={location}
                      lat={location.latitude}
                      lng={location.longitude}
                    />
                  )}
                </Marker>
              );
            })}
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
};
