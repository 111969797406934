import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import { Box, Button, Fade, Grid, TextField } from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { startEmailChange } from '../../fetch';

interface IDvmChangeEmailModal {
  open: boolean;
  onClose: () => void;
  dvmId: string;
  refetch: () => void;
}

const Schema = Yup.object().shape({
  newEmail: Yup.string().email('Invalid email').nullable(),
});

export const DvmChangeEmailModal: FC<IDvmChangeEmailModal> = ({
  open,
  onClose,
  dvmId,
  refetch,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ newEmail: '' }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          await startEmailChange(dvmId, values.newEmail);
          actions.resetForm();
          onClose();
          refetch();
        } catch (error: any) {
          const errorMessage = error?.response?.data?.Detail;
          enqueueSnackbar(errorMessage || `Error requesting a change of email, please try again.`, {
            variant: 'error',
          });
          console.log(error);
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
        resetForm,
      }) => {
        return (
          <Modal
            open={open}
            onClose={() => {
              resetForm();
              onClose();
            }}
            maxWidth="md"
            title="Change Email Address"
            titleContent={'Change Email Address'}
          >
            <Fade in={open}>
              <Form onSubmit={handleSubmit} autoComplete="none">
                <div className={classes.content}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        label={'Email'}
                        name="newEmail"
                        value={values.newEmail}
                        onBlur={handleBlur}
                        size="small"
                        onChange={e => {
                          setFieldValue('newEmail', e.target.value);
                        }}
                        inputProps={{ 'data-lpignore': 'true' }}
                        error={touched.newEmail && errors.newEmail ? true : false}
                        helperText={touched.newEmail && errors.newEmail}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!dirty || isSubmitting || !isValid}
                    type="submit"
                    startIcon={<Edit />}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    startIcon={<Close />}
                    onClick={() => {
                      //@ts-ignore
                      if (!deepEqual(initialValues, values)) {
                        const result = window.confirm(
                          'You have unsaved changes, are you sure you want to exit?'
                        );
                        if (result) {
                          resetForm();
                          onClose();
                        } else {
                          return;
                        }
                      } else {
                        resetForm();
                        onClose();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
