import Axios, { Method } from 'axios';

import { logError, logInfo } from '../services';
let buildConfig = require('../buildSettings');
// uncomment for hitting staging
// let buildConfig = require('../buildSettings.stage.json');

const axiosInstance = Axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/api/`,
});

const axiosRequest = async (
  method: Method,
  url: string,
  data: object | null,
  options: object,
  params?: any
) => {
  logInfo(null, `axios.js request started: ${url}`);
  try {
    return await axiosInstance({
      method,
      url,
      ...(data ? { data } : {}),
      ...options,
      params,
    });
  } catch (err) {
    logError(err, 'axios.js request failed');
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (url: string, params?: {}, options = {}) => {
    return axiosRequest('get', url, null, options, params);
  },
  post: async (url: string, data = {}, options = {}) => {
    return axiosRequest('post', url, data, { ...options });
  },
  put: async (url: string, data = {}, options = {}) => {
    return axiosRequest('put', url, data, { ...options });
  },
  delete: async (url: string, options = {}) => {
    return axiosRequest('delete', url, null, { ...options });
  },
};
