import { FC, useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import { Autocomplete, Box, Button, Fade, Grid, TextField, Typography } from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { IAppRole, IUserInfo } from '../../models';
import { updateUserRoles } from '../../fetch';
import { IDropdownResponse } from '../../models/util';

interface IAddEditUserModal {
  open: boolean;
  onClose: () => void;
  currentUser?: IUserInfo;
  fetchUsers: () => {};
  appRoles: IAppRole[] | null;
  userRoles: string[];
  locations: IDropdownResponse[] | null;
  userLocations: any[] | undefined;
}

export const AddEditUserModal: FC<IAddEditUserModal> = ({
  open,
  onClose,
  currentUser,
  fetchUsers,
  appRoles,
  userRoles,
  userLocations,
  locations,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isPracticeManager, setIsPracticeManager] = useState(
    userRoles
      .map(userRole => appRoles?.find(appRole => appRole.id === userRole))
      .map(role => role?.name)
      .includes('Practice Manager')
  );

  useEffect(() => {
    setIsPracticeManager(
      userRoles
        .map(userRole => appRoles?.find(appRole => appRole.id === userRole))
        .map(role => role?.name)
        .includes('Practice Manager')
    );
  }, [appRoles, userRoles]);

  const Schema = Yup.object().shape({
    roles: Yup.array()
      .of(Yup.object({ name: Yup.string(), id: Yup.string() }))
      .required('Required'),
    locations: isPracticeManager
      ? Yup.array().of(Yup.object()).required('At least 1 Location is Required').min(1)
      : Yup.object().notRequired().nullable(),
  });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          roles: userRoles.map(userRole => appRoles?.find(appRole => appRole.id === userRole)),
          locations: userLocations ?? [],
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          if (values.roles?.length > 1 && values.roles?.some(role => role?.name === 'DVM'))
          {
            enqueueSnackbar(`If user is a DVM they may not be assigned any other role.`, {
              variant: 'error',
            });
          }
          else {
            try {
              if (values.roles) {
                await updateUserRoles(
                  currentUser?.userId as number,
                  values.roles.map(role => role?.id),
                  !isPracticeManager
                    ? []
                    : values.locations.map(location => {
                        return { locationId: location?.value, name: location?.description };
                      })
                );
              }
              enqueueSnackbar(`Updated user!`, { variant: 'success' });
              fetchUsers();
              onClose();
            } catch (e) {
              enqueueSnackbar(`Error updating users.`, { variant: 'error' });
            }
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          errors,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                onClose();
              }}
              maxWidth="md"
            >
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <div>
                    <Typography variant="h5">
                      {!!currentUser
                        ? `Edit ${currentUser?.firstName} ${currentUser?.lastName}`
                        : `Add New User`}
                    </Typography>
                    <Grid item xs={12}>
                      <Autocomplete
                        value={values.roles as IAppRole[]}
                        multiple
                        fullWidth
                        size={'small'}
                        onChange={(event, newValue: IAppRole[]) => {
                          if (newValue && newValue.length > 0) {
                            setFieldValue('roles', newValue);
                            const isPracticeManager = newValue
                              .map(userRole =>
                                appRoles?.find(appRole => appRole.id === userRole.id)
                              )
                              .map(role => role?.name)
                              .includes('Practice Manager');
                            setIsPracticeManager(isPracticeManager);
                            !isPracticeManager && setFieldValue('locations', []);
                          } else {
                            setFieldValue('roles', []);
                          }
                        }}
                        id="roles"
                        options={appRoles ?? []}
                        disabled={false}
                        getOptionLabel={(option: IAppRole) => {
                          return option.name;
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            name={'Roles'}
                            autoComplete="none"
                            inputProps={{
                              ...params.inputProps,
                              maxLength: 150,
                            }}
                            onBlur={handleBlur}
                            label="Roles"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {isPracticeManager && (
                      <Grid item xs={12}>
                        <Autocomplete
                          value={values.locations as any[]}
                          multiple
                          fullWidth
                          size={'small'}
                          onChange={(event, newValue: any[]) => {
                            if (newValue && newValue.length > 0) {
                              setFieldValue('locations', newValue);
                            } else {
                              setFieldValue('locations', []);
                            }
                          }}
                          id="locations"
                          options={locations ?? []}
                          disabled={false}
                          getOptionLabel={(option: any) => {
                            return option?.description ? option?.description : option?.name;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              name={'Locations'}
                              autoComplete="none"
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 150,
                              }}
                              onBlur={handleBlur}
                              label="Locations"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </div>
                  <Box marginTop="1rem">
                    <Button
                      className={classes.button}
                      disabled={!dirty || isSubmitting || !isValid}
                      type="submit"
                      startIcon={<Edit />}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting}
                      startIcon={<Close />}
                      onClick={() => {
                        //@ts-ignore
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm(
                            'You have unsaved changes, are you sure you want to exit?'
                          );
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          resetForm();
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
