import { differenceInSeconds, format, parseISO } from 'date-fns';

export const phoneRegExp = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
export const passwordRegex = new RegExp(
  /^(?=.*[A-Z])(?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])\S{10,20}$/
);

export const formatInputPhoneNumber = (val: string | undefined) => {
  let number = val?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // taken from here, https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  return (
    number &&
    (!number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`)
  );
};

/**
 * Formats a string or number into USD
 * @example formatMoney(1) => $1.00
 * @example formatMoney(0) => $0.00
 * @example formatMoney('1.99') => $1.99
 * @example formatMoney('9999.99') => $9,999.99
 * @example formatMoney(undefined) => $0.00
 * @example formatMoney(null) => $0.00
 * @param value number | string | undefined | null
 * @param digits number | undefined
 * @returns string
 */
export const formatMoney = (value: number | string | undefined | null, digits = 2): string => {
  let amount = 0;

  if (value) {
    if (typeof value === 'string') {
      // strip out any commas or dollar signs so that $9,999.99 passes !isNaN test
      value =
        value.includes(',') || value.includes('$')
          ? value.replace(',', '').replace('$', '')
          : value;
      // make sure the string is a number
      if (!isNaN(value as unknown as number)) {
        amount = Number(value);
      }
    } else if (typeof value === 'number' && value > 0) {
      amount = value;
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

/**
 * Format date string to Date
 * @example "2020-06-09T00:00:00+00:00" => Tue Jun 09 2020 00:00:00 GMT-0400
 * @param date string
 * @returns Date
 */
export const stringToDate = (date: string): Date => {
  const d = date.split('T')[0].split('-');
  // Months are 0 index so subtract 1
  return new Date(+d[0], +d[1] - 1, +d[2]);
};

/**
 * Format date
 * @example 1/6/2020
 * @example 12/12/2020
 * @param date Date | number | string
 * @returns string
 */
export const formatDate = (date: Date | number | string | null): string | null => {
  if (date) {
    const stringDate: Date | number = typeof date === 'string' ? stringToDate(date) : date;
    return format(stringDate, 'L/d/yy');
  }
  return null;
};

/**
 * Format date
 * @example 1/6/2020
 * @example 12/12/2020
 * @param date Date | number | string
 * @returns string
 */
export const formatDateWithDay = (date: Date | number | string | null): string | null => {
  if (date) {
    const stringDate: Date | number = typeof date === 'string' ? stringToDate(date) : date;
    return format(stringDate, 'L/d/yy eee');
  }
  return null;
};

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDateWithTime = (date: Date | number | string): string | null => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yy h:mm a');
  }
  return null;
};

export const formatUtcToLocalShortFriendlyDateWithTime = (date: Date | number | string): string | null => {
  if (date) {
  const updatedDate = new Date(date + 'Z');
  return updatedDate.toLocaleString();
  }
  return null;
}

/**
 * Format date into a short friendly date with time and seconds
 * @example 1/6/2020 2:00:00pm
 * @example 12/12/2020 12:00:00AM
 * @param date Date | number | string
 * @returns string
 */
export const formatDateWithTimeAndSeconds = (date: Date | number | string): string | null => {
  if (date) {
    const parsedDate: Date | number = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, 'L/d/yy h:mm:ssa');
  }
  return null;
};

/**
 * return the difference in seconds between two date objects
 * @param date Date | number | string
 * @returns string
 */
export const getDifferenceBetweenTwoDatesInSeconds = (
  date1: Date | number | string,
  date2: Date | number | string
): number | null => {
  if (date1 && date2) {
    const parsedDate1: Date | number = typeof date1 === 'string' ? parseISO(date1) : date1;
    const parsedDate2: Date | number = typeof date2 === 'string' ? parseISO(date2) : date2;
    return differenceInSeconds(parsedDate2, parsedDate1);
  }
  return null;
};

export const formatArrayParams = (data: string[], key: string) => {
  return `${data.map((id, index) => `${index !== 0 ? '&' : ''}${key}=${id}`).join('')}`;
};

/** @example `Shopping cart contains ${items.length} item${s(items.length)}.` */
export const checkArrayLengthReturnS = (arr: unknown[]): string => (arr.length > 1 ? 's' : '');

export const groupByKey = (array: any[], key: string) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
  }, {});
};

export const formatPascalStringToSpaces = (data: string) => {
  const regx = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
  return data.replace(regx, '$1$4 $2$3$5');
};

export const DECIMAL_REGEX = /^\d*(\.)?(\d{0,4})?$/;

export const unescape = (string: string) => {
  return new DOMParser()?.parseFromString(string, 'text/html')?.querySelector('html')?.textContent;
};

// Calling val.toFixed(2) drops decimals down and creates a string
// representation of the number. If we then parse out the string into a float
// and call .toString() on that we can remove any 0s padded on at the end. I.e.
// '16.00` becomes '16'. Take that final string value and parse it back to a
// float and return.
export const trimDecimals = (val: number, decimals = 2): number =>
  parseFloat(parseFloat(val.toFixed(decimals)).toString());

// format percent and ensure at least 1 decimal place even if it is .0%
export const formatPercent = (val: number): string => {
  let percent = `${trimDecimals(parseFloat((val * 100).toString()))}`;
  if (percent.indexOf('.') === -1) {
    return `${percent}.0%`;
  }
  return `${percent}%`;
};

export const formatWholeNumberPercent = (val: number): string => {
  let percent = `${trimDecimals(parseFloat((val * 100).toString()))}`;
  return `${percent}%`;
};

export const dateFormatter = (val: string | number | null | undefined) => {
  if (val === null || val === undefined) {
    return val;
  }

  // allow for shortcuts
  const today = new Date();
  const newVal = new Date();

  if (!isNaN(val as number)) {
    // convert dates like 0428 to 04/28/YYYY
    if (val.toString().length === 4) {
      const month = val.toString().slice(0, 2);
      const day = val.toString().slice(2, 4);
      val = `${month}/${day}/${today.getFullYear()}`;
    } else {
      val = newVal.setDate(today.getDate() + Number(val));
    }
  } else if ((val as string).split('/').length === 2) {
    // apply a year since we can assume it wasn't provided
    today.getFullYear();
    val = new Date(val).setFullYear(today.getFullYear());
  }

  return format(new Date(val), 'MM/dd/yyyy');
};

// Gets the localized date, without time
export const getLocalDate = (date: string) => format(new Date(date), 'P');

// Gets the localized date and time
export const getLocalDateTime = (dateTime: string) => format(new Date(dateTime), 'Pp');
