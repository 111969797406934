import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { format, startOfWeek, addDays, isSameDay, lastDayOfWeek, startOfDay } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import '../../styles/react-calendar-grid.css'; // theme css file
import { IDropdownResponse } from '../../models/util';
import { Alert, alpha, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  IMarketCalendarLocation,
  IMarketCalendarShiftRequest,
  IShift,
} from '../../models/schedule';
import { InfoModal } from './info-modal';
import { getDvmRequestsAndShifts } from '../../fetch';
import { DVMPeekInfo } from '../../models';
import { RSSAddEditModal } from './rss-add-modal';
import { CalendarEvent } from './calendar-event';

interface ICalendarGrid {
  locations: IDropdownResponse[];
  selectedLocation: string;
  selectedDvm?: IDropdownResponse | null;
  handleDateClick: (day: Date) => void;
  handleRemoveClick: (day: Date, locationId?: string | number) => void;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  scheduled: IShift[] | IMarketCalendarLocation[];
  isMarket?: boolean;
  selectedDVMSchedule?: string[];
  allowDvmCardDelete?: boolean;
  handleApprove?: (request: IMarketCalendarShiftRequest) => void;
  handleReject?: (request: IMarketCalendarShiftRequest) => void;
  handleReinstate?: (request: IMarketCalendarShiftRequest) => void;
  checkedRequests?: IMarketCalendarShiftRequest[];
  reinstatedRequests?: IMarketCalendarShiftRequest[];
  rejectedRequests?: IMarketCalendarShiftRequest[];
  fetchSchedule?: () => Promise<void>;
  openDate?: Date | null;
  closeDate?: Date | null;
  reloadPayload?: () => void;
  showInactiveRequests?: boolean;
}

// based off of this component, https://codesandbox.io/s/xmt6r?file=/package.json
export const CalendarGrid: FC<ICalendarGrid> = ({
  locations,
  selectedLocation,
  selectedDvm,
  handleDateClick,
  handleRemoveClick,
  selectedDate,
  setSelectedDate,
  scheduled,
  isMarket = false,
  selectedDVMSchedule,
  allowDvmCardDelete = false,
  handleApprove,
  handleReject,
  handleReinstate,
  checkedRequests,
  reinstatedRequests,
  rejectedRequests,
  fetchSchedule,
  openDate,
  closeDate,
  reloadPayload,
  showInactiveRequests,
}) => {
  const location = locations.find(loc => loc.value === selectedLocation) ?? {
    description: '',
    shorthand: '',
    value: '',
  };

  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState(selectedDate);

  useEffect(() => {
    setCurrentMonth(selectedDate);
    reloadPayload?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onDateClickHandle = (day: Date) => {
    handleDateClick(day);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, setInfo] = useState<DVMPeekInfo | null>(null);

  const [isRSSAddModalOpen, setIsRSSAddModalOpen] = useState(false);
  const [RSSModalSelectedShift, setRSSModalSelectedShift] = useState<IShift | null>(null);
  const [RSSModalSelectedShiftRequest, setRSSModalSelectedShiftRequest] =
    useState<IMarketCalendarShiftRequest | null>(null);

  useEffect(() => {
    if (RSSModalSelectedShift || RSSModalSelectedShiftRequest) {
      setIsRSSAddModalOpen(true);
    }
  }, [RSSModalSelectedShift, RSSModalSelectedShiftRequest]);

  const [rssAddLocation, setRssAddLocation] = useState<IMarketCalendarLocation | null>(null);
  const [rssAddDate, setRssAddDate] = useState<Date | null>(null);
  const [rssIncentive, setRssIncentive] = useState<number | null>(null);

  const handleModalOpen = async (dvmId: number, mondayDate: Date) => {
    try {
      const res = await getDvmRequestsAndShifts(dvmId, format(mondayDate, 'yyyy-MM-dd'));
      setInfo(res);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const renderDays = () => {
    const dateFormatDay = 'MMM d';

    const dateFormat = 'EEE';
    const days = [
      <div className={clsx('col col-center', classes.heading)} key={'days-empty'}>
        Location
      </div>,
    ];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(
          <div className={clsx('col col-center', classes.heading, scheduled)} key={i}>
            {format(addDays(startDate, i), dateFormat)}
            <Typography className={classes.headerDate}>
              {format(addDays(startDate, i), dateFormatDay)}
            </Typography>
          </div>
        );
        day = addDays(day, 1);
      }
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = (
    location: string | IMarketCalendarLocation,
    onSchedule?: IMarketCalendarLocation[],
    openDate?: Date | null,
    closeDate?: Date | null
  ) => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const rows = [];
    let days = [
      <div className={clsx('col cell', classes.locationContainer)} key={'empty'}>
        <Typography>{typeof location === 'string' ? location : location.locationName}</Typography>
      </div>,
    ];
    let day = startOfDay(startDate);

    const isSelected = (day: Date, selectedDate: Date) =>
      isSameDay(day, selectedDate) ? 'selected' : '';

    const requested = isMarket ? (location as IMarketCalendarLocation)?.shiftRequests : [];
    const incentives = isMarket ? (location as IMarketCalendarLocation)?.incentiveRates : [];

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;

        const onCalendar = isMarket
          ? (location as IMarketCalendarLocation)?.shifts?.find(
              s => new Date(s.shiftDate).getTime() === cloneDay.getTime() && !s.isDeleted
            )
          : (scheduled as IShift[]).find(
              s => new Date(s.shiftDate).getTime() === cloneDay.getTime() && !s.isDeleted
            );

        const thisDaysRequests = requested?.[i] ?? [];
        const thisDaysIncentive = incentives?.[i] ?? null;

        const isScheduled = onCalendar?.shiftId;
        const isRelief = onCalendar?.dvmType === 'Contractor';
        const isPenciledIn = onCalendar && onCalendar.shiftId === null;

        const dayString = String(day);
        const dateObj = new Date(Date.parse(dayString));
        const isoDateString = dateObj.toISOString();

        let isBeforeOpenDate = false;
        let isAfterCloseDate = false;

        if (openDate && isoDateString < String(openDate)) {
          isBeforeOpenDate = true;
        }
        if (closeDate && isoDateString > String(closeDate)) {
          isAfterCloseDate = true;
        }

        const dateHasRecentCancelation = isMarket
          ? (location as IMarketCalendarLocation)?.datesWithRecentCancelations
              ?.map(dateTime => new Date(dateTime).getTime())
              ?.includes(new Date(cloneDay).getTime()) && !isScheduled
          : undefined;

        const determineClass = (event: IShift) => {
          if (!!onCalendar && !isBeforeOpenDate && !isAfterCloseDate) {
            if (isScheduled) {
              if (isRelief) {
                return classes.scheduledRelief;
              } else {
                return classes.scheduled;
              }
            } else if (isPenciledIn) {
              if (isRelief) {
                return classes.penciledInRelief;
              } else {
                return classes.penciledIn;
              }
            } else {
              return '';
            }
          } else {
            return '';
          }
        };

        days.push(
          <CalendarEvent
            isSameDay={isSameDay}
            isSelected={isSelected}
            isMarket={isMarket}
            determineClass={determineClass}
            isBeforeOpenDate={isBeforeOpenDate}
            isAfterCloseDate={isAfterCloseDate}
            onCalendar={onCalendar}
            location={location}
            day={day}
            allowDvmCardDelete={allowDvmCardDelete}
            handleModalOpen={handleModalOpen}
            setRSSModalSelectedShift={setRSSModalSelectedShift}
            setRssAddLocation={setRssAddLocation}
            setRssAddDate={setRssAddDate}
            setIsRSSAddModalOpen={setIsRSSAddModalOpen}
            setRssIncentive={setRssIncentive}
            cloneDay={cloneDay}
            selectedDate={selectedDate}
            startDate={startDate}
            isRelief={isRelief}
            selectedDvm={selectedDvm}
            selectedDVMSchedule={selectedDVMSchedule}
            handleRemoveClick={handleRemoveClick}
            onDateClickHandle={onDateClickHandle}
            handleApprove={handleApprove}
            handleReject={handleReject}
            handleReinstate={handleReinstate}
            checkedRequests={checkedRequests}
            reinstatedRequests={reinstatedRequests}
            rejectedRequests={rejectedRequests}
            onSchedule={onSchedule}
            thisDaysRequests={thisDaysRequests}
            thisDaysIncentive={thisDaysIncentive}
            fetchSchedule={fetchSchedule}
            dateHasRecentCancelation={dateHasRecentCancelation}
            showInactiveRequests={showInactiveRequests}
          />
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day.toDateString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return <div className="header row flex-middle">{/* Footer Content */}</div>;
  };
  return isMarket && (scheduled as IMarketCalendarLocation[]).length === 0 ? (
    <Alert>No locations exist for this market</Alert>
  ) : (
    <div className="calendar">
      {renderDays()}
      {isMarket
        ? (scheduled as IMarketCalendarLocation[]).map(location =>
            renderCells(
              location,
              scheduled as IMarketCalendarLocation[],
              location.openDate,
              location.closeDate
            )
          )
        : renderCells(location.shorthand, undefined, openDate, closeDate)}
      {renderFooter()}
      <InfoModal isOpen={isModalOpen && !!info} onClose={() => setIsModalOpen(false)} info={info} />
      <RSSAddEditModal
        isOpen={isRSSAddModalOpen}
        onClose={() => {
          setIsRSSAddModalOpen(false);
          setRSSModalSelectedShift(null);
          setRSSModalSelectedShiftRequest(null);
          setRssAddDate(null);
          setRssIncentive(null);
          setRssAddLocation(null);
        }}
        fetchSchedule={() => fetchSchedule?.()}
        date={rssAddDate as Date}
        shift={RSSModalSelectedShift ?? undefined}
        scheduledShift={RSSModalSelectedShiftRequest ?? undefined}
        location={rssAddLocation as IMarketCalendarLocation}
        thisDaysIncentive={rssIncentive as number}
      />
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  locationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    color: alpha(theme.palette.text.primary, 0.875),
    padding: '.5rem',
  },
  dayContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  penciledIn: {
    borderTop: `3px solid ${theme.palette.secondary.light}`,
    borderRight: `3px solid ${theme.palette.secondary.light} !important`,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
  },
  penciledInRelief: {
    borderTop: `3px solid ${theme.palette.primary.light}`,
    borderRight: `3px solid ${theme.palette.primary.light} !important`,
    borderBottom: `3px solid ${theme.palette.primary.light}`,
    borderLeft: `3px solid ${theme.palette.primary.light}`,
  },
  closed: {
    height: '80%',
    width: '80%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),

    borderTop: `3px solid ${theme.palette.grey[300]}`,
    borderRight: `3px solid ${theme.palette.grey[300]} !important`,
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(1, 0.25, 0),
    alignSelf: 'flex-start',
  },
  addButtonWithRequests: {
    opacity: '0',

    transition: 'opacity .25s ease-out',
    position: 'absolute',
    bottom: -7,
    right: -7,
  },
  bidContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    transition: 'opacity .15s ease-in',

    '&:hover > $addButtonWithRequests': {
      opacity: '1',
    },
  },
  scheduled: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    justifyContent: 'space-around',
  },
  scheduledName: {
    textDecoration: 'underline',
  },
  checkDisabled: {
    backgroundColor: theme.palette.secondary.light,
  },
  scheduledRelief: {
    color: theme.palette.common.white,
    backgroundColor: '#4c5076',
    justifyContent: 'space-around',
  },

  headerDate: {
    color: alpha(theme.palette.text.primary, 0.875),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
  },
  heading: {
    borderRight: ` 1px solid #eee`,
  },
  removeButton: { position: 'absolute', top: 0, left: 0 },
  '.calendar': {
    '& .body': {
      '& .cell': {
        '& .remove': {},
      },
    },
  },
  noBids: {
    alignSelf: 'center',
  },
  link: {
    color: theme.palette.common.white,
  },
}));
