import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useSyncState } from '../helpers';

interface ILoad {
  set(key: string): void;
  isLoading(keys?: string[]): boolean;
  remove(key: string): void;
}

const LoadContext = createContext<ILoad>(null as unknown as ILoad);

/**
 * Designed so that multiple loader states don't have to be instantiated inside of each component.
 * This is designed to simplify the following scenario:
 * const [isLoadingThis, setIsLoadingThis] = useState<boolean>(false);
 * const [isLoadingThat, setIsLoadingThat] = useState<boolean>(false);
 * const [isLoadingTheOtherThing, setIsLoadingTheOtherThing] = useState<boolean>(false);
 *
 * Instead, you'll have a single variable that is stored in local state, is available to all components,
 * and manages all of your various loading elements like so:
 *
 * const load = useLoadState();
 *
 * const apiCall1 = async () => {
 *  load.set('apiCall1');
 *  await apiCall1();
 *  load.remove('apiCall1');
 * }
 *
 * const apiCall2 = async () => {
 *  load.set('apiCall2');
 *  await apiCall2();
 *  load.remove('apiCall2');
 * }
 *
 * <Loader isLoading={load.isLoading()} />
 *
 * Or check load state based on your specific key(s) (1-n keys)
 * <Loader isLoading={load.isLoading([ 'apiCall1', 'apiCall2', 'etc' ])} />
 */

export const useLoadState = (): ILoad => useContext(LoadContext);

export const LoadProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const loadKeys: { set: (newValue: string[]) => void; get: () => string[] } = useSyncState([]);

  const set: ILoad['set'] = useCallback((key: string) => {
    loadKeys?.set([ ...loadKeys?.get(), key ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadKeys]);

  const isLoading: ILoad['isLoading'] = useCallback((keys?: string[]) => {
    if (keys?.length) {
      return loadKeys?.get()?.some((loadKey: string) => keys?.includes(loadKey));
    } else {
      return !!loadKeys?.get()?.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadKeys]);

  const remove: ILoad['remove'] = useCallback((key: string) => {
    loadKeys?.set(loadKeys?.get()?.filter((loadKey: string) => loadKey === key))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadKeys]);

  const load: ILoad = useMemo(
    () => ({ set, isLoading, remove }),
    [set, isLoading, remove]
  );

  return (
    <LoadContext.Provider value={load}>
      {children}
    </LoadContext.Provider>
  );
};
