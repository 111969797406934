import { FC, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Autocomplete,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Loader, Modal } from '../../components';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { getDVMWithW2Reserves, updateDVMWithW2Reserves } from '../../fetch/w2-payment-reserve';
import { ICurrentW2PaymentReserve, IDVMPaymentReserves } from '../../models';
import { getW2DVMs } from '../../fetch/lookups';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { NumericFormatProps, NumericFormat } from 'react-number-format';
import { isAfter, startOfToday } from 'date-fns';

interface IW2DVMsModal {
  open: boolean;
  onClose: () => void;
  fetchW2DVMs: () => void;
  currentDVMId: string | number;
  isEdit: boolean;
}

const Schema = Yup.object().shape({
  name: Yup.object().required('W2 DVM is Required'),
  w2PaymentReserves: Yup.array()
    .of(
      Yup.object({
        reserve: Yup.number()
          .typeError('Reserve Percentage is Required')
          .min(0, 'Value must be at least 0%')
          .max(100, 'Value must be less than 100%')
          .required('Reserve Percentage is Required'),
        effectiveDate: Yup.date()
          .typeError('Effective Date is invalid')
          .required('Effective Date is Required'),
      })
    )
    .required('At least 1 Location is Required')
    .min(1),
});

export const W2DVMsModal: FC<IW2DVMsModal> = ({
  open,
  onClose,
  fetchW2DVMs,
  currentDVMId,
  isEdit,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [DVMPaymentReserves, setDVMPaymentReserves] = useState<IDVMPaymentReserves>();

  const fetchDVMW2PaymentReserves = async () => {
    try {
      setIsLoading(true);
      const res = await getDVMWithW2Reserves(currentDVMId);
      setDVMPaymentReserves(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVM with W2 Reserves, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [areDVMsLoading, setAreDVMsLoading] = useState(true);
  const [DVMOptions, setDVMOptions] = useState<any[]>([]);
  const fetchW2DVMOptions = async () => {
    try {
      setAreDVMsLoading(true);
      const res = await getW2DVMs();
      setDVMOptions(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading W2 DVMs please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreDVMsLoading(false);
    }
  };

  useEffect(() => {
    if (currentDVMId) {
      fetchDVMW2PaymentReserves();
    }

    fetchW2DVMOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentDVMId]);

  const today = startOfToday();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: DVMOptions.find(option => option.description === DVMPaymentReserves?.name) ?? '',
        w2PaymentReserves:
          DVMPaymentReserves?.w2PaymentReserves?.map(reserve => {
            if (reserve?.w2PaymentReserveId) {
              // Old Item
              return {
                w2PaymentReserveId: reserve.w2PaymentReserveId,
                reserve: reserve.reserve * 100,
                effectiveDate: reserve.effectiveDate,
                isDeleted: false,
              };
            } else {
              // New Item
              return {
                reserve: reserve.reserve * 100,
                effectiveDate: reserve.effectiveDate,
                isDeleted: false,
              };
            }
          }) ?? [],
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        const data = {
          w2PaymentReserves: values.w2PaymentReserves
            ?.map(reserve => {
              if (reserve?.w2PaymentReserveId) {
                return {
                  w2PaymentReserveId: reserve.w2PaymentReserveId ?? null,
                  reserve: reserve.reserve / 100,
                  effectiveDate: reserve.effectiveDate,
                  isDeleted: reserve?.isDeleted,
                };
              } else {
                return {
                  reserve: reserve.reserve / 100,
                  effectiveDate: reserve.effectiveDate,
                  isDeleted: reserve?.isDeleted,
                };
              }
            })
            ?.filter(reserve => {
              if (!reserve?.w2PaymentReserveId) {
                return !reserve.isDeleted;
              }
              return true;
            }) as any[],
        };
        try {
          await updateDVMWithW2Reserves(values.name.value, data);
          fetchW2DVMs();
          enqueueSnackbar('W2 DVM Saved!', {
            variant: 'success',
          });
          actions.resetForm();
          onClose();
          setDVMPaymentReserves(undefined);
        } catch (error: any) {
          const errorMessage = error?.response?.data?.Detail;
          enqueueSnackbar(errorMessage || `Error saving W2 DVM, please try again.`, {
            variant: 'error',
          });
          console.log(error);
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
        resetForm,
      }) => {
        return (
          <Modal
            open={open}
            onClose={() => {
              resetForm();
              onClose();
              setDVMPaymentReserves(undefined);
            }}
            maxWidth="md"
          >
            <Fade in={open}>
              <Form onSubmit={handleSubmit} autoComplete="none">
                {isLoading || isSubmitting ? (
                  <Loader type="inline" position="centered" title="Loading..." />
                ) : (
                  <>
                    {currentDVMId ? (
                      <Typography variant={'h5'}>Edit {DVMPaymentReserves?.name}</Typography>
                    ) : (
                      <Autocomplete
                        value={values.name}
                        onChange={(event, newValue: any) => {
                          setFieldValue('name', newValue);
                        }}
                        disabled={areDVMsLoading}
                        selectOnFocus
                        handleHomeEndKeys
                        loading={areDVMsLoading}
                        id="w2DVMs"
                        options={DVMOptions}
                        getOptionLabel={(option: any) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          return `${option?.description} (${option?.shorthand})`;
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            key={params.id}
                            size="small"
                            autoComplete="on"
                            label="W2 DVMs"
                            variant="standard"
                            name={'name'}
                            onBlur={handleBlur}
                            error={!!(touched?.name && errors?.name)}
                            helperText={touched?.name && errors?.name}
                          />
                        )}
                      />
                    )}
                    <FieldArray
                      name="w2PaymentReserves"
                      render={arrayHelpers => (
                        <Grid>
                          <Box className={classes.contentContainer}>
                            {values.w2PaymentReserves.map((reserve, index) => {
                              const reservePercentage = `w2PaymentReserves[${index}].reserve`;
                              const effectiveDate = `w2PaymentReserves[${index}].effectiveDate`;
                              const isDeleted = `w2PaymentReserves[${index}].isDeleted`;

                              const reserveName = `w2PaymentReserves[${index}].reserve`;
                              const effectiveDateName = `w2PaymentReserves[${index}].effectiveDate`;
                              const touchedRate = touched?.w2PaymentReserves?.[index];
                              const errorRate = errors?.w2PaymentReserves?.[
                                index
                              ] as FormikErrors<ICurrentW2PaymentReserve>;
                              const isNew = reserve?.w2PaymentReserveId === undefined;
                              // If it was saved, it is now disabled. The user can delete it if it's in the future.
                              const isDisabled = !!reserve?.w2PaymentReserveId;
                              return (
                                <Grid
                                  key={`${index}`}
                                  container
                                  item
                                  xs={12}
                                  spacing={0}
                                  alignItems={'flex-start'}
                                  columnSpacing={0}
                                  rowSpacing={0}
                                  style={{ display: reserve?.isDeleted ? 'none' : 'flex' }}
                                >
                                  <Grid item xs={12} sm={5}>
                                    <FormControl
                                      fullWidth
                                      variant="outlined"
                                      margin="dense"
                                      size="small"
                                    >
                                      <TextField
                                        fullWidth
                                        required
                                        name={reserveName}
                                        margin="dense"
                                        variant="outlined"
                                        autoComplete="nope"
                                        label="Reserve Percentage"
                                        value={reserve.reserve}
                                        onBlur={handleBlur}
                                        size="small"
                                        error={!!(touchedRate?.reserve && errorRate?.reserve)}
                                        disabled={isDisabled}
                                        helperText={touchedRate?.reserve && errorRate?.reserve}
                                        InputProps={{
                                          inputComponent: NumericFormatCustom as any,
                                        }}
                                        onChange={e => {
                                          const value = e.target.value ?? 0;
                                          setFieldValue(reservePercentage, value);
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={5}>
                                    <FormControl
                                      fullWidth
                                      variant="outlined"
                                      margin="dense"
                                      size="small"
                                    >
                                      <DatePicker
                                        label="Effective Date"
                                        inputFormat="MM/dd/yyyy"
                                        minDate={today}
                                        value={reserve.effectiveDate ?? null}
                                        onChange={val => setFieldValue(effectiveDate, val)}
                                        disabled={isDisabled}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            name={effectiveDateName}
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                              !!(
                                                touchedRate?.effectiveDate &&
                                                errorRate?.effectiveDate
                                              )
                                            }
                                            helperText={
                                              touchedRate?.effectiveDate && errorRate?.effectiveDate
                                            }
                                            inputProps={{
                                              ...params.inputProps,
                                              readOnly: isDisabled,
                                            }}
                                            disabled={isDisabled}
                                            required
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid className={classes.removeButton} item xs={12} sm={2}>
                                    <IconButton
                                      aria-label="remove payment reserve"
                                      disabled={isAfter(today, new Date(reserve.effectiveDate))}
                                      onClick={() => {
                                        setFieldValue(isDeleted, true);
                                        if (isNew) {
                                          arrayHelpers.remove(index);
                                        }
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Box>
                          <Box marginTop="1rem">
                            <Button
                              className={classes.button}
                              disabled={!dirty || isSubmitting || !isValid}
                              type="submit"
                              startIcon={<Edit />}
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                            <Button
                              className={classes.button}
                              type="button"
                              variant="contained"
                              color="inherit"
                              disabled={isSubmitting}
                              startIcon={<Close />}
                              onClick={() => {
                                //@ts-ignore
                                if (!deepEqual(initialValues, values)) {
                                  const result = window.confirm(
                                    'You have unsaved changes, are you sure you want to exit?'
                                  );
                                  if (result) {
                                    resetForm();
                                    onClose();
                                    setDVMPaymentReserves(undefined);
                                  } else {
                                    return;
                                  }
                                } else {
                                  resetForm();
                                  onClose();
                                  setDVMPaymentReserves(undefined);
                                }
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.button}
                              startIcon={<Edit />}
                              variant="contained"
                              color="primary"
                              disabled={!isValid && values.w2PaymentReserves.length > 0}
                              onClick={() =>
                                arrayHelpers.push({
                                  reserve: 30,
                                  effectiveDate: '',
                                  isDeleted: false,
                                })
                              }
                            >
                              Add New Rate
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    />
                  </>
                )}
              </Form>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  contentContainer: {
    maxHeight: 500,
    overflowY: 'auto',
  },
  removeButton: {
    alignSelf: 'center',
    padding: '0 8px',
  },
}));
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        allowNegative={false}
        decimalScale={0}
        suffix="%"
      />
    );
  }
);
