import { Typography } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { alpha, Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

interface ILoaderExtended {
  loaderWrapperClassName?: string;
  position?: 'centered' | 'left' | 'top-center';
  size?: 'small' | 'medium' | 'large';
  subtitle?: string;
  title?: string;
  topOffset?: number;
  type?: 'fullscreen' | 'inline' | 'overlay';
}

type ILoader = ILoaderExtended & Omit<CircularProgressProps, 'variant'>;

/**
 * A presentational CircularProgress indicator.
 * 
 * @usage
 * ```TypeScript
 * const Component: FC = () => {
 *   const [isLoading, setIsLoading] = useState(true);
 *   const [resources, setResources] = useState<Resource[]>([]);
 *   useEffect(() => {
 *     fetchResource().then(x => setResource(x)).finally(() => setIsLoading(false));
 *   }, []);
 *   if (isLoading) return <Loader title="Loading xyz data..." />;
 *   return <div> ... </div>;
 * };
 * ```
 */
export const Loader: FC<ILoader> = ({
  children,
  loaderWrapperClassName = '',
  subtitle,
  title = 'Loading...',
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <div
      className={clsx(classes.loader, loaderWrapperClassName)}
      data-testid={props.id}
      role="alert"
    >
      <div className={classes.inner}>
        <CircularProgress
          {...props}
          className={clsx(classes.progress, props.className)}
          size={props.size === 'large' ? 18 : props.size === 'small' ? 10 : 14}
          variant="indeterminate"
        />
        <div className={classes.text}>
          {children}
          {!children && (
            <>
              <Typography className={classes.title} variant="body1">
                {title}
              </Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant="subtitle1">
                  {subtitle}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, ILoader>((theme: Theme) => {
  return createStyles({
    inner: {
      alignItems: 'center',
      display: 'flex',
      flexShrink: 1,
      flexWrap: 'wrap',
      justifyContent: props =>
        props.position === 'top-center'
          ? 'center'
          : props.position === 'centered'
          ? 'center'
          : 'flex-start',
      marginTop: props => props.topOffset && props.topOffset + '%',
      width: '100%',
    },
    loader: {
      alignItems: props => (props.position === 'centered' ? 'center' : 'flex-start'),
      backgroundColor: props =>
        props.type === 'fullscreen' || props.type === 'overlay'
          ? alpha(theme.palette.background.paper, 0.75)
          : '',
      bottom: 0,
      display: 'flex',
      fontSize: props =>
        props.size === 'large' ? '1.25rem' : props.size === 'small' ? '0.875rem' : '1rem',
      height: props => (props.position === 'centered' ? '100%' : 'auto'),
      left: 0,
      position: props =>
        props.type === 'fullscreen' ? 'fixed' : props.type === 'overlay' ? 'absolute' : 'static',
      right: 0,
      top: 0,
      width: '100%',
      zIndex: theme.zIndex.tooltip + 1,
    },
    progress: {
      color: theme.palette.primary.main,
      margin: theme.spacing(0, 1, 0, 0),
    },
    subtitle: {
      color: theme.palette.grey[800],
      fontSize: props =>
        props.size === 'large' ? '0.875rem' : props.size === 'small' ? '0.625rem' : '0.75rem',
      margin: theme.spacing(0.25, 0, 0),
      textTransform: 'uppercase',
    },
    text: {
      maxWidth: 230, // this is needed for long subtitles (to force that text to wrap)
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: props =>
        props.size === 'large' ? '1.25rem' : props.size === 'small' ? '0.875rem' : '1rem',
      margin: 0,
    },
  });
});
