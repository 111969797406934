import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useMemo, useState } from 'react';
import { Loader, Page } from '../../components';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn } from '../../models/util';
import { getPendingPayments, updatePendingPaymentStatus } from '../../fetch/payments';
import { IPendingPayment } from '../../models/payments';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { Check, Clear } from '@mui/icons-material';

export const PendingPayments: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [pendingPayments, setPendingPayments] = useState<IPendingPayment[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const fetchPendingPayments = async () => {
    try {
      setIsLoading(true);
      const res = await getPendingPayments();
      setPendingPayments(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading pending payments, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApproval = async (original: IPendingPayment) => {
    const data = {
      pendingPayment: {
        shiftId: original.shiftId,
        paymentStatus: original.paymentStatus === 'Approved' ? 'Rejected' : 'Approved',
      },
    };
    try {
      const pendingPaymentRow = await updatePendingPaymentStatus(data);
      setPendingPayments((prev: IPendingPayment[]) => {
        return prev.map(pendingPayment =>
          pendingPayment.shiftId === pendingPaymentRow.shiftId
            ? { ...pendingPayment, ...pendingPaymentRow }
            : pendingPayment
        );
      });
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error updating this Pending Payment, please try again.`, {
        variant: 'error',
      });
    }
  };

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Date',
        accessor: 'shiftDate',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Scheduled DVM',
        accessor: 'dvmName',
      },
      {
        Header: 'Status',
        accessor: 'paymentStatus',
      },
      {
        Header: 'Override By',
        accessor: 'overrideBy',
      },
      {
        Header: 'Override Date',
        accessor: 'overrideDateTime',
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IPendingPayment } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={clsx(
                  classes.button,
                  original.paymentStatus === 'Approved'
                    ? classes.rejectButtonDesktop
                    : classes.approvalButtonDesktop
                )}
                color="primary"
                onClick={() => handleApproval(original)}
              >
                {original.paymentStatus === 'Approved' ? <Clear /> : <Check />}
                {original.paymentStatus === 'Approved' ? 'Reject' : 'ReApprove'}
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={'Pending Payments'}>
      {isLoading && <Loader type="fullscreen" position="centered" title="Loading..." />}

      <Grid container className={classes.pendingPaymentsGrid} spacing={3}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={pendingPayments}
            hidePagination
            isLoading={isLoading}
            ResponsiveComponent={isMobile ? MobileTable : undefined}
            stickyHeader={true}
            containerClasses={'pending-payments-container'}
            mobileProps={{
              fields: columns
                .filter((col: { Header: any }) => col.Header)
                .map(col => ({
                  name: col.Header,
                  accessor: col.accessor,
                  Cell: col?.Cell,
                })),
              mobileAccordionLabel: 'dvmName',
              handleApproval: async (original: IPendingPayment) => {
                await handleApproval(original);
              },
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  pendingPaymentsGrid: {
    '& .pending-payments-container': {
      height: '80vh',
    },
  },
  rejectButtonDesktop: {
    width: '100%',
    maxWidth: '125px',
    '& svg': {
      width: '.75em',
      height: '.75em',
      marginRight: '6px',
    },
  },
  approvalButtonDesktop: {
    width: '100%',
    maxWidth: '125px',
    backgroundColor: theme.palette.success.main,
    '& svg': {
      width: '.75em',
      height: '.75em',
      marginRight: '6px',
    },
  },
}));
