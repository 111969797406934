import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { FC, useState, useEffect, useMemo } from 'react';
import { Loader, Page } from '../../components';
import { getDvms } from '../../fetch/dvms';
import { getDvmTypes } from '../../fetch/lookups';
import { getDvmStatuses } from '../../fetch/lookups';
import { IDVMTable } from '../../models';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn, IDropdownResponse, sortable } from '../../models/util';
import { Add, EditLocationAlt } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { DVMFilters } from './dvm-filters';
import { getDVMMarkets, getStates } from '../../fetch/markets';
import { AddContractorModal } from './dvm-add-contractor-modal';

export const Dvms: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dvms, setDvms] = useState<IDVMTable[]>([]);

  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [selectedSort, setSelectedSort] = useState<string>('1');
  const [sortDirection, setSortDirection] = useState<{
    FirstName?: sortable;
    LastName?: sortable;
    Status?: sortable;
    Type?: sortable;
    PrimaryMarket?: sortable;
  }>({
    FirstName: 'Asc',
  });

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // Filters
  const [selectedStateId, setSelectedStateId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [selectedDvmType, setSelectedDvmType] = useState('');
  const [selectedDvmStatus, setSelectedDvmStatus] = useState([
    {
      value: 2,
      description: 'Active',
      shorthand: 'Active',
    },
    {
      value: 1,
      description: 'Pending',
      shorthand: 'Pending',
    },
  ]);

  const [dvmTypes, setDvmTypes] = useState<IDropdownResponse[]>([]);
  const [dvmStatuses, setDvmStatuses] = useState<IDropdownResponse[]>([]);
  const [areDvmTypesLoading, setAreDvmTypesLoading] = useState(false);
  const [areDvmStatusesLoading, setAreDvmStatusesLoading] = useState(false);
  const [states, setStates] = useState<IDropdownResponse[]>([]);
  const [areStatesLoading, setAreStatesLoading] = useState(false);

  const [isAddContractorModalOpen, setIsAddContractorModalOpen] = useState(false);

  const [filters, setFilters] = useState<{
    stateId?: string;
    dvmType?: string;
    PrimaryMarketId?: string;
    dvmStatuses?: string[];
  }>({});

  const fetchTypes = async () => {
    setAreDvmTypesLoading(true);
    try {
      const res = await getDvmTypes();
      setDvmTypes(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading types, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreDvmTypesLoading(false);
    }
  };

  const fetchStatuses = async () => {
    setAreDvmStatusesLoading(true);
    try {
      const res = await getDvmStatuses();
      setDvmStatuses(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading statuses, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreDvmStatusesLoading(false);
    }
  };

  const fetchStates = async () => {
    setAreStatesLoading(true);
    try {
      const res = await getStates();
      setStates(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading states, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreStatesLoading(false);
    }
  };

  const [areMarketsLoading, setAreMarketsLoading] = useState(true);
  const [markets, setMarkets] = useState<IDropdownResponse[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>('');

  const fetchMarkets = async () => {
    setAreMarketsLoading(true);
    try {
      const res = await getDVMMarkets();
      setMarkets(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreMarketsLoading(false);
    }
  };
  useEffect(() => {
    fetchTypes();
    fetchStatuses();
    fetchStates();
    fetchMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (dvmId: number | string) => {
    history.push(`dvms/${dvmId}`);
  };

  const fetchDvms = async () => {
    try {
      const mappedStatuses = selectedDvmStatus?.map(s => s.description);
      console.log('mapped statuses', mappedStatuses);
      setIsLoading(true);
      const res = await getDvms({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
        DVMName: searchValue,
        dvmStatuses: mappedStatuses || undefined,
        ...filters,
      });
      setDvms(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVMs, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDvms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection, filters]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'First Name',
        accessor: 'firstName',
        isServerSorted: selectedSort === 'FirstName',
        isServerSortedDesc: sortDirection.FirstName === 'Desc',
        handleClickColumn: () => handleClickColumn('FirstName'),
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        isServerSorted: selectedSort === 'LastName',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('LastName'),
      },
      {
        Header: 'Email',
        accessor: 'email',
        isServerSorted: selectedSort === 'email',
        isServerSortedDesc: sortDirection.LastName === 'Desc',
        handleClickColumn: () => handleClickColumn('email'),
      },
      {
        Header: 'Status',
        accessor: 'status',
        isServerSorted: selectedSort === 'Status',
        isServerSortedDesc: sortDirection.Status === 'Desc',
        handleClickColumn: () => handleClickColumn('Status'),
      },
      {
        Header: 'Type',
        accessor: 'type',
        isServerSorted: selectedSort === 'Type',
        isServerSortedDesc: sortDirection.Type === 'Desc',
        handleClickColumn: () => handleClickColumn('Type'),
      },
      {
        Header: 'Market',
        accessor: 'primaryMarket',
        isServerSorted: selectedSort === 'PrimaryMarket',
        isServerSortedDesc: sortDirection.PrimaryMarket === 'Desc',
        handleClickColumn: () => handleClickColumn('PrimaryMarket'),
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IDVMTable } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original.dvmId);
                }}
              >
                Edit
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  return (
    <Page
      title={'DVMs'}
      additionalHeaderContent={
        <>
          <Button
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setIsAddContractorModalOpen(true);
            }}
          >
            ADD
          </Button>
        </>
      }
    >
      {isLoading && <Loader type="fullscreen" position="centered" title="Loading..." />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DVMFilters
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            applyFilters={(clearFilters?: boolean) => {
              if (clearFilters) {
                setFilters({});
                setSearchValue('');
              } else {
                setPage(0);
                setFilters({
                  ...filters,
                  dvmStatuses: selectedDvmStatus?.map(s => s.description) || undefined,
                  dvmType: selectedDvmType || undefined,
                  stateId: selectedStateId || undefined,
                  PrimaryMarketId: selectedMarket || undefined,
                });
              }
            }}
            setHasAppliedFilters={setHasAppliedFilters}
            handleSearch={(val: string) => {
              setPage(0);
              setSearchValue(val);
            }}
            isLoading={
              isLoading ||
              areDvmTypesLoading ||
              areDvmStatusesLoading ||
              areStatesLoading ||
              areMarketsLoading
            }
            types={dvmTypes}
            selectedType={selectedDvmType}
            setSelectedType={setSelectedDvmType}
            statuses={dvmStatuses}
            selectedStatuses={selectedDvmStatus}
            setSelectedStatuses={setSelectedDvmStatus}
            states={states}
            selectedState={selectedStateId}
            setSelectedState={setSelectedStateId}
            hasAppliedFilters={hasAppliedFilters}
            markets={markets}
            selectedMarket={selectedMarket}
            setSelectedMarket={setSelectedMarket}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={dvms}
            isLoading={isLoading}
            serverPage={page}
            serverRecordCount={recordCount}
            serverPerPage={perPage}
            handlePage={setPage}
            handleRowsPerPage={setRowsPerPage}
            ResponsiveComponent={isMobile ? MobileTable : undefined}
            mobileProps={{
              fields: columns
                .filter(col => col.Header)
                .map(col => ({
                  name: col.Header,
                  accessor: col.accessor,
                  Cell: col?.Cell,
                })),
              handleEdit: (dvm: IDVMTable) => {
                handleEdit(dvm.dvmId);
              },
            }}
          />
        </Grid>
      </Grid>
      <AddContractorModal
        isOpen={isAddContractorModalOpen}
        onClose={() => setIsAddContractorModalOpen(false)}
      />
    </Page>
  );
};
