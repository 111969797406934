import React, { FC, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  alpha,
  Box,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import clsx from 'clsx';
import { IRoute, Paths } from '../../constants';

export interface ISideNavProps {
  open: boolean;
  navItems: IRoute[];
  isMobile: boolean;
  setShowNav: (b: boolean) => void;
  width: number;
  headerHeight: number;
}

export const SideNav: FC<ISideNavProps> = ({
  headerHeight,
  open,
  navItems,
  isMobile,
  setShowNav,
  width,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const classes = useStyles({ width });

  const isSelected = useCallback(
    (route: string) => pathname?.split('/')[1] === route?.split('/')[1],
    [pathname]
  );

  const handleNavItemClick = (route: typeof Paths[keyof typeof Paths]) => {
    // Automatically close the menu on nav selection for mobile views

    if (isMobile) {
      setShowNav(false);
    }
    history.push(route);
  };

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      classes={{ paper: classes.drawer }}
      open={(isMobile && open) || !isMobile}
    >
      {/* HEADER SPACER */}
      <Toolbar sx={{ height: headerHeight }} />

      {/* NAV CONTAINER */}
      <Box className={classes.navContainer}>
        <List sx={{ paddingTop: '1px' }}>
          {navItems.map(item => (
            <ListItemButton
              classes={{ root: classes.navItem, selected: classes.listItemSelected }}
              className={isSelected(item.path) ? classes.navLinkActive : classes.navLink}
              key={item.sortOrder}
              onClick={() => handleNavItemClick(item.path)}
              selected={isSelected(item.path)}
              role={"listitem"}
              component={"li"}
              title={item.label}
            >
              {/* NAV ICON */}
              <ListItemIcon
                className={isSelected(item.path) ? classes.navIconSelected : classes.navIcon}
              >
                <Icon component={item.icon} />
              </ListItemIcon>

              {/* NAV TEXT */}
              <ListItemText
                primary={item.label}
                className={clsx(classes.showLabel, {
                  [classes.hideLabel]: !open,
                })}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles<Theme, { width: number }>(theme => ({
  drawer: {
    backgroundColor: theme.palette.secondary.main,
  },
  listItemSelected: {
    '&.selected': { backgroundColor: 'none' },
  },
  navContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    fontSize: '24px',
    width: ({ width }) => width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  showLabel: {
    opacity: 1,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hideLabel: {
    opacity: 0,
  },
  navLink: {
    fill: theme.palette.common.white,
    color: alpha(theme.palette.common.white, 0.4),
    '& button': {
      fill: alpha(theme.palette.common.white, 0.4),
    },
    '& button svg': {
      fill: alpha(theme.palette.common.white, 0.4),
    },
  },
  navLinkActive: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    fill: theme.palette.common.white,
    backgroundColor: 'none',
    color: alpha(theme.palette.common.white, 1),
    '& button': {
      fill: alpha(theme.palette.common.white, 1),
    },
    '& button svg': {
      fill: alpha(theme.palette.common.white, 1),
    },

    selected: {
      backgroundColor: 'none',
    },
  },
  navItem: {
    color: alpha(theme.palette.common.white, 0.4),

    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      fill: alpha(theme.palette.common.white, 1),
    },
  },
  navIcon: {
    '& svg': {
      fill: alpha(theme.palette.common.white, 0.4),
    },
  },
  navIconSelected: {
    '& svg': {
      fill: alpha(theme.palette.common.white, 1),
    },
  },
}));
