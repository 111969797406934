import React, { FC } from 'react';
import { Page } from '../../components';
import { Grid, Link } from '@mui/material';

export const NotFound: FC = () => {
  return (
    <Page title={'Something went wrong'}>
      <Grid container spacing={3}>
        <Grid item>
          The page you requested was either not found or is inaccessible. Please return to the home
          page and try again. If you continue to experience problems, contact{' '}
          <Link href="mailto:relief@vetcher.com">
            relief@vetcher.com
          </Link>
        </Grid>
      </Grid>
    </Page>
  );
};
