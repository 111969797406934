import { authFetch } from './fetch';
import { logError } from '../services';
import { IDVMPaymentReserves, IDVMPaymentReservesUpdatePayload, IW2PaymentReserves } from '../models';
import { IResponse } from '../models/util';
const baseUrl = '/admin/W2PaymentReserves';

export const getDVMsWithW2Reserves = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  name?: string;
}): Promise<IResponse<IW2PaymentReserves[]>> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}`, filters);
    return data;
  } catch (error) {
    logError(error, 'getDVMsWithW2Reserves failed');
    return Promise.reject(error);
  }
};


export const getDVMWithW2Reserves = async (dvmId: string | number): Promise<IDVMPaymentReserves> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/dvm/${dvmId}`);
    return data;
  } catch (error) {
    logError(error, 'getDVMWithW2Reserves failed');
    return Promise.reject(error);
  }
};


export const updateDVMWithW2Reserves = async (dvmId: string | number, payload: IDVMPaymentReservesUpdatePayload ): Promise<any> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}/dvm/${dvmId}`, payload);
    return data;
  } catch (error) {
    logError(error, 'updateDVMWithW2Reserves failed');
    return Promise.reject(error);
  }
};
