import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
// Components
import {
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Checkbox,
  Box,
} from '@mui/material';
import { ExpandMore, Edit, Delete, Download, Clear, Check } from '@mui/icons-material';
import { resolveObjectField } from '../../helpers';

interface IMobileTable {
  original: any;
  handleEdit?: (val: any) => void;
  handleDelete?: (val: any) => void;
  handleDownload?: (val: any) => void;
  handleApproval?: (val: any) => void;
  handleCheckboxComplete?: (val: any) => void;
  handleAccordionLabel?: (val: any) => void;
  fields: {
    name: string;
    accessor: string;
    Cell?: (value: { cell: { row: { original: Record<string, unknown> } } }) => JSX.Element;
  }[];
  mobileAccordionLabel?: string;
}

export const MobileTable: FC<IMobileTable> = ({
  handleDownload,
  handleApproval,
  handleCheckboxComplete,
  handleEdit,
  handleDelete,
  fields,
  original,
  mobileAccordionLabel,
}) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        className={classes.accordion}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.topPanelSummaryWrapper}>
          <Typography color="primary" className={classes.boldName}>
            {mobileAccordionLabel
              ? original[
                  fields.find(field => {
                    return field.accessor === mobileAccordionLabel;
                  })?.accessor ?? 0
                ]
              : original[fields[0].accessor]}
          </Typography>
          <div className={classes.buttonsWrapper}>
            {handleDownload && (
              <IconButton
                className={classes.editButton}
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  handleDownload(original);
                }}
                size="large"
              >
                <Download />
              </IconButton>
            )}
            {handleEdit && (
              <IconButton
                className={classes.editButton}
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  handleEdit(original);
                }}
                size="large"
              >
                <Edit />
              </IconButton>
            )}
            {handleDelete && (
              <IconButton
                className={classes.deleteButton}
                color="default"
                onClick={e => {
                  e.stopPropagation();
                  handleDelete(original);
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            )}
            {handleApproval && (
              <IconButton
                className={
                  original.paymentStatus === 'Approved'
                    ? classes.rejectButton
                    : classes.approvalButton
                }
                color="default"
                onClick={e => {
                  e.stopPropagation();
                  handleApproval(original);
                }}
                size="large"
              >
                {original.paymentStatus === 'Approved' ? <Clear /> : <Check />}
              </IconButton>
            )}
            {handleCheckboxComplete && (
              <Box display="flex" alignItems="center" className={classes.checkboxWrapper}>
                <Typography className={classes.checkboxLabel}>Mark as read</Typography>
                <Checkbox
                  checked={original.cancelationAcknowledged === true}
                  id="orders-checkbox"
                  onChange={e => {
                    e.stopPropagation();
                    handleCheckboxComplete(original);
                  }}
                />
              </Box>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {fields.map((field: typeof fields[0], index) => {
          return (
            <div key={`${index}`} className={classes.panelSummaryWrapper}>
              <FormLabel component="span" className={clsx(classes.boldName, classes.subLabelEmail)}>
                {field?.name?.length > 1 ? `${field.name}:` : field.name}
              </FormLabel>
              <Typography component="span">
                {/* TODO clean this up */}
                {typeof field.accessor === 'string' && typeof field.Cell !== 'function'
                  ? !resolveObjectField(field.accessor, original)
                    ? '--'
                    : resolveObjectField(field.accessor, original)
                  : typeof field.Cell === 'function'
                  ? ''
                  : '--'}
                {typeof field.Cell === 'function'
                  ? field.Cell({ cell: { row: { original } } })
                  : null}
              </Typography>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflowX: 'hidden',
  },
  accordion: {
    padding: '0 16px',
    '&& .MuiAccordionSummary-expandIcon': {
      padding: 3,
    },
  },
  topPanelSummaryWrapper: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '&:first-child': { marginTop: 0 },
  },
  panelSummaryWrapper: {
    '&:first-child': { marginTop: 0 },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey[100],
  },
  editButton: {
    padding: `2px 5px`,
  },
  deleteButton: {
    padding: `2px 5px`,
    color: theme.palette.error.main,
  },
  approvalButton: {
    padding: `2px`,
    marginRight: '6px',
    backgroundColor: theme.palette.success.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: '#ffffff',
    },
    '& svg': {
      width: '.75em',
      height: '.75em',
    },
  },
  rejectButton: {
    padding: `2px`,
    marginRight: '6px',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': { backgroundColor: theme.palette.error.main, color: '#ffffff' },
    '& svg': {
      width: '.75em',
      height: '.75em',
    },
  },
  boldName: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  subLabelEmail: {
    marginRight: 10,
  },
  buttonsWrapper: {
    display: 'flex',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  checkboxLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));
