import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  Typography,
  Autocomplete,
  Alert,
} from '@mui/material';
import React, { FC, useState, useEffect, useContext } from 'react';
import { Loader } from '../../components';
import { IDVM } from '../../models';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  AssignmentInd,
  Close,
  ContactMail,
  Edit,
  School,
  HomeRepairService,
  CheckCircle,
  Email,
} from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { Formik, Form } from 'formik';
import { Redirect, useHistory, useParams, Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IDropdownResponse } from '../../models/util';
import { updateDvm, updateLimitedDvm, getScheduledShiftCount } from '../../fetch/dvms';

import {
  getDvmSchools,
  getDvmStatuses,
  getDvmTypes,
  getMarkets,
  getStates,
} from '../../fetch/lookups';
import { formatInputPhoneNumber, phoneRegExp } from '../../helpers';
import { UserContext } from '../../context';
import { LicenseInfo } from './license-info';
import { InsuranceInfo } from './insurance-info';
import { createLicense, updateLicense } from '../../fetch/license';
import { createInsurance } from '../../fetch/insurance';
import { IconHeading } from '../../components/icon-heading';
import { DvmChangeEmailModal } from './dvm-update-email';

export interface IDVMProfile {
  dvm: IDVM;
  isLoading: boolean;
  getDVM: () => Promise<void>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DvmProfile: FC<IDVMProfile> = ({ dvm, isLoading, getDVM, setIsDirty }) => {
  const [areStatusesLoading, setAreStatusesLoading] = useState(false);
  const [statuses, setStatuses] = useState<IDropdownResponse[]>([]);
  const [areTypesLoading, setAreTypesLoading] = useState(false);
  const [types, setTypes] = useState<IDropdownResponse[]>([]);
  const [areSchoolsLoading, setAreSchoolsLoading] = useState(false);
  const [schools, setSchools] = useState<IDropdownResponse[]>([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { dvmId }: { dvmId: string } = useParams();
  const { user, isDVM, isSysAdmin, isRSS, isDVMEmployee } = useContext(UserContext);
  const dvmProfileView = isDVM && !dvmId;

  const fetchStatuses = async () => {
    setAreStatusesLoading(true);
    try {
      setAreStatusesLoading(true);
      const res = await getDvmStatuses();
      setStatuses(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVM Statuses, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreStatusesLoading(false);
    }
  };

  const fetchTypes = async () => {
    setAreTypesLoading(true);
    try {
      setAreTypesLoading(true);
      const res = await getDvmTypes();
      setTypes(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading DVM Types, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreTypesLoading(false);
    }
  };

  const [areMarketsLoading, setAreMarketsLoading] = useState(true);
  const [markets, setMarkets] = useState<IDropdownResponse[]>([]);

  const fetchMarkets = async () => {
    setAreMarketsLoading(true);
    try {
      const res = await getMarkets();
      setMarkets(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreMarketsLoading(false);
    }
  };

  const fetchSchools = async () => {
    setAreSchoolsLoading(true);
    try {
      const res = await getDvmSchools();
      setSchools(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading schools, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreSchoolsLoading(false);
    }
  };

  const [areStatesLoading, setAreStatesLoading] = useState(true);
  const [states, setStates] = useState<IDropdownResponse[]>([]);
  const fetchStates = async () => {
    setAreStatesLoading(true);
    try {
      const res = await getStates();
      setStates(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading states, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreStatesLoading(false);
    }
  };

  useEffect(() => {
    fetchStatuses();
    fetchTypes();
    fetchSchools();
    fetchMarkets();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Schema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    pendingEmailAddress: Yup.string().email('Invalid email').nullable(),
    type: Yup.number(),
    status: Yup.number(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string().length(5),
    phoneNumber: Yup.string().matches(phoneRegExp, {
      excludeEmptyString: true,
      message: 'Invalid phone number',
    }),
    schoolId: Yup.number().nullable().notRequired(),
    vendorId: Yup.string().nullable().notRequired(),
    legalEntityName: Yup.string().nullable().notRequired(),
    graduationYear: Yup.number()
      .integer()
      .min(1900, 'Graduation year must be valid')
      .max(new Date().getFullYear(), 'Graduation year must be valid')
      .notRequired(),
    licenses: Yup.array().of(
      Yup.object()
        .shape({
          state: Yup.mixed().notRequired(),
          expirationDate: Yup.mixed().notRequired(),
          file: Yup.mixed().notRequired(),
        })
        .notRequired()
    ),
    insuranceExpirationDate: Yup.mixed().notRequired(),
    insuranceFile: Yup.mixed().notRequired(),
    enableNotifications: Yup.boolean().required(),
    enableAutomaticPayments: Yup.boolean().required(),
  });

  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);

  // They are a DVM but don't have their data setup correctly, or are trying to URL hack
  if ((isDVM && !dvmId && !user?.validation?.value?.dvmInformation?.dvmId) || (isDVM && dvmId)) {
    return <Redirect to={`not-found`} />;
  }

  // check user roles to determine what actions they can take/view
  const canViewNotifications = (isSysAdmin: boolean, isRSS: boolean, isDVM: boolean): boolean => {
    return isSysAdmin || isRSS || isDVM;
  };
  const canViewAutoPayments = (isSysAdmin: boolean, isRSS: boolean): boolean => {
    return isSysAdmin || isRSS;
  };

  if (
    isLoading ||
    areTypesLoading ||
    areStatusesLoading ||
    areSchoolsLoading ||
    areMarketsLoading
  ) {
    return <Loader position="centered" type="fullscreen" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Formik
          enableReinitialize={true}
          validateOnMount
          initialTouched={{
            firstName: true,
            lastName: true,
            type: true,
            status: true,
            address: true,
            city: true,
            state: true,
            zip: true,
            primaryMarketId: true,
            phoneNumber: true,
            vendor1099Id: true,
            vendorW2Id: true,
            employeeId: true,
            authorizedStateIds: true,
          }}
          initialValues={{
            firstName: dvm?.firstName ?? '',
            lastName: dvm?.lastName ?? '',

            email: dvm?.email ?? '',
            type: dvm?.type ?? 0,
            status: dvm?.status ?? 0,
            address: dvm?.address?.street ?? '',
            city: dvm?.address?.city ?? '',
            state: dvm?.address?.state ?? '',
            stateName: dvm?.address?.stateName ?? '',
            stateId: dvm?.address?.stateId ?? '',
            zip: dvm?.address?.zip ?? '',
            primaryMarketId: !!dvm?.primaryMarketId ? dvm?.primaryMarketId : undefined,
            phoneNumber: formatInputPhoneNumber(dvm?.phone) ?? '',
            schoolId: dvm?.school?.value ?? null,
            graduationYear: dvm?.graduationYear ?? '',
            licenses: dvm?.licenses ?? [],
            insuranceExpirationDate: dvm?.insuranceExpirationDate ?? '',
            insuranceFile: dvm?.insuranceFile ?? null,
            vendor1099Id: dvm?.vendor1099Id ?? null,
            vendorW2Id: dvm?.vendorW2Id ?? null,
            employeeId: dvm?.employeeId ?? null,
            authorizedStateIds:
              dvm?.authorizedStateIds.map(authorizedStateId =>
                states.find(state => +state.value === authorizedStateId)
              ) ?? [],
            legalEntityName: dvm?.legalEntityName ?? null,
            enableNotifications: dvm?.enableNotifications ?? true,
            enableAutomaticPayments: dvm?.enableAutomaticPayments ?? true,
          }}
          validationSchema={Schema}
          onSubmit={async (values, actions) => {
            const data = {
              dvm: {
                status: values.status,
                type: values.type,
                dvmId: dvm?.dvmId ?? 0,
                firstName: values.firstName,
                lastName: values.lastName,
                schoolId: values?.schoolId && values?.schoolId > 0 ? values.schoolId : null,
                graduationYear: values.graduationYear ?? '',
                insuranceFile: values.insuranceFile,
                insuranceExpirationDate: values.insuranceExpirationDate ?? '',
                licenses: values.licenses ?? [],
                phone: values.phoneNumber.replace(/\D/g, ''),
                vendor1099Id: values.vendor1099Id,
                vendorW2Id: values.vendorW2Id,
                employeeId: values.employeeId,
                authorizedStateIds:
                  values?.authorizedStateIds?.length === 0
                    ? null
                    : values.authorizedStateIds.map(state => Number(state?.value)),
                legalEntityName: values.legalEntityName,
                primaryMarketId: values.primaryMarketId ?? '',
                address: {
                  addressId: dvm?.address?.addressId ?? 0,
                  street: values.address,
                  city: values.city,
                  state: values.state,
                  stateId: values.stateId,
                  stateName: values.stateName,
                  zip: values.zip || null,
                  latitude: dvm?.address?.latitude,
                  longitude: dvm?.address?.longitude,
                },
                enableNotifications: values.enableNotifications ?? true,
                enableAutomaticPayments: values.enableAutomaticPayments ?? true,
              },
            };

            if (data?.dvm?.status === 3) {
              const res = await getScheduledShiftCount(Number(dvmId));
              if (res?.upcomingApprovedShiftsCount > 0) {
                const confirmation = window.confirm(
                  `Deactivating this user will cancel ${res.upcomingApprovedShiftsCount} upcoming shifts. Are you sure?`
                );

                if (!confirmation) {
                  return;
                }
              } else {
                const confirmation = window.confirm(
                  `You are setting this user to Deactivated. Confirm if you would like to proceed.`
                );

                if (!confirmation) {
                  return;
                }
              }
            }

            try {
              dvmProfileView ? await updateLimitedDvm(data) : await updateDvm(data);
              for await (let license of values.licenses) {
                license.licenseId > 0
                  ? await updateLicense(license, dvm?.dvmId ?? 0)
                  : await createLicense(license, dvm?.dvmId ?? 0);
              }
              if (values?.insuranceFile && !dvm?.insuranceFile) {
                await createInsurance(values.insuranceFile, dvm?.dvmId ?? 0);
              }
              enqueueSnackbar(`DVM updated!`, {
                variant: 'success',
              });
              setIsDirty(false);
              getDVM();
              !dvmProfileView && history.push('/dvms');
            } catch (error: any) {
              const errorMessage = error?.response?.data?.Detail;
              enqueueSnackbar(errorMessage || `Error updating this DVM, please try again.`, {
                variant: 'error',
              });
            }
          }}
        >
          {({
            isSubmitting,
            values,
            initialValues,
            setFieldValue,
            handleSubmit,
            dirty,
            isValid,
            handleBlur,
            errors,
            touched,
            validateForm,
          }) => {
            return (
              <Form onSubmit={handleSubmit} autoComplete="none" className={classes.form}>
                {(isSubmitting || isLoading) && (
                  <Loader type="fullscreen" position="centered" title="Loading..." />
                )}
                <Grid container spacing={6} paddingBottom={'24px'}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>Email:</span>{' '}
                      {dvm?.email ?? 'No Email provided.'}
                    </Typography>
                    {dvm.pendingEmailAddress && (
                      <Alert severity={'warning'}>
                        Email ({dvm?.pendingEmailAddress}) is Pending.
                      </Alert>
                    )}
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <IconHeading title="Contact Information" icon={ContactMail} variant="h2" />

                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="First Name"
                          name="name"
                          value={values.firstName}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('firstName', e.target.value);
                          }}
                          inputProps={{ 'data-lpignore': 'true' }}
                          error={touched.firstName && errors.firstName ? true : false}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Last Name"
                          name="name"
                          value={values.lastName}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('lastName', e.target.value);
                          }}
                          inputProps={{ 'data-lpignore': 'true' }}
                          error={touched.lastName && errors.lastName ? true : false}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="standard" size="small">
                          <InputLabel htmlFor="typesLabel">Type</InputLabel>
                          <Select
                            fullWidth
                            name="types"
                            labelId="typesLabel"
                            id="types"
                            disabled={areTypesLoading}
                            error={errors && errors.type ? true : false}
                            value={values.type}
                            onBlurCapture={() => setIsDirty(dirty)}
                            onChange={e => {
                              if (e.target.value === 2) {
                                setFieldValue('legalEntityName', '');
                              }
                              setFieldValue('type', e.target.value as string);
                            }}
                          >
                            {types?.map((type: IDropdownResponse, index) => {
                              return (
                                <MenuItem key={`${index + 1}`} value={type.value}>
                                  {type.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {touched?.type && errors?.type && (
                            <FormHelperText error>Required</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="standard" size="small">
                          <InputLabel htmlFor="statusesLabel">Status</InputLabel>
                          <Select
                            fullWidth
                            name="statuses"
                            labelId="statusesLabel"
                            id="statuses"
                            disabled={areStatusesLoading}
                            error={errors && errors.status ? true : false}
                            value={values.status}
                            onBlurCapture={() => setIsDirty(dirty)}
                            onChange={e => {
                              setFieldValue('status', e.target.value as string);
                            }}
                          >
                            {statuses?.map((status: IDropdownResponse, index) => {
                              return (
                                <MenuItem key={`${index + 1}`} value={status.value}>
                                  {status.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {touched?.status && errors?.status && (
                            <FormHelperText error>Required</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Address"
                        name="address"
                        value={values.address}
                        onBlur={handleBlur}
                        size="small"
                        onBlurCapture={() => setIsDirty(dirty)}
                        onChange={e => {
                          setFieldValue('address', e.target.value);
                        }}
                        inputProps={{ 'data-lpignore': 'true' }}
                        error={touched.address && errors.address ? true : false}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="City"
                        name="city"
                        value={values.city}
                        onBlur={handleBlur}
                        size="small"
                        onBlurCapture={() => setIsDirty(dirty)}
                        onChange={e => {
                          setFieldValue('city', e.target.value);
                        }}
                        error={touched.city && errors.city ? true : false}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="standard" size="small">
                        <InputLabel htmlFor="stateLabel">State</InputLabel>
                        <Select
                          fullWidth
                          name="state"
                          labelId="stateLabel"
                          id="state"
                          disabled={areStatesLoading}
                          error={errors && errors.stateId ? true : false}
                          value={values.stateId}
                          onChange={e => {
                            const selectedState = states.find(
                              state => e.target.value === state.value
                            );
                            setFieldValue('stateId', e.target.value);
                            setFieldValue('state', selectedState?.shorthand ?? '');
                            setFieldValue('stateName', selectedState?.description ?? '');
                          }}
                        >
                          {states?.map((state: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index}`} value={state.value}>
                                {state.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.state && errors?.state && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Zip"
                        name="zip"
                        value={values.zip}
                        onBlur={handleBlur}
                        size="small"
                        onBlurCapture={() => setIsDirty(dirty)}
                        onChange={e => {
                          const re = /^[0-9\b]+$/;
                          // if value is not blank, then test the regex
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setFieldValue('zip', e.target.value);
                          }
                        }}
                        error={touched.zip && errors.zip ? true : false}
                        helperText={touched.zip && errors.zip}
                      />
                    </Grid>
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="standard" size="small">
                          <InputLabel htmlFor="primaryMarketLabel">Primary Market</InputLabel>
                          <Select
                            fullWidth
                            name="primaryMarket"
                            labelId="primaryMarketLabel"
                            id="primaryMarket"
                            disabled={areMarketsLoading}
                            error={errors?.primaryMarketId ? true : false}
                            value={values.primaryMarketId}
                            onBlurCapture={() => setIsDirty(dirty)}
                            onChange={e => {
                              setFieldValue('primaryMarketId', e.target.value as string);
                            }}
                          >
                            <MenuItem key={`${0}`} value={''}>
                              Not Assigned
                            </MenuItem>
                            {markets?.map((market: IDropdownResponse, index) => {
                              return (
                                <MenuItem key={`${index + 1}`} value={market.value}>
                                  {market.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Phone Number"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onBlur={e => {
                          setFieldValue('phoneNumber', formatInputPhoneNumber(e.target.value));
                        }}
                        size="small"
                        onBlurCapture={() => setIsDirty(dirty)}
                        onChange={e => setFieldValue('phoneNumber', e.target.value)}
                        error={touched.phoneNumber && errors.phoneNumber ? true : false}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Vendor ID (1099)"
                          name="vendor1099Id"
                          value={values.vendor1099Id}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('vendor1099Id', e.target.value);
                          }}
                          InputLabelProps={{ shrink: !!values.vendor1099Id ?? false }}
                          error={touched.vendor1099Id && errors.vendor1099Id ? true : false}
                          helperText={touched.vendor1099Id && errors.vendor1099Id}
                        />
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Vendor ID (W2)"
                          name="vendorW2Id"
                          value={values.vendorW2Id}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('vendorW2Id', e.target.value);
                          }}
                          InputLabelProps={{ shrink: !!values.vendorW2Id ?? false }}
                          error={touched.vendorW2Id && errors.vendorW2Id ? true : false}
                          helperText={touched.vendorW2Id && errors.vendorW2Id}
                        />
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Employee ID"
                          name="employeeId"
                          value={values.employeeId}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('employeeId', e.target.value);
                          }}
                          InputLabelProps={{ shrink: !!values.employeeId ?? false }}
                          error={touched.employeeId && errors.employeeId ? true : false}
                          helperText={touched.employeeId && errors.employeeId}
                        />
                      </Grid>
                    )}
                    {!dvmProfileView && (
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          value={values.authorizedStateIds}
                          onChange={(event, newValue: any) => {
                            setFieldValue('authorizedStateIds', newValue);
                          }}
                          disabled={areStatesLoading}
                          selectOnFocus
                          handleHomeEndKeys
                          loading={areStatesLoading}
                          id="selected-states"
                          options={states || []}
                          getOptionLabel={(option: any) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            return `${option?.description}`;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              key={params.id}
                              size="small"
                              autoComplete="on"
                              label="Authorized States"
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {(isSysAdmin || isRSS) && values.type === 1 && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Legal Entity Name"
                          name="legalEntityName"
                          value={values.legalEntityName}
                          onBlur={handleBlur}
                          size="small"
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('legalEntityName', e.target.value);
                          }}
                          InputLabelProps={{ shrink: !!values.legalEntityName ?? false }}
                          error={touched.legalEntityName && errors.legalEntityName ? true : false}
                          helperText={touched.legalEntityName && errors.legalEntityName}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid item spacing={5} container xs={12} md={6}>
                    {canViewNotifications(isSysAdmin, isRSS, isDVM) && (
                      <Grid item xs={12}>
                        <IconHeading title="Notifications" icon={CheckCircle} variant="h2" />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.enableNotifications}
                              onChange={e => {
                                setFieldValue('enableNotifications', e.target.checked);
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Enable Notifications"
                        />
                        {canViewAutoPayments(isSysAdmin, isRSS) && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.enableAutomaticPayments}
                                onChange={e => {
                                  setFieldValue('enableAutomaticPayments', e.target.checked);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="Enable Automatic Payments"
                          />
                        )}
                        <Typography className={classes.optOutText}>
                          By enabling notifications I agree to receive messaging at the phone number
                          provided above, data rates may apply, reply STOP to opt out.
                        </Typography>
                        <Grid item mt={2}>
                          <Link className={classes.linksToTerms} to="/privacy-policy">Privacy Policy</Link>
                          <span> | </span> 
                          <Link className={classes.linksToTerms} to="/terms-and-conditions">Terms & Conditions</Link>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <IconHeading variant="h2" icon={School} title="Education and Certification" />
                      <FormControl fullWidth variant="standard" size="small">
                        <InputLabel shrink={!!values.schoolId} htmlFor="schoolLabel">
                          School
                        </InputLabel>
                        <Select
                          fullWidth
                          name="school"
                          labelId="schoolLabel"
                          id="school"
                          disabled={areSchoolsLoading || dvmProfileView}
                          error={touched.schoolId && errors && errors.schoolId ? true : false}
                          value={values.schoolId}
                          onBlurCapture={() => setIsDirty(dirty)}
                          onChange={e => {
                            setFieldValue('schoolId', e.target.value as string);
                          }}
                        >
                          {schools?.map((school: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index + 1}`} value={school.value}>
                                {school.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.schoolId && errors?.schoolId && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Graduation Year"
                        name="graduationYear"
                        value={values.graduationYear}
                        disabled={dvmProfileView}
                        inputProps={{
                          shrink: !values.graduationYear,
                        }}
                        onBlur={handleBlur}
                        size="small"
                        onBlurCapture={() => setIsDirty(dirty)}
                        onChange={e => {
                          const re = /^[0-9\b]+$/;
                          // if value is not blank, then test the regex
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setFieldValue('graduationYear', e.target.value);
                          }
                        }}
                        error={touched.graduationYear && errors.graduationYear ? true : false}
                        helperText={touched.graduationYear && errors.graduationYear}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IconHeading
                        variant="h2"
                        icon={AssignmentInd}
                        title="License Information"
                        style={{ marginBottom: '1rem' }}
                      />
                      <LicenseInfo
                        licenses={values.licenses}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                        validateForm={validateForm}
                        errors={errors}
                        values={values}
                        fetchDVM={async () => await getDVM()}
                        readOnly={dvmProfileView}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IconHeading
                        variant="h2"
                        icon={HomeRepairService}
                        title="Insurance Information"
                        style={{ marginBottom: '1rem' }}
                      />
                      <InsuranceInfo
                        insuranceFile={values.insuranceFile}
                        insuranceExpirationDate={values.insuranceExpirationDate}
                        dvmId={dvm?.dvmId ?? 0}
                        fetchDVM={getDVM}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                        validateForm={validateForm}
                        errors={errors}
                        readOnly={dvmProfileView}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!dirty || isSubmitting || isLoading || !isValid}
                    type="submit"
                    startIcon={<Edit />}
                    variant="contained"
                    color="primary"
                  >
                    Update
                  </Button>
                  {!isDVMEmployee && (
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting}
                      startIcon={<Close />}
                      onClick={() => {
                        //@ts-ignore
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm(
                            'You have unsaved changes, are you sure you want to exit?'
                          );
                          if (result) {
                            dvmProfileView ? history.goBack() : history.push('/dvms');
                          } else {
                            return;
                          }
                        } else {
                          dvmProfileView ? history.goBack() : history.push('/dvms');
                        }
                      }}
                    >
                      {dvmProfileView ? 'Back' : 'Cancel'}
                    </Button>
                  )}
                  {!dvmProfileView && (
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      startIcon={<Email />}
                      onClick={() => {
                        setIsChangeEmailModalOpen(true);
                      }}
                    >
                      {'Change Email Address'}
                    </Button>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <DvmChangeEmailModal
        open={isChangeEmailModalOpen}
        onClose={() => setIsChangeEmailModalOpen(false)}
        dvmId={dvmId}
        refetch={() => getDVM()}
      />
    </Grid>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  optOutText: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    color: theme.palette.common.black,
  },
  linksToTerms: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }
}));
