import { Box, Button, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';
import { Table } from '../../../components/table/Table';
import { MobileTable } from '../../../components/table/MobileTable';
import { IColumn, setState } from '../../../models/util';
import { EditLocationAlt } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IW2PaymentReserves } from '../../../models';
import { formatDate, formatWholeNumberPercent } from '../../../helpers';
import { W2DVMFilters } from './w2-dvms-filters';

interface IW2DVMs {
  handleEdit: (dvmId: number | string) => void;
  dvmsWithW2Reserves: IW2PaymentReserves[];
  setDvmsWithW2Reserves: (dvmsWithW2Reserves: IW2PaymentReserves[]) => void;
  isLoading: boolean;
  searchValue: string;
  setSearchValue: setState<string>;
  hasAppliedFilters: boolean;
  setHasAppliedFilters: setState<boolean>;
  handleClickColumn: (column: string) => void;
  selectedSort: string;
  sortDirection: any;
  handleSearch: (val: string) => void;
}

export const W2DVMs: FC<IW2DVMs> = ({
  handleEdit,
  dvmsWithW2Reserves,
  setDvmsWithW2Reserves,
  searchValue,
  setSearchValue,
  hasAppliedFilters,
  setHasAppliedFilters,
  handleClickColumn,
  isLoading,
  selectedSort,
  sortDirection,
  handleSearch,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const classes = useStyles();

  // filters

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'DVM Name',
        accessor: 'name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === 'Desc',
        handleClickColumn: () => handleClickColumn('Name'),
      },
      {
        Header: 'Current Reserve Rate',
        accessor: 'currentW2PaymentReserve.reserve',
        isServerSorted: selectedSort === 'Reserve',
        isServerSortedDesc: sortDirection.Reserve === 'Desc',
        handleClickColumn: () => handleClickColumn('Reserve'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IW2PaymentReserves } };
        }) => {
          return (
            <span>
              {original?.currentW2PaymentReserve?.reserve
                ? formatWholeNumberPercent(original?.currentW2PaymentReserve?.reserve)
                : 'No Current Rate'}
            </span>
          );
        },
      },
      {
        Header: 'Date of Current Reserve Rate',
        accessor: 'currentW2PaymentReserve.effectiveDate',
        isServerSorted: selectedSort === 'EffectiveDate',
        isServerSortedDesc: sortDirection.EffectiveDate === 'Desc',
        handleClickColumn: () => handleClickColumn('EffectiveDate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IW2PaymentReserves } };
        }) => {
          return (
            <span>
              {original?.currentW2PaymentReserve?.effectiveDate
                ? formatDate(original?.currentW2PaymentReserve?.effectiveDate)
                : 'No Current Date'}
            </span>
          );
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IW2PaymentReserves } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original?.dvmId);
                }}
              >
                Edit
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dvmsWithW2Reserves, selectedSort, sortDirection]);

  return (
    <>
      <W2DVMFilters
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        applyFilters={(clearFilters?: boolean) => {
          if (clearFilters) {
            setSearchValue('');
          }
        }}
        setHasAppliedFilters={setHasAppliedFilters}
        handleSearch={(val: string) => {
          handleSearch(val);
        }}
        isLoading={isLoading}
        hasAppliedFilters={hasAppliedFilters}
      />
      <Table
        columns={columns}
        data={dvmsWithW2Reserves}
        hidePagination
        isLoading={isLoading}
        ResponsiveComponent={isMobile ? MobileTable : undefined}
        mobileProps={{
          fields: columns
            .filter(col => col.Header)
            .map(col => ({
              name: col.Header,
              accessor: col.accessor,
              Cell: col?.Cell,
            })),
          handleEdit: (IW2PaymentReserves: IW2PaymentReserves) => {
            handleEdit(IW2PaymentReserves?.dvmId);
          },
        }}
      />
    </>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
