import { FC, useEffect, useState, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Box,
  Button,
  Fade,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
} from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { createLegalEntity, getLegalEntity, updateLegalEntity } from '../../fetch/legal-entities';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { IDropdownResponse } from '../../models/util';
import { ILegalEntity } from '../../models/legal-entitites';
import { getStates, getTaxTypes } from '../../fetch/lookups';
import { RadioGroup } from '../../components/formik-mui/radio-group';

interface ILegalEntityModal {
  open: boolean;
  onClose: () => void;
  fetchLegalEntities: () => void;
  currentLegalEntityId: string | number;
  isEdit: boolean;
}

const Schema = Yup.object().shape({
  legalEntityName: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
  taxType: Yup.string().required('Tax Type is Required'),
});

export const LegalEntityModal: FC<ILegalEntityModal> = ({
  open,
  onClose,
  fetchLegalEntities,
  currentLegalEntityId,
  isEdit,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [legalEntity, setLegalEntity] = useState<ILegalEntity>();
  const [areStatesLoading, setAreStatesLoading] = useState(true);
  const [states, setStates] = useState<IDropdownResponse[]>([]);
  const [stateIds, setStateIds] = useState<number[]>([]);

  const fetchLegalEntity = async () => {
    try {
      setIsLoading(true);
      const res = await getLegalEntity(currentLegalEntityId);
      setLegalEntity(res);
      setStateIds(res?.stateIds || []);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading legal entity, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStates = async () => {
    setAreStatesLoading(true);
    try {
      const res = await getStates();
      setStates(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading states, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreStatesLoading(false);
    }
  };

  const [areTaxTypesLoading, setAreTaxTypesLoading] = useState(true);
  const [taxTypes, setTaxTypes] = useState<IDropdownResponse[]>([]);
  const fetchTaxTypes = async () => {
    setAreTaxTypesLoading(true);
    try {
      const res = await getTaxTypes();
      setTaxTypes(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading taxTypes, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreTaxTypesLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
    if (!currentLegalEntityId) {
      setStateIds([]);
      setLegalEntity(undefined);
    }
    fetchTaxTypes();
    if (currentLegalEntityId) fetchLegalEntity();
    if (!currentLegalEntityId) setLegalEntity(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntityId]);

  const filteredStates = useMemo(() => {
    if (!states || !stateIds) return states;
    return states.filter(state => !stateIds.includes(Number(state.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, legalEntity?.stateIds, stateIds]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        legalEntityName: legalEntity?.name ?? '',
        code: legalEntity?.code ?? '',
        authorizedStateIds:
          legalEntity?.stateIds.map(
            (id: string | number) => states.find(state => state.value === id) // Convert IDs to IDropdownResponse objects
          ) ?? [],
        taxType: taxTypes?.find(type => type.value === legalEntity?.taxType)?.value ?? '',
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        const data = {
          authorizedStateIds:
            values?.authorizedStateIds?.length === 0
              ? []
              : values.authorizedStateIds.map((state: any) => Number(state?.value)),
          name: values.legalEntityName,
          code: values.code,
          taxType: values.taxType,
        };

        try {
          if (!isEdit) {
            await createLegalEntity({
              name: data?.name,
              code: data?.code,
              stateIds: data?.authorizedStateIds || null,
              taxType: values.taxType,
            });
          } else {
            await updateLegalEntity(currentLegalEntityId, {
              name: data?.name,
              code: data?.code,
              stateIds: data?.authorizedStateIds || null,
              taxType: values.taxType,
            });
          }
          fetchLegalEntities();
          enqueueSnackbar('Legal Entity Saved!', {
            variant: 'success',
          });
          actions.resetForm();
          onClose();
        } catch (error: any) {
          const errorMessage = error?.response?.data?.Detail;
          enqueueSnackbar(errorMessage || `Error saving legal entity, please try again.`, {
            variant: 'error',
          });
          console.log(error);
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
        resetForm,
      }) => {
        return (
          <Modal
            open={open}
            onClose={() => {
              resetForm();
              onClose();
            }}
            maxWidth="md"
          >
            <Fade in={open}>
              <Form onSubmit={handleSubmit} autoComplete="none">
                <div>
                  <Typography variant="h5">
                    {isEdit ? 'Edit Legal Entity' : 'Add New Legal Entity'}
                  </Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={isLoading}
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Legal Entity Name"
                          name="name"
                          value={values?.legalEntityName}
                          onBlur={handleBlur}
                          size="small"
                          required
                          onChange={e => setFieldValue('legalEntityName', e.target.value)}
                          error={
                            touched.legalEntityName && errors && errors.legalEntityName
                              ? true
                              : false
                          }
                          helperText={touched.legalEntityName && errors && errors.legalEntityName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={isLoading}
                          fullWidth
                          variant="standard"
                          autoComplete="nope"
                          label="Code"
                          name="code"
                          value={values?.code}
                          onBlur={handleBlur}
                          size="small"
                          required
                          onChange={e => setFieldValue('code', e.target.value)}
                          error={touched.code && errors && errors.code ? true : false}
                          helperText={touched.code && errors && errors.code}
                        />
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <Autocomplete
                          multiple
                          value={values.authorizedStateIds}
                          onChange={(event, newValue: any) => {
                            const newStateIds = newValue.map((item: any) => item.value);
                            setStateIds(newStateIds);
                            setFieldValue('authorizedStateIds', newValue);
                          }}
                          disabled={areStatesLoading}
                          selectOnFocus
                          handleHomeEndKeys
                          loading={areStatesLoading}
                          id="selected-states"
                          options={filteredStates || []}
                          filterSelectedOptions
                          getOptionLabel={(option: any) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            return `${option?.description} (${option?.shorthand})`;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              key={params.id}
                              size="small"
                              autoComplete="on"
                              label="Authorized States"
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      {/* Used as a spacer */}
                      <Grid item xs={12} sm={1}></Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          component="fieldset"
                          disabled={areTaxTypesLoading}
                          margin={'dense'}
                        >
                          <FormLabel required>Tax Type</FormLabel>
                          <RadioGroup
                            defaultValue="1099"
                            row
                            aria-label="taxType"
                            name="taxType"
                            value={values.taxType}
                            onChange={(e, value) => setFieldValue('taxType', e.target.value)}
                          >
                            {taxTypes.map(type => (
                              <FormControlLabel
                                value={type.value}
                                control={<Radio />}
                                label={type.description}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!dirty || isSubmitting || !isValid}
                    type="submit"
                    startIcon={<Edit />}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    startIcon={<Close />}
                    onClick={() => {
                      //@ts-ignore
                      if (!deepEqual(initialValues, values)) {
                        const result = window.confirm(
                          'You have unsaved changes, are you sure you want to exit?'
                        );
                        if (result) {
                          resetForm();
                          onClose();
                        } else {
                          return;
                        }
                      } else {
                        resetForm();
                        onClose();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Fade>
          </Modal>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
