import { TablePagination, TablePaginationProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';

import { TablePaginationActions } from './TablePaginationActions';

interface IPagination
  extends Pick<
    TablePaginationProps,
    'labelRowsPerPage' | 'count' | 'rowsPerPage' | 'page' | 'rowsPerPageOptions'
  > {
  setPage: (newPage: number) => void;
  setRowsPerPage: (val: number) => void;
  rowsPerPageOptions?: number[];
}

export const Pagination: FC<IPagination> = ({
  labelRowsPerPage,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
}) => {
  const classes = paginationStyles();
  return (
    <TablePagination
      labelRowsPerPage={labelRowsPerPage}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions ?? [10, 25, 50]}
      component="div"
      classes={{
        root: classes.paginationRoot,
      }}
      onPageChange={(e: unknown, newPage: number) => setPage(newPage)}
      onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value, 10));
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
};

const paginationStyles = makeStyles((theme: Theme) => ({
  paginationRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    '&& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: '5px',
    },
    '&& .MuiToolbar-gutters': {
      paddingLeft: 0,
    },
    '&& .MuiTablePagination-actions': {
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      '&& .MuiTablePagination-selectRoot': {
        marginLeft: '8px',
        marginRight: '32px',
      },
      '&& .MuiToolbar-gutters': {
        paddingLeft: 0,
      },
      '&& .MuiTablePagination-actions': {
        marginLeft: '20px',
      },
    },
  },
}));
