import { DeleteForever } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { MultiFileUpload } from '../../components/file/multi-image-upload';
import { Table } from '../../components/table/Table';
import { getFile } from '../../fetch/files';
import { deleteInsurance } from '../../fetch/insurance';
import { downloadFile } from '../../helpers';
import { IFile } from '../../models/files';

interface IInsuranceInfo {
  insuranceFile: any;
  insuranceExpirationDate: any;
  isLoading: boolean;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  validateForm: any;
  fetchDVM: () => void;
  dvmId: number;
  readOnly: boolean;
}
export const InsuranceInfo: FC<IInsuranceInfo> = ({
  insuranceFile,
  insuranceExpirationDate,
  isLoading,
  errors,
  setFieldValue,
  validateForm,
  fetchDVM,
  dvmId,
  readOnly,
}) => {
  const [insuranceCopy, setInsuranceCopy] = useState<any>(insuranceFile);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [files, setFiles] = useState<IFile[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const addInsurance = () => {
    setInsuranceCopy({
      file: null,
      expirationDate: null,
    });
  };

  const updateMyData = (insurance: any, columnId: string, value: string, rowIndex: number) => {
    setInsuranceCopy(insurance);
    setFieldValue('insuranceExpirationDate', insurance.expirationDate);
  };

  const handleDelete = async (shiftIndex: number) => {
    const result = window.confirm('Are you sure you want to delete this insurance?');
    if (result) {
      try {
        await deleteInsurance(dvmId);
        enqueueSnackbar(`insurance deleted.`, {
          variant: 'success',
        });
        setInsuranceCopy(null);
        fetchDVM();
      } catch (error: any) {
        console.error(error);
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting insurance, please try again.`, {
          variant: 'error',
        });
      }
    }
  };

  const [expirationDate, setExpirationDate] = useState<string | undefined>(
    insuranceExpirationDate as string
  );

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setExpirationDate(insuranceExpirationDate as string);
  }, [insuranceExpirationDate]);

  useEffect(() => {
    setInsuranceCopy(insuranceFile);
  }, [insuranceFile]);

  const getFileName = (res: any) => {
    let filename = '';
    let disposition = res.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Expiration Date',
          accessor: 'insuranceExpirationDate',
          sort: false,
          Cell: (props: any) => {
            const [val, setVal] = useState('');
            return (
              <>
                {!isMobile ? (
                  <>
                    <DesktopDatePicker
                      toolbarPlaceholder={'Expiration Date'}
                      inputFormat="MM/dd/yyyy"
                      value={expirationDate}
                      onChange={(e: any) => setVal(e)}
                      disabled={readOnly}
                      onAccept={val => {
                        setExpirationDate(val);
                        setFieldValue('insuranceExpirationDate', val);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          disabled={readOnly}
                          onBlur={() => {
                            setExpirationDate(val);
                            setFieldValue('insuranceExpirationDate', val);
                          }}
                          size="small"
                          variant="standard"
                          {...params}
                          error={errors && errors?.insuranceExpirationDate}
                        />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <MobileDatePicker
                      toolbarPlaceholder={'Expiration Date'}
                      inputFormat="MM/dd/yyyy"
                      value={expirationDate}
                      onChange={(e: any) => setVal(e)}
                      disabled={readOnly}
                      onAccept={val => {
                        setExpirationDate(val);
                        setFieldValue('insuranceExpirationDate', val);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          disabled={readOnly}
                          onBlur={() => {
                            setExpirationDate(val);
                            setFieldValue('insuranceExpirationDate', val);
                          }}
                          variant="standard"
                          size="small"
                          {...params}
                          error={errors && errors?.insuranceExpirationDate}
                        />
                      )}
                    />
                  </>
                )}
              </>
            );
          },
        },
        {
          Header: 'File',
          accessor: 'insuranceFile',
          sort: false,
          Cell: (props: any) => {
            return (
              <>
                {insuranceCopy?.azureUri ? (
                  <Button
                    onClick={async () => {
                      const res = await getFile(props?.row?.original?.fileId);
                      const fileName = getFileName(res);
                      const binaryData = [];
                      binaryData.push(res.data);
                      downloadFile(binaryData, fileName);
                    }}
                    size={'large'}
                    variant={'contained'}
                    component={'span'}
                  >
                    {insuranceCopy?.fileName}
                  </Button>
                ) : (
                  <>
                    <MultiFileUpload
                      multi={false}
                      imageKey={`insurance-${props?.row?.index}`}
                      handleFileChange={async val => {
                        console.log(props?.row?.original);
                        setFiles(prev => [...prev, ...val]);
                        setFieldValue('insuranceFile', val?.[0].file);
                      }}
                      files={files.map(file => {
                        return file.title;
                      })}
                      fileName={props?.row?.original?.name || props?.row?.original?.fileName}
                      // disabled={isUploadingPhoto || isLoadingServicePhotos || isDeletingPhoto}
                    />
                    <Typography variant="body2">{errors && errors?.InsuranceFile}</Typography>
                  </>
                )}
              </>
            );
          },
        },
        {
          Header: '',
          accessor: '',
          id: 'actions',
          isServerSorted: false,
          isServerSortedDesc: false,
          handleClickColumn: () => {},
          Cell: (props: any) => {
            return (
              <Box
                sx={{
                  textAlign: {
                    xs: 'left',
                    md: 'center',
                  },
                }}
              >
                <IconButton onClick={() => handleDelete(props?.row?.index)}>
                  <DeleteForever />
                </IconButton>
              </Box>
            );
          },
        },
      ].filter(column => (readOnly ? column.id !== 'actions' : true)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expirationDate, isLoading]
  );

  return (
    <Grid container spacing={1}>
      {!readOnly && (
        <Grid item xs={12}>
          <Button disabled={!!insuranceCopy} onClick={() => addInsurance()}>
            Add
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={insuranceCopy ? [insuranceCopy] : []}
          hidePagination
          isLoading={isLoading}
          useTableProps={{
            // @ts-ignore
            updateMyData,
          }}
        />
      </Grid>
    </Grid>
  );
};
