import { tokenRequest } from './../authConfig';
import { InteractionRequiredAuthError, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

import { axios } from '../helpers';

const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  // This may have been set somewhere, so we need to check
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  const request:SilentRequest = {
    scopes: tokenRequest.scopes,
    account: activeAccount || accounts[0],
    resourceRequestUri: msalConfig.auth.clientId,

  };

  try {
    const res = await msalInstance.acquireTokenSilent(request)
    return res.accessToken;
  } catch(error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const res = await msalInstance.acquireTokenPopup(request);
        return res.accessToken;
      } catch (error) {
        msalInstance.logoutRedirect()
      }
    }
    else {
      msalInstance.logoutRedirect()
    }
  }
};
const msalInstance = new PublicClientApplication(msalConfig);

// keep axios syntax for simplicity
export const authFetch = {
  get: async (url: string, params?: any, options?: any) => {
    const accessToken = await acquireAccessToken(msalInstance);

    return await axios.get(url, params, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  put: async (url: string, data: any, options?: any) => {
    const accessToken = await acquireAccessToken(msalInstance);

    const routeOptions = {
      ...options,
      ...{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    };
    return await axios.put(url, data, routeOptions);
  },
  post: async (url: string, data: any, options?: any) => {
    const accessToken = await acquireAccessToken(msalInstance);

    const routeOptions = {
      ...options,
      ...{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    };

    return await axios.post(url, data, routeOptions);
  },
  delete: async (url: string) => {
    const accessToken = await acquireAccessToken(msalInstance);

    return await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  /**
   * The null return path should redirect the user to the login page of the app
   * so I think it's safe to assume that this will always give you a result when it
   * matters. If they're sent to the login screen we don't care what they were doing really.
   * @returns Promise<AuthenticationResult | null>
   */
  forceRefresh: async () => {
    // This may have been set somewhere, so we need to check
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    const request: SilentRequest = {
      scopes: tokenRequest.scopes,
      account: activeAccount || accounts[0],
      forceRefresh: true,
      resourceRequestUri: msalConfig.auth.clientId
    };

    return await msalInstance.acquireTokenSilent(request)
      .then(response => { return response })
      .catch(async error => {
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenPopup(request);
        }
        else {
          msalInstance.logoutRedirect();
          return null;
        }
      });
  }
};
