import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserContext } from '../../context';

export interface IPrivateRoute extends RouteProps {
  allowedRoles?: string[];
  redirectTo?: string;
}
export const PrivateRoute: FC<IPrivateRoute> = (props: IPrivateRoute) => {
  const { user, isFetching } = useContext(UserContext);
  if (isFetching) {
    return null;
  }

  if (!user && !isFetching) {
    return <Redirect to={`/${props.redirectTo ?? 'not-found'}`} />;
  }

  if (user?.account.idTokenClaims?.roles?.some(role => props.allowedRoles?.includes(role))) {
    return <Route {...props} />;
  }
  return <Redirect to={`/${props.redirectTo ?? 'not-found'}`} />;
};
