import { FC, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Close, Check } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { IIncentiveRate } from '../../models/incentiveRates';
import { DateRangePicker } from '../../components/input/date-range';
import { IDateRange } from '../../models/date';
import { createIncentiveRate, updateIncentiveRate } from '../../fetch/incentive-rates';
import { formatDate } from '../../helpers';

interface ICancelRequestModal {
  open: boolean;
  onClose: () => void;
  fetchIncentiveRates: () => {};
  incentive?: IIncentiveRate;
  locationId: string;
}

const Schema = Yup.object().shape({
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
  rate: Yup.number().required('Required'),
});

export const IncentiveRatesModal: FC<ICancelRequestModal> = ({
  open,
  onClose,
  fetchIncentiveRates,
  incentive,
  locationId,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: incentive?.startDate ?? null,
    endDate: incentive?.endDate ?? null,
    key: 'selection',
    inputValue: '',
  });

  useEffect(() => {
    setSelectedDateRange({
      startDate: incentive?.startDate ? new Date(incentive.startDate) : null,
      endDate: incentive?.endDate ? new Date(incentive.endDate) : null,
      key: 'selection',
      inputValue:
        incentive?.startDate && incentive?.endDate
          ? `${formatDate(incentive?.startDate)} - ${formatDate(incentive?.endDate)}`
          : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const currentDay = new Date();

  const handleClose = () => {
    setSelectedDateRange({
      startDate: incentive?.startDate ?? null,
      endDate: incentive?.endDate ?? null,
      key: 'selection',
      inputValue: '',
    });

    onClose();
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          startDate: incentive?.startDate ?? null,
          endDate: incentive?.endDate ?? null,
          rate: incentive?.rate ?? null,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            locationId: parseInt(locationId),
            incentiveRateId: incentive?.incentiveRateId ?? 0,
            startDate: selectedDateRange.startDate as Date,
            endDate: selectedDateRange.endDate as Date,
            rate: parseInt(values?.rate ?? ''),
          };

          try {
            if (!data?.incentiveRateId) {
              await createIncentiveRate(data);
            } else {
              await updateIncentiveRate(data);
            }

            fetchIncentiveRates();
            handleClose();
            actions.resetForm();
            enqueueSnackbar(`Incentive Rates updated!`, {
              variant: 'success',
            });
          } catch (error: any) {
            const errorMessage = error?.response?.data?.Detail;
            enqueueSnackbar(
              errorMessage || `Error updating this Incentive Rate, please try again.`,
              {
                variant: 'error',
              }
            );
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          setValues,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                handleClose();
              }}
              maxWidth="md"
            >
              <Form onSubmit={handleSubmit} autoComplete="none">
                <div>
                  <Typography variant="h5">Incentive Rates</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          required
                          variant={'outlined'}
                          autoComplete="nope"
                          label="Amount"
                          name="rate"
                          value={values.rate}
                          onBlur={handleBlur}
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onChange={e => {
                            const re = /^[0-9\b]+$/;
                            // make sure it is number only before we update state
                            if (e.target.value === '' || re.test(e.target.value)) {
                              setFieldValue('rate', e.target.value);
                            }
                          }}
                          error={touched.rate && errors.rate ? true : false}
                          helperText={touched.rate && errors.rate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateRangePicker
                          id="filterDateRange"
                          label="Date Range"
                          value={selectedDateRange}
                          isDisabled={isSubmitting}
                          onChange={value => {
                            setSelectedDateRange(value);
                            setValues({
                              ...values,
                              startDate: value.startDate,
                              endDate: value.endDate,
                            });
                          }}
                          fullWidth
                          inputSize="small"
                          minDate={currentDay}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                    startIcon={<Check />}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    startIcon={<Close />}
                    onClick={() => {
                      //@ts-ignore
                      if (!deepEqual(initialValues, values)) {
                        const result = window.confirm(
                          'You have unsaved changes, are you sure you want to exit?'
                        );
                        if (result) {
                          resetForm();
                          fetchIncentiveRates();
                          handleClose();
                        } else {
                          return;
                        }
                      } else {
                        resetForm();
                        handleClose();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  reasonSelector: {
    cursor: 'pointer',
  },
}));
