import { LogLevel } from "@azure/msal-browser";

const {
    REACT_APP_MSAL_CLIENT_ID,
    REACT_APP_MSAL_TENANT_ID,
    REACT_APP_MSAL_SCOPES,
    REACT_APP_MSAL_REDIRECT,
} = require('./buildSettings.json');


export const msalConfig = {
    auth: {
        clientId: REACT_APP_MSAL_CLIENT_ID,
        authority: `https://login.windows.net/${REACT_APP_MSAL_TENANT_ID}`,
        redirectUri: REACT_APP_MSAL_REDIRECT,
        postLogoutRedirectUri: REACT_APP_MSAL_REDIRECT
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

// Coordinates and required scopes for your web API
export const apiConfig = {
    resourceUri: "https://localhost:44351/api/profile",
    resourceScopes: [REACT_APP_MSAL_SCOPES]
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com"
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [...apiConfig.resourceScopes]
}