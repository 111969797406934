import { useState } from 'react';

// Ensures that state variables can always have the latest values read to mimic synchronicity;
// note: will not work with truly async values, like values returning from an API response
export const useSyncState = (initialValue: any) => {
  const [trait, updateTrait] = useState(initialValue);

  let current = trait;

  const get = () => current;

  const set = (newValue: any) => {
    current = newValue;
    updateTrait(newValue);
    return current;
  }

  return {
    get,
    set,
  }
};
